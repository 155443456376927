import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import MultistepProposal from './MultistepProposal';
import UseFormContext from '../../../context/UseFormContext';
import "../../style/healthProposal.scss";
import { Formik } from 'formik';

const HealthProposal = (props) => {
  const formContext = UseFormContext();
  let history = useHistory();

  const formatAmount = (amount) => {
    return new Intl.NumberFormat('en-IN', { maximumFractionDigits: 0 }).format(amount);
  };

  const calculateTotalPremium = () => {
    const basePremium = Number(formContext.selectedHealthQuoteData?.totalPremium.replace(/,/g, "")) || 0;
    return basePremium;
  };

  const getAllRiders = () => {
    const selectedRiders = formContext.selectedRiders || [];
    const ridersFromHealthQuote = formContext.selectedHealthQuoteData?.riderList || [];
    
    const defaultRiders = [
      { name: 'Unlimited Automatic Recharge', amount: "", riderCode: "UARV1195", riderId: 1, type: "M", description: "Get your sum insured increased up to 150% in 5 consecutive claim free years" },
      { name: 'Pre and Post Hospitalization', amount: 0, riderCode: "PREPHECA1150", riderId: 11, type: "M", description: "Get your sum insured increased up to 150% in 5 consecutive claim free years" },
      { name: 'Unlimited E-Consultation', amount: 0, riderCode: "UECV1153", riderId: 12, type: "M", description: "Get your sum insured increased up to 150% in 5 consecutive claim free years" },
    ];

    let mergedRiders = [
      ...defaultRiders,
      ...selectedRiders.filter(selectedRider => !defaultRiders.some(defaultRider => defaultRider.riderId === selectedRider.riderId)),
    ];

    const eldestMemberAge = Math.max(
      ...formContext.selectedHealthQuoteData?.healthInsurefor.map((member) => member.age) || [0]
    );
    const zone = formContext.selectedHealthQuoteData?.zone || "";

    if (eldestMemberAge <= 45 && (zone === "zone 1" || zone === "zone 1a")) {
      console.log("Automatically adding NCB Super based on Zone and Age");
      const ncbSuperRider = ridersFromHealthQuote.find(rider => rider.name === "NCB Super");
      if (ncbSuperRider && !selectedRiders.some(rider => rider.riderId === ncbSuperRider.riderId)) {
        selectedRiders.push(ncbSuperRider);
      }
    } else if (eldestMemberAge >= 76 && eldestMemberAge <= 99) {
      console.log("Automatically adding NCB Super based on Age between 76 and 99");
      const ncbSuperRider = ridersFromHealthQuote.find(rider => rider.name === "NCB Super");
      if (ncbSuperRider && !selectedRiders.some(rider => rider.riderId === ncbSuperRider.riderId)) {
        selectedRiders.push(ncbSuperRider);
      }
    }

    mergedRiders = mergedRiders.map((rider) => {
      const selectedRider = ridersFromHealthQuote.find((quoteRider) => quoteRider.riderCode === rider.riderCode);
      if (selectedRider) {
        rider.amount = selectedRider.amount;
      }
      return rider;
    });

    const uniqueRiders = Array.from(new Map(mergedRiders.map(rider => [rider.riderId, rider])).values());
    return uniqueRiders;
  };

  const handleViewSection = (section) => {
    const sectionId = section === 'riders' ? '#riders-section' : '#addons-section';
    history.push({
      pathname: window.location.pathname,
      hash: sectionId
    });
  };

  const backButtonPressed = () => {
    formContext.setgetquotesApiFlag(false);
    history.push("/quotes/1");
  };

  useEffect(() => {
    document.body.classList.add("car-page");
    return () => {
      document.body.classList.remove("car-page");
    };
  }, []);

  const selectedAddOns = formContext.selectedAddOns || [];
  const uniqueRiders = getAllRiders();

  const totalPremium = calculateTotalPremium();

  return (
    <>
      <style>
        {`
          svg {
            display: unset;
          }
        `}
      </style>
      <section className="section-breadcrumb mb-[50px] max-[1199px]:mb-[35px] border-b-[1px] border-solid border-[#eee] bg-[#f8f8fb]">
        <div className="flex flex-wrap justify-between relative items-center mx-auto min-[1400px]:max-w-[1320px] min-[1200px]:max-w-[1140px] min-[992px]:max-w-[960px] min-[768px]:max-w-[720px] min-[576px]:max-w-[540px]">
          <div className="flex flex-wrap w-full">
            <div className="w-full px-[12px]">
              <div className="flex flex-wrap w-full bb-breadcrumb-inner m-[0] py-[20px] items-center">
                <div className="min-[768px]:w-[50%] min-[576px]:w-full w-full px-[12px]">
                  <h2 className="max-[767px]:justify-start min-[767px]:flex bb-breadcrumb-title font-quicksand tracking-[0.03rem] leading-[1.2] text-[16px] font-bold text-[#3d4750] max-[767px]:text-center max-[767px]:mb-[10px]">
                    Health Proposal
                  </h2>
                </div>
                <div className="min-[768px]:w-[50%] min-[576px]:w-full w-full px-[12px]">
                  <ul className="bb-breadcrumb-list mx-[-5px] mb-0 flex justify-end min-[767px]:justify-end max-[768px]:justify-center">
                    <li className="bb-breadcrumb-item text-[14px] font-normal px-[5px]">
                      <a
                        href="index.html"
                        className="font-Poppins text-[14px] leading-[28px] tracking-[0.03rem] font-semibold text-[#686e7d] no-underline"
                      >
                        Home
                      </a>
                    </li>
                    <li className="text-[14px] font-normal px-[5px]">
                      <i className="ri-arrow-right-double-fill text-[14px] font-semibold leading-[28px]"></i>
                    </li>
                    <li className="bb-breadcrumb-item font-Poppins text-[#686e7d] text-[14px] leading-[28px] font-normal tracking-[0.03rem] px-[5px] active">
                      Health Proposal
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="proposal-wrap rider-selection-wrap pb-[50px] pt-[50px]">
        <Container className="form-bg  justify-between relative items-center mx-auto min-[1400px]:max-w-[1320px] min-[1200px]:max-w-[1140px] min-[992px]:max-w-[960px] min-[768px]:max-w-[720px] min-[576px]:max-w-[540px] max-[1199px]:py-[35px]">
          <Row>
            <Col className="p-left" sm={12} md={4}>
              <div className="leftside-form">
                <h3 className="title mb-3">Premium Amount ₹ {formatAmount(totalPremium)}</h3>
                <div className="summary-details mb-3" style={{ textAlign: "left" }}>
                  <div className="heading-title pt-2 pb-2">
                    <h6 className="title_text mb-0 fw-600">Summary</h6>
                  </div>
                  <div className="content-wrap">
                    <div className="amount-info">
                      <div className="amount-item">
                        <span className="text-grey text-left">Base Premium - 1 Year:</span>
                        <span className="text-right">₹ {formatAmount(formContext.selectedHealthQuoteData?.basePremium)}</span>
                      </div>
                    </div>
                    <div className="mt-3 mb-3">
                      <label className="mb-1 fw-600">Selected rider(s)</label>
                      <div className="amount-item border-box d-block">
                        {getAllRiders().length === 0 ? (
                          <div className="amount-item">
                            <label className="text-grey">Missing out on benefits</label>
                          </div>
                        ) : (
                          <div>
                            {getAllRiders().map((rider, index) => (
                              <div key={index} className="add-ons-items">
                                <span className="text-grey text-left">{rider.name}</span>
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="total-info content-bottom-wrap">
                    <div className="amount-item total-item" style={{ display: 'flex', justifyContent: 'space-between' }}>
                      <span className="text-left">Total Premium</span>
                      <span className="text-right">₹ {formatAmount(totalPremium)}</span>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col sm={12} md={8} className="mb-3">
              <MultistepProposal healthMainFormData={formContext.healthMainFormData} healthQuoteFormikData={formContext.healthQuoteFormikData} selectedHealthQuoteData={formContext.selectedHealthQuoteData} />
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default HealthProposal;