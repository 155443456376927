import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CryptoJS from "crypto-js";

const KYCRedirection = () => {
  const { url: encryptedUrl , proposalId} = useParams(); // Extract encrypted URL from params
  const [kycUrl, setKycUrl] = useState("");

  useEffect(() => {
    if (encryptedUrl) {
      console.log("🔹 Encrypted URL from params:", encryptedUrl); // Debugging
      const decryptedUrl = decryptUrl(encryptedUrl);
      console.log("✅ Decrypted KYC URL:", decryptedUrl); // Debugging
      setKycUrl(decryptedUrl);
    }
  }, [encryptedUrl]);

  useEffect(() => {
    if (kycUrl) {
      setTimeout(() => {
        console.log("🚀 Auto-submitting the form...");
        document.getElementById("kycRedirectionForm")?.submit();
      }, 2000); // 2-second delay before submission
    }
  }, [kycUrl]);

  const decryptUrl = (encryptedString) => {
    try {
      const normalized = encryptedString
        .replace(/_/g, "/")
        .replace(/-/g, "+")
        .replace(/\./g, "=");
      const bytes = CryptoJS.AES.decrypt(normalized, "dynamipass");
      const decryptedText = bytes.toString(CryptoJS.enc.Utf8);
      
      if (!decryptedText) {
        throw new Error("Decryption resulted in an empty string.");
      }

      return decryptedText;
    } catch (error) {
      console.error("❌ Decryption failed:", error);
      return "";
    }
  };

  return (
    <div>
      <table align="center">
        <tbody>
          <tr>
            <td>
              <strong>You are being redirected to the KYC portal</strong>
            </td>
          </tr>
          <tr>
            <td>
              <font color="blue">Please wait ...</font>
            </td>
          </tr>
          <tr>
            <td>(Please do not press 'Refresh' or 'Back' button)</td>
          </tr>
        </tbody>
      </table>

      {kycUrl && (
        <form id="kycRedirectionForm" method="post" action={kycUrl}>
          <input
            type="hidden"
            name="Aggregator_KYC_Req_No"
            value={proposalId}
          />
          <input type="hidden" name="IC_KYC_No" value="test_sample" />
          <input
            type="hidden"
            name="Aggregator_Return_URL"
            value="https://uat1.policies365.com/proposal#ownercontactform"
          />
        </form>
      )}
    </div>
  );
};

export default KYCRedirection;
