/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";

import UseMultistepForm from "../../MultipleForms/UseMultiStepForms";
import CarFormVehicleDetails from "../Cars/CarFormVehicleDetails";
import CarFormPersonDetails from "../Cars/CarFormPersonDetails";
import { useHistory, useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import * as api from "../../../API/authCurd";
import UseFormContext from "../../../../src/context/UseFormContext";
import {
  yearValidation,
  expDateValidation,
} from "../../commonModules/CommonCode";
function ContainerForCar(props) {
  const formContext = UseFormContext();
  const valuesForExpDate = expDateValidation();
  let history = useHistory();
  const { id } = useParams();
  const initialData = {
    isTextVisible: false,
    insuranceFor: "",
    registeredRto: "",
    make: "",
    model: "",
    varient: "",
    fuel: "",
    registrationYear: "",
    previousPolicy: valuesForExpDate?.todayConvertedValue,
    insuranceClaim: "false",
    tpPolicyStartDate: "",
    tpPolicyEndtDate: "",
    noClaimBonus: "25",
    policyType: "",
    policyTerms: "3",
    currentStepIndex: "0",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    formtype: "car",
    isvehNumberMissing: formContext.isvehNumberMissing,
    addOnCover: [
      {
        riderName: "Personal Accident cover",
        riderId: 11,
      },
      {
        riderId: 35,
        riderName: "LPG-CNG Kit",
        riderAmount: 10000,
      },
    ],
  };

  const validationSchema = Yup.object().shape({
    insuranceFor: Yup.string()
      .trim()
      .required("Insurance Field Is Required")
      .test("not blank", "Select value", (value) => {
        return value !== "";
      }),

    registeredRto: Yup.string().when("isvehNumberMissing", {
      is: false,
      then: () =>
        Yup.string()
          .required("Vehicle RTO code is required")
          .matches(
            /^[A-Za-z]{2}\d{2}$/,
            "Invalid Vehicle RTO code, e.g., MH12"
          ),
      otherwise: () =>
        Yup.string()
          .required("Vehicle Registration Number Is Required")
          .matches(
            /^(?:[A-Z]{2}\d{2}[A-Z]{1,2}\d{4}|[A-Z]{3}\d{4}|[A-Z]{2}\d{2} ?[A-Z]{1,2} ?\d{4}|CD \d{2} \d{3}|[A-Z]{2} \d{5}|[A-Z]{2}\d[A-Z]{1,2} ?[A-Z]{1,3} ?\d{4}|[0-9]{2}BH[0-9]{4})$/,
            "Invalid Vehicle Number, e.g., MH12AB1234, MAY2731, or 22BH0000"
          ),
    }),

    make: Yup.string().trim().required("Make Field Is Required"),
    model: Yup.string().trim().required("Model Field Is Required"),

    varient: Yup.string().trim().required("Variant Field Is Required"),

    fuel: Yup.string()
  
      .required("Fuel Field is required")
      .test("not blank", "Select value", (value) => {
        return value !== "";
      }),

    registrationYear: Yup.date()

      .required("Year Field is Required")
      .test("not blank", "Select value", (value) => value !== "")
      .test("valid date", "Future date is not allowed", (value) => {
        const currentDate = new Date();
        const inputDate = new Date(value);
        return inputDate <= currentDate;
      })
      .test(
        "valid range",
        "Date should be within the past 50 years",
        (value) => {
          return yearValidation(value, 50);
        }
      ),

    previousPolicy: Yup.string()
      .trim()
      .required("Previous Policy Is Required")
      .test("not blank", "Select value", (value) => {
        return value !== "";
      }),
    insuranceClaim: Yup.string()
      .trim()
      .required("Insurance Claim Field Is Required")
      .test("not blank", "Select value", (value) => {
        return value !== "";
      }),

    noClaimBonus: Yup.string().when("insuranceClaim", {
      is: "false",
      then: () => Yup.string().required("Field Is Required"),
    }),

    currentStepIndex: Yup.string().required("Field Is Required"),
    policyType: Yup.string().trim().required("Policy Type Field Is Required"),

    firstName: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () =>
        Yup.string()
          .required(" First Name Is Required")
          .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed"),
    }),

    lastName: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () =>
        Yup.string()
          .required(" Last Name Is Required")
          .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed"),
    }),

    email: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () =>
        Yup.string()
          .required("Email is required")
          .matches(
            /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/,
            "Wrong email format"
          )
          .test(
            "no-leading-trailing-dot",
            "Email cannot start or end with a dot",
            (value) => (value ? !/^\.|\.@/.test(value) : true)
          )
          .test(
            "no-consecutive-dots",
            "Email cannot contain consecutive dots",
            (value) => (value ? !/\.{2,}/.test(value) : true)
          )
          .test(
            "valid-domain",
            "Invalid domain format",
            (value) =>
              value
                ? /^[a-zA-Z0-9._%+-]+@(?!(?:-))[a-zA-Z0-9-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/.test(
                    value
                  ) && !/@-/.test(value) // Ensures domain does not start with a hyphen
                : true
          )
          .max(50, "Maximum 50 characters allowed"),
    }),
    

    //  email:Yup.string().when("currentStepIndex", {
    //       is: "1",
    //       then: () =>
    //         Yup.string()
    //           .required("Email is required")
    //           .matches(
    //             /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    //             "Wrong email format"
    //           )
    //           .test(
    //             "no-leading-trailing-dot",
    //             "Email cannot start or end with a dot",
    //             (value) => value ? !/^\.|@\./.test(value) && !/@.+\.$/.test(value) : true
    //           )
    //           .test(
    //             "no-consecutive-dots",
    //             "Email cannot contain consecutive dots",
    //             (value) => !!value && !/\.\./.test(value)
    //           )
    //           .test(
    //             "valid-domain",
    //             "Invalid domain format",
    //             (value) => value
    //               ? /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/.test(value)
    //               : true
    //           )
    //           .max(50, "Maximum 50 characters allowed"),
    //     }),  
 
    phoneNumber: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () =>
        Yup.string()
          .required(" Phone Number Is Required")
          .matches(/^[0-9\b]+$/, "Invalid Phone number !")
          .min(10, "Minimum 10 digit required")
          .max(10, "Maximum 10 digit required"),
    }),
  });

  const formik = useFormik({
    initialValues: initialData,
    validationSchema: validationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      let resData = values;
      setSubmitting(true);
      if (!isLastStep) return next();
      formContext.setsecondLoaderStatus(true);
      localStorage.setItem("policyType", values.policyType);
      localStorage.setItem("ownerEmail", values.email);
      api
        .createQoteApiCar(values)
        .then((data) => {
          redirectMethod(values, data);
        })
        .catch((err) => {
          formContext.notifyError("An error occurred while fetching data");
        })
        .finally(() => {
          // Set loader back to false
          formContext.setsecondLoaderStatus(false);
        });
    },
  });

  const redirectMethod = (values, data) => {
    const JsonDataForCreateQuotes = JSON.parse(data.data);
    formContext.setPolicyDates(JsonDataForCreateQuotes);
    sessionStorage.setItem("quoteId", JsonDataForCreateQuotes?.QUOTE_ID);
    formContext.setcreatequotesresult(JsonDataForCreateQuotes.data);
    formContext.setroutingPath("/car/1");
    formContext.setcarbikeformikValues(values);
    history.push({
      pathname: "/quotes",
      state: {
        values: values,
        apiResopnse: JsonDataForCreateQuotes.data,
        prePage: "car",
      },
    });
  };

  // Function to clear the input field value using Formik's setFieldValue
  // @param {string} value - The name of the Formik field to clear
  const clearIcon = (value) => {
    formik.setFieldValue(value, "");
  };

  // Destructuring variables from the custom hook UseMultistepForm
  const { currentStepIndex, step, isFirstStep, isLastStep, back, next } =
    UseMultistepForm([
      <CarFormVehicleDetails
        {...props}
        formik={formik}
        clearIcon={clearIcon}
      />,
      <CarFormPersonDetails {...props} formik={formik} />,
    ]);
  //  to add n remove addon
  useEffect(() => {
    let uniqueData = formik.values.addOnCover?.filter(
      (filterData) => filterData?.riderId !== 11
    );

    if (formik.values.policyType === "OD") {
      formik.setFieldValue("addOnCover", uniqueData);
    } else {
      uniqueData.push({
        riderName: "Personal Accident cover",
        riderId: 11,
      });

      formik.setFieldValue("addOnCover", uniqueData);
    }
  }, [formik.values.policyType]);

  useEffect(() => {
    let uniqueData = formik.values.addOnCover?.filter(
      (filterData) => filterData?.riderId !== 35
    );
    const value1 = "petrol+lpg";
    const value2 = "petrol+cng";

    if (formik.values.fuel !== value1 && formik.values.fuel !== value2) {
      formik.setFieldValue("addOnCover", uniqueData);
    } else {
      uniqueData.push({
        riderId: 35,
        riderName: "LPG-CNG Kit",
        riderAmount: 10000,
      });

      formik.setFieldValue("addOnCover", uniqueData);
    }
  }, [formik.values.fuel]);
  // when back button pressed on quotes page

  useEffect(() => {
    if (id && formContext.carbikeformikValues) {
      formik.setValues({
        // ...formik.initialValues,
        ...formContext.carbikeformikValues,
      });
    }
  }, []);
  // Update the "currentStepIndex" field in Formik whenever the "currentStepIndex" state changes
  useEffect(() => {
    formik.setFieldValue("currentStepIndex", currentStepIndex);
  }, [currentStepIndex]);
  useEffect(() => {
    if (formik.values.insuranceClaim) {
      formik.setFieldValue("noClaimBonus", "0");
    } else {
      formik.setFieldValue("noClaimBonus", "25");
    }
  }, [formik.values.insuranceClaim]);
  // policyTearms for insuranceFor is changed
  useEffect(() => {
    if (formik.values.insuranceFor === "renew") {
      formik.setFieldValue("policyTerms", 1);
    } else {
      formik.setFieldValue("policyTerms", 3);
    }
  }, [formik.values.insuranceFor]);
  // CRM code end
  useEffect(() => {
    const fetchData = async () => {
      try {
        const crmToken = await formContext.setCrmTokenMethod();
        api
          .crmGetLoginUser(crmToken.data.token)
          .then((res) => {
            formContext.setcrmLoggedUser(res?.data?.output?.motor?.[0]);
          })
          .catch((err) => {
            formContext.setcrmLoggedUser();
          });
      } catch (err) {
        formContext.setcrmLoggedUser();
        console.error("Error fetching CRM token or user data", err);
      }
    };
    fetchData();
  }, []);
  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        {step}
        <div>
          {!isFirstStep && !formContext.secondLoaderStatus && (
            <Button onClick={back} className="mt-4 back-btn">
              Back
            </Button>
          )}
          <Button
            type="submit"
            className="mt-4 primary-btn"
            disabled={formContext.secondLoaderStatus}
          >
            {isLastStep ? (
              <>
                {formContext.secondLoaderStatus ? (
                  <>{formContext.spinner}</>
                ) : (
                  "Get Quotes"
                )}
              </>
            ) : (
              "Next"
            )}
          </Button>
        </div>
      </form>
    </div>
  );
}

export default ContainerForCar;
