import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
} from "react-bootstrap";
import ErrorMessage from "../../commonModules/ErrorMessage";
import InputFieldDropdown from "../../commonModules/InputFieldDropdown";
import InputFieldText from "../../commonModules/InputFieldText";
import { formikFieldVisibility } from "../../commonModules/CommonCode";
import { gateage } from "../../commonModules/CommonCode";

export default function Nominee(props) {
  console.log("props",props)

const carrier = props.carrierType
  const [relation, setRelation] = useState([
    { value: "", label: "Please Select" }, 
    { value: "MOTHER", label: "Mother" },
    { value: "FATHER", label: "Father" },
    { value: "SON", label: "Son" },
    { value: "DAUGHTER", label: "Daughter" },
    { value: "BROTHER", label: "Brother" },
    { value: "GRAND FATHER", label: "Grand Father" }, 
    { value: "GRAND MOTHER", label: "Grand Mother" },
    { value: "BROTHER-IN-LAW", label: "Brother in law" },
    { value: "MOTHER IN LAW", label: "Mother in law" },
    { value: "SISTER-IN-LAW", label: "Sister in law" },
    { value: "SISTER", label: "Sister" },
    { value: "HUSBAND", label: "Husband" },
    { value: "WIFE", label: "Wife" },

  ]);

  const [relationForHDFC, setRelationForHDFC] = useState([
    { value: "", label: "Please Select" }, 
    { value: "Spouse", label: "Spouse" },
    { value: "Nephew", label: "Nephew" },
    { value: "Niece", label: "Niece" },
    { value: "Uncle", label: "Uncle" },
    { value: "DaughterInLaw", label: "Daughter in law" },
    { value: "SonInLaw", label: "Son in law" },
    { value: "LegalGuardian", label: "Legal Guardian" },
    { value: "Brother", label: "Brother" },
    { value: "Child", label: "Child" },
    { value: "Daughter", label: "Daughter" },
    { value: "Employee", label: "Employee" },
    { value: "Father", label: "Father" },
    { value: "FatherInLaw", label: "Father in law" },
    { value: "GrandDaughter", label: "Grand Daughter" },
    { value: "GrandFather", label: "Grand Father" },
    { value: "GrandMother", label: "Grand Mother" },
    { value: "GrandSon", label: "Grand Son" },
    { value: "Husband", label: "Husband" },
    { value: "Mother", label: "Mother" },
    { value: "MotherInLaw", label: "Mother in law" },
    { value: "Partner", label: "Partner" },
    { value: "PolicyHolder", label: "Policy Holder" },
    { value: "Sister", label: "Sister" },
    { value: "Son", label: "Son" },
    { value: "SpecialConcessionAdult", label: "Special concession adult" },
    { value: "SpecialConcessionChild", label: "Special concession child" },
    { value: "Wife", label: "Wife" }
]);

  // useEffect to set initial visibility of formik fields for nominee information
  useEffect(() => {
    formikFieldVisibility(["nomineFirstName", "nomineLastName", "nomineDob", "nomineRelation", "appointeeName", "appointeeDOB", "appointeeRelation"], props.formik)
    console.log("props.formik--->",props.formik)
    sessionStorage.setItem("nextPage","yes")
  }, []);

  return (
    <React.Fragment>
      <div>
        <Row>
          <Col sm={4} className="mb-4">
            <InputFieldText formikFieldName="nomineFirstName" placeholder="First name*" formik={props.formik} />
            <ErrorMessage formik={props.formik} fieldValue="nomineFirstName" />
          </Col>
          <Col sm={4} className="mb-4">
            <InputFieldText formikFieldName="nomineLastName" placeholder="Last Name*" formik={props.formik} />
            <ErrorMessage formik={props.formik} fieldValue="nomineLastName" />
          </Col>
          <Col sm={4} className="mb-4">
            <InputFieldText formikFieldName="nomineDob" placeholder="Date of birth*" formik={props.formik} type="date" />
            <ErrorMessage formik={props.formik} fieldValue="nomineDob" />
          </Col>

          <Col sm={4} className="mb-4">
            <InputFieldDropdown formikFieldName="nomineRelation" label="Relation*" optionsArray={carrier==="HDFC ERGO General Insurance Company" ? relationForHDFC :relation}
              formik={props.formik} />
          </Col>
          <ErrorMessage formik={props.formik} fieldValue="nomineRelation" />
        </Row>
        {gateage(props.formik?.values?.nomineDob) < 18 && 
        <div>
          <p>Please enter Appointee Details as the nominee is minor</p>
          <Row>
            <Col sm={4} className="mb-4">
              <InputFieldText formikFieldName="appointeeName" placeholder="Appointee Name*" formik={props.formik} />
              <ErrorMessage formik={props.formik} fieldValue="appointeeName" />
            </Col>
            <Col sm={4} className="mb-4">
              <InputFieldText formikFieldName="appointeeDOB" placeholder="Appointee Date of birth*" formik={props.formik} type="date" />
              <ErrorMessage formik={props.formik} fieldValue="appointeeDOB" />
            </Col>
            <Col sm={4} className="mb-4">
              <InputFieldDropdown formikFieldName="appointeeRelation" label="Relation*" optionsArray={carrier==="HDFC ERGO General Insurance Company" ? relationForHDFC :relation}
                formik={props.formik} />
            </Col>
            <ErrorMessage formik={props.formik} fieldValue="appointeeRelation" />
          </Row>
        </div>}
      </div>
    </React.Fragment>
  );
}



