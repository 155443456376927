import { useState, React, useEffect } from "react";
import axios from "axios";
import UseMultistepForm from "./UseMultiStepForms";
import OwnerContactInfo from "./OwnerContactInfo";
import AddressDetailsofVehicalDetails from "./AddressDetailsofVehicalDetails";
import Benefits from "./Benefits";
import PreviousPolicyForm from "./PreviousPolicyForm";
import InsuredVehical from "./InsuredVehical";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Done from "../../images/Done.svg";
import SVG from "react-inlinesvg";
import * as Yup from "yup";
import { useFormik } from "formik";
import KYCForm from "./KYCForm";
import PaymentForm from "./PaymentForm";
import * as api from "../../API/authCurd";
import UseFormContext from "../../context/UseFormContext";
import { yearValidation, convertDate } from "../commonModules/CommonCode";
import VerticallyCenteredModal from "../commonModules/Popups/VerticallyCenteredModal";
import { useHistory } from "react-router-dom";
import {
  bajajKycVerify,
  bajajDocumentUploadForKyc,
} from "../../API/kyc/bajaj-kyc.service";
import { BiSolidError } from "react-icons/bi";
import FileUpload from "../commonModules/FileUpload";
import BajajUploadDocForKYC from "../Bajaj/BajajUploadDocForKYC";
import BajajPOA from "../Bajaj/BajajPOA";
import {
  hdfcKycVerify,
  generateTokenForHdfcKyc,
} from "../../API/kyc/hdfc-kyc.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Share from "../../images/share.svg";
import MailService from "../../services/mail.service";
import {
  EmailIcon,
  EmailShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from "react-share";
import JsonDbCrud from "../../jsondb-crud/crud";
import { decryptData } from "../../services/auth-service";
import { bajajKycVerifyPOA } from "../../API/kyc/bajaj-kyc.service";
import { expDateValidation } from "../commonModules/CommonCode";
import {
  bikeFormikIntialData,
  initialDataQuotesPage,
} from "../commonModules/CommonCode";
var CryptoJS = require("crypto-js");

const Popup = ({ onClose, show }) => {
  return (
    <Modal show={show} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>
          Proof Of Identity Failed. We can not Proceed ahead.
        </Modal.Title>
      </Modal.Header>
      <Modal.Footer>
        <Button type="button" className="primary-btn" onClick={onClose}>
          Okay!
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

function ContainerForForm(props) {
  const [idvSelectedValue, setidvSelectedValue] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const valuesForExpDate = expDateValidation();
  const formContext = UseFormContext();

  const [user, setUser] = useState(null);
  useEffect(() => {
    decryptUser();
  }, []);

  const encryptEmail = (email) => {
    if (email && email !== undefined && email !== null) {
      const encrypted = CryptoJS?.AES?.encrypt(email, "dynamipass").toString();
      return encrypted
        .replace(/\//g, "_")
        .replace(/\+/g, "-")
        .replace(/=/g, ".");
    }
  };

  // for agent user email , decrypt user
  const decryptUser = async () => {
    const encryptedUser = localStorage.getItem("user");
    console.log("user", encryptedUser);
    if (encryptedUser) {
      try {
        const user1 = await decryptData(encryptedUser);
        setUser(user1);
        return user1;
      } catch (error) {
        console.error("Error decrypting user:", error);
      }
    }
  };

  const GoDigitEmailTrigger = async () => {
    console.log("agent email", user?.email);
    console.log("customer email", localStorage.getItem("ownerEmail"));

    try {
      const to = localStorage.getItem("ownerEmail");
      const bcc = ["abhijit.patil@navnitinsurance.com"];
      const cc = ["sauravp@comsenseconsulting.com"];
      const subject = `Your KYC is Pending - Complete it to Generate Your Policy`;
      const content = `
              <!DOCTYPE html>
              <html lang="en">
              <head>
                  <meta charset="UTF-8">
                  <meta name="viewport" content="width=device-width, initial-scale=1.0">
                  <title>KYC Pending - Policy Generation</title>
              </head>
              <body style="font-family: Arial, sans-serif; line-height: 1.6;">
                  <table width="100%" cellpadding="0" cellspacing="0" border="0">
                      <tr>
                          <td>
                              <table align="center" width="600" cellpadding="0" cellspacing="0" border="0" style="border: 1px solid #ddd; padding: 20px; background-color: #f9f9f9;">
                                  <tr>
                                      <td style="padding: 10px 0; text-align: center;">
                                          <h1 style="font-size: 24px; color: #333;">Policy365</h1>
                                      </td>
                                  </tr>
                                  <tr>
                                      <td style="padding: 20px; color: #555;">
                                          <p>Dear Customer,</p>
                                          <p>We would like to inform you that your KYC (Know Your Customer) process is still pending. In order to generate your policy, you must complete your KYC first.</p>
                                          <p>Please visit the following URL to complete your KYC: <a href="${iframeUrl}">${iframeUrl}</a></p>
                                          <p>If you have any questions or need further assistance, please do not hesitate to contact our customer support team.</p>
                                          <p>Thank you for choosing Policy365.</p>
                                          <p>Best regards,</p>
                                          <p>Policy365<br>
                                              Policy365 Customer Support<br>
                                              022-62526252<br>
                                              contact@policies365.com</p>
                                      </td>
                                  </tr>
                              </table>
                          </td>
                      </tr>
                  </table>
              </body>
              </html>
          `;

      // Send mail
      const data1 = { to, bcc, cc, subject, content };

      MailService.sendMail(data1).then((res) => {});
    } catch {
      console.error("Error sending email:");
    }
  };

  const [consentGiven, setConsentGiven] = useState(false);
  const handleCheckboxChange = (event) => {
    setConsentGiven(event.target.checked);
  };
  const [pdfDataUrl, setPdfDataUrl] = useState("");
  // const [pdfDataUrl, setPdfDataUrl] = useState(`data:application/pdf;base64,${base64Pdf}`);
  const [hdfcDataForPayment, sethdfcDataForPayment] = useState("");
  const [hdfcValueForPayment, sethdfcValuesForPayment] = useState("");
  const [iciciDataForPayment, seticiciDataForPayment] = useState("");
  const [iciciValueForPayment, seticiciValuesForPayment] = useState("");
  const [showPdfModel, setShowPdfModel] = useState(false);
  const [consentSave, setConsentSave] = useState(false);
  const [modalShow, setModalShow] = useState(false);

  const currentYear = new Date().getFullYear();
  const minRegistrationYear = currentYear - 50;
  const [errorModalShow, setErrorModalShow] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  let paymentdataDestructure = {};
  let history = useHistory();
  const [iframeUrl, setiframeUrl] = useState();
  const [iframeStatus, setiframeStatus] = useState(false);
  const [kycPopUpStatus, setkycPopUpStatus] = useState(false);
  const [kycPopUp, setkycPopUp] = useState(false);
  const [kycPopUpStatusNewInd, setkycPopUpStatusNewInd] = useState();
  const [iframeOpenedCarrier, setiframeOpenedCarrier] = useState();

  const [showBajajDocUpload, setShowBajajDocUpload] = useState(false);
  const [showBajajPOA, setShowBajajPOA] = useState(false);
  const [showBajajError, setShowBajajError] = useState(false);
  const [kycFailUrl, setkycFailUrl] = useState();
  const policyType =
    props.carbikeformikValues?.insuranceFor ||
    formContext?.proposalData?.insuranceType;
  const [kycTokenIcici, setkycTokenIcici] = useState();
  const objForUpdateStage = {};
  const [kycStatus, setkycStatus] = useState();
  const [iciciKycData, seticiciKycData] = useState({
    poiFile: "", //proof of identity
    poaFile: "",
    poitype: "",
    poatype: "",
  });
  const [poiType, setpoiType] = useState([
    { value: "", label: "Please select" },
    { value: "PAN", label: "PAN" },
    { value: "AADHAAR", label: "AADHAAR" },
    { value: "VOTERID", label: "VOTERID" },
    { value: "DL", label: "DRIVING LICENSE" },
    { value: "PASSPORT", label: "PASSPORT" },
  ]);
  const [poAType, setpoAType] = useState([
    { value: "", label: "Please select" },
    { value: "AADHAAR", label: "AADHAAR" },
    { value: "VOTERID", label: "VOTERID" },
    { value: "DL", label: "DRIVING LICENSE" },
    { value: "PASSPORT", label: "PASSPORT" },
  ]);
  const [icicikycError, seticicikycError] = useState(false);
  const [iciciApiError, seticiciApiError] = useState(false);
  const [kycLoader, setKycLoader] = useState(false);
  const [shareUrl, setShareUrl] = useState("");
  const [title, setTitle] = useState(
    "Please complete payment process via link to generate your policy :"
  );
  const [iciciPyamentLink, setIciciPyamentLink] = useState(false);
  const objForCreateLead = {};
  console.log("formcontext", formContext);
  const [isCopied, setIsCopied] = useState(false);
  const [futureKycProposalId, setfutureKycProposalId] = useState();
  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(shareUrl)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => setIsCopied(false), 2000); // Reset the copied state after 2 seconds
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  // formik methods
  const initialData = {
    currentStepIndex: "0",
    policyType: formContext.quotesPageFormikData?.policyType,

    kycNominePanNumber: formContext?.kycData?.kycNominePanNumber || "",
    kycNomineAadharNumber: formContext?.kycData?.kycNomineAadharNumber || "",
    kycNomineDob: formContext?.kycData?.kycNomineDob || "",
    kycNomineGender: formContext?.kycData?.kycNomineGender || "",
    kycNomineFullName: formContext?.kycData?.kycNomineFullName || "",
    kycType: "pan",

    ownerPrefix: "",
    ownerFirstName: "",
    ownerLastName: "",
    ownerMiddleName: "",
    ownerEmail: "",
    ownerPhoneNumber: "",
    ownerMaritialStus: "",
    ownerGstin: "",

    nomineFirstName: "",
    nomineLastName: "",
    nomineDob: "",
    nomineRelation: "",

    ownerFlatNumber: "",
    ownerAddress: "",
    ownerLocation: "",
    ownerPincode: "",
    ownerCity: "",
    ownerState: "",
    isRegisteredAddressSame: false,
    ownerFlatNumberReg: "",
    ownerAddressReg: "",
    ownerLocationReg: "",
    ownerPincodeReg: "",
    ownerCityReg: "",
    ownerStateReg: "",

    policyNumber: "",
    policyInsuranceName: "",
    policyInsuranceId: "",
    // PolicyStartDate: "",
    PolicyEndtDate: "",
    policyInsuranceNamePrevious: "",
    policyPreviousType: "",
    tpPolicyNumber: "",
    tpPolicyInsuranceName: "",

    vehicalMakeModel: "",
    vehicalRegistionDate: "",
    vehicalManufaDate: "",
    vehicalPurchaseLoan: "",
    vehicalFinancierName: "",
    financierID: "",
    financierName: "",
    financierNameValidaton: "no",
    vehicalReferalcode: "",
    vehicalTermAccept: "",
    chassisNumber: "",
    engineNumber: "",
    registeredRto: "",
    puc: "",
    kycFailedData: "",
    pucNumber: "",
    pucExpiryDate: "",
    alternatePolicyExpDateFuture: "",
    existingPAPolicyNo: "",
    existingPASumInsured: 1500000,
    insurerName: "",
    validLicense: "",
    futurePA: false,
  };

  useEffect(() => {
    if (formContext.quotesPageFormikData?.alternatePolicyExpDateFuture) {
      formik.setFieldValue(
        "alternatePolicyExpDateFuture",
        formContext.quotesPageFormikData?.alternatePolicyExpDateFuture
      );
    }
    if (formContext.quotesPageFormikData?.existingPAPolicyNo) {
      formik.setFieldValue(
        "existingPAPolicyNo",
        formContext.quotesPageFormikData?.existingPAPolicyNo
      );
    }
    if (formContext.quotesPageFormikData?.existingPASumInsured) {
      formik.setFieldValue(
        "existingPASumInsured",
        formContext.quotesPageFormikData?.existingPASumInsured
      );
    }
    if (formContext.quotesPageFormikData?.insurerName) {
      formik.setFieldValue(
        "insurerName",
        formContext.quotesPageFormikData?.insurerName
      );
    }
    if (formContext.quotesPageFormikData?.validLicense) {
      formik.setFieldValue(
        "validLicense",
        formContext.quotesPageFormikData?.validLicense
      );
    }
  }, [formContext?.quotesPageFormikData]);

  const validationSchema = Yup.object().shape({
    // First page

    kycNominePanNumber: Yup.string()
      .trim()
      .matches(/^[A-Z]{5}[0-9]{4}[A-Z]$/, "Invalid PAN Number")
      .required("PAN Number Is Required"),

    kycNomineDob: Yup.string()
      .trim()
      .required("DOB Is Required")
      .test("not blank", "Select value", (value) => value !== "")
      .test("valid date", "Future date is not allowed", (value) => {
        const currentDate = new Date();
        const inputDate = new Date(value);
        return inputDate <= currentDate;
      })
      .test(
        "valid range",
        "Date should be within the past 100 years",
        (value) => {
          return yearValidation(value, 100);
        }
      ),

    kycNomineGender: Yup.string()
      .trim()
      .required("Gender Is Required")
      .test("not blank", "Select value", (value) => {
        return value !== "";
      }),

    currentStepIndex: Yup.string().required("Field is required"),

    //second page
    ownerPrefix: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () =>
        Yup.string()
          .required(" Prefix Is Required")
          .test("not blank", "Select value", (value) => {
            return value !== "";
          }),
    }),
    ownerFirstName: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () =>
        Yup.string()
          .matches(/^[a-zA-Z ]*$/, "Invalid First Name")
          .required(" First Name Is Required"),
    }),

    ownerMiddleName: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () => Yup.string().matches(/^[a-zA-Z ]*$/, "Invalid Middle Name"),
    }),

    ownerLastName: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () =>
        Yup.string()
          .matches(/^[a-zA-Z ]*$/, "Invalid Last Name")
          .required(" Last Name Is Required"),
    }),

    ownerEmail: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () =>
        Yup.string()
          .required("Email is required")
          .email("Invalid email format")
          .matches(
            /^(?!\.)(?!.*\.\.)(?!.*\.$)[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
            "Invalid email format"
          ),
    }),

    ownerPhoneNumber: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () =>
        Yup.string()
          .matches(/^\d{10}$/, "Phone Number must be 10 digits")
          .required(" Phone Number Is Required"),
    }),

    ownerMaritialStus: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () =>
        Yup.string()
          .required(" Maritial Status Is Required")
          .test("not blank", "Select value", (value) => {
            return value !== "";
          }),
    }),

    // third page
    nomineFirstName: Yup.string().when("currentStepIndex", {
      is: "2",
      then: () =>
        Yup.string()
          .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed")
          .required(" First Name Is Required"),
    }),

    nomineLastName: Yup.string().when("currentStepIndex", {
      is: "2",
      then: () =>
        Yup.string()
          .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed")
          .required(" Last Name Is Required"),
    }),

    nomineDob: Yup.string().when(
      ["currentStepIndex", "nomineRelation", "kycNomineDob"],
      {
        is: (currentStepIndex) => currentStepIndex === "2",
        then: () =>
          Yup.string()
            .required("DOB Is Required")
            .test("not blank", "Select value", (value) => value !== "")
            .test("valid date", "Future date is not allowed", (value) => {
              const currentDate = new Date();
              const inputDate = new Date(value);
              return inputDate <= currentDate;
            })
            .test(
              "valid range",
              "Date should be within the past 100 years",
              (value) => {
                return yearValidation(value, 100);
              }
            )
            .test("date comparison", "Invalid Date", function (value) {
              const userDob = new Date(this.parent.kycNomineDob);
              const nomineeDob = new Date(value);

              if (
                this.parent.nomineRelation === "son" ||
                this.parent.nomineRelation === "daughter"
              ) {
                return (
                  nomineeDob > userDob ||
                  this.createError({
                    message: "Nominee DOB should be after user DOB",
                  })
                );
              } else if (
                this.parent.nomineRelation === "mother" ||
                this.parent.nomineRelation === "father"
              ) {
                return (
                  nomineeDob < userDob ||
                  this.createError({
                    message: "Nominee DOB should be before user DOB",
                  })
                );
              }

              return true;
            }),
        else: Yup.string(),
      }
    ),

    nomineRelation: Yup.string().when("currentStepIndex", {
      is: "2",
      then: () =>
        Yup.string()
          .required(" Relation Of Nomine Is Required")
          .test("not blank", "Select value", (value) => {
            return value !== "";
          }),
    }),

    // 4th page

    ownerFlatNumber: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () =>
        Yup.string()
          .matches(/^[0-9]+$/, "Flat Number must be a number")
          .required(" Flat Number Is Required"),
    }),

    ownerAddress: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required("Address is required"),
      // .max(120, "Address cannot be longer than 120 characters"), // max length validation
    }),

    ownerLocation: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required(" Location Is Required"),
    }),
    ownerPincode: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () =>
        Yup.string()
          .matches(/^\d{6}$/, "Invalid PIN code")
          .required(" Pincode Is Required"),
    }),

    ownerCity: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required(" City Is Required"),
    }),

    ownerState: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required(" State Is Required"),
    }),
    isRegisteredAddressSame: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required("Field is required"),
    }),

    ownerFlatNumberReg: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () =>
        Yup.string()
          .matches(/^[0-9]+$/, "Flat Number must be a number")
          .required("  Reg Flat Number Is Required"),
    }),

    ownerAddressReg: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required(" Reg Address Is Required"),
    }),

    ownerLocationReg: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required(" Reg Location Is Required"),
    }),
    ownerPincodeReg: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () =>
        Yup.string()
          .matches(/^\d{6}$/, "Invalid PIN code")
          .required(" Reg Pincode Is Required"),
    }),

    ownerCityReg: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required(" Reg City Is Required"),
    }),

    ownerStateReg: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required("Reg State Is Required"),
    }),

    //page 5
    policyNumber: Yup.string().when("currentStepIndex", {
      is: "4",
      then: () =>
        Yup.string()
          .matches(
            /^[a-zA-Z0-9\/-]*$/,
            "Only letters, numbers, and '/' are allowed"
          )
          .max(50, "Maximum length must be 50 characters")
          .min(4, "Minimum length must be 4 characters")
          .required("Policy Number Is Required"),
    }),

    policyInsuranceNamePrevious: Yup.string().when("currentStepIndex", {
      is: "4",
      then: () => Yup.string().required("Previous Policy Name Is Required"),
    }),

    PolicyEndtDate: Yup.string().when("currentStepIndex", {
      is: "4",
      then: () =>
        Yup.string()
          .required("Policy End Date Is Required")
          .test(
            "valid date",
            "Policy End Date should be within the past 50 years",
            (value) => {
              const currentDate = new Date();
              const selectedDate = new Date(value);
              const yearDifference =
                currentDate.getFullYear() - selectedDate.getFullYear();
              return yearDifference <= 50;
            }
          ),
    }),

    policyInsuranceName: Yup.string().when("currentStepIndex", {
      is: "4",
      then: () =>
        Yup.string()
          .required(" Policy Insurance Name Is Required")
          .test("not blank", "Select value", (value) => {
            return value !== "";
          }),
    }),

    tpPolicyInsuranceName: Yup.string().when(
      ["currentStepIndex", "policyType"],
      {
        is: (currentStepIndex, policyType) =>
          currentStepIndex === "4" && policyType === "OD",
        then: () =>
          Yup.string()
            .required("TP Policy Insurer Name Is Required")
            .test("not blank", "Select value", (value) => {
              return value !== "";
            }),
      }
    ),

    tpPolicyNumber: Yup.string().when(["currentStepIndex", "policyType"], {
      is: (currentStepIndex, policyType) =>
        currentStepIndex === "4" && policyType === "OD",
      then: () =>
        Yup.string()
          .matches(
            /^[a-zA-Z0-9\/-]*$/,
            "Only letters, numbers, '/', and '-' are allowed"
          )
          .max(30, "Maximum length must be 30 characters")
          .min(4, "Minimum length must be 4 characters")
          .required("TP policy Number Is Required"),
    }),

    policyPreviousType: Yup.string().when("currentStepIndex", {
      is: "4",
      then: () =>
        Yup.string()
          .required("Previous policy Type Is Required")
          .test("not blank", "Select value", (value) => {
            return value !== "";
          }),
    }),

    // page 6

    vehicalMakeModel: Yup.string().when("currentStepIndex", {
      is: "5",
      then: () => Yup.string().required(" Make and Model Is Required"),
    }),

    vehicalRegistionDate: Yup.string().when("currentStepIndex", {
      is: "5",
      then: () =>
        Yup.string()
          .required("Registration Date Is Required")
          .test("not blank", "Select value", (value) => value !== "")
          .test("valid date", "Future date is not allowed", (value) => {
            const currentDate = new Date();
            const inputDate = new Date(value);
            return inputDate <= currentDate;
          })
          .test(
            "valid range",
            "Date should be within the past 50 years",
            (value) => {
              return yearValidation(value, 50);
            }
          ),
    }),

    vehicalManufaDate: Yup.string().when("currentStepIndex", {
      is: "5",
      then: () => Yup.string().required(" Manufacturing Year Is Required"),
    }),

    vehicalPurchaseLoan: Yup.string().when("currentStepIndex", {
      is: "5",
      then: () =>
        Yup.string()
          .required("Bike Purchased on Loan Is Required")
          .test("not blank", "Select value", (value) => {
            return value !== "";
          }),
    }),

    vehicalFinancierName: Yup.string().when(
      ["currentStepIndex", "vehicalPurchaseLoan"],
      {
        is: (currentStepIndex, vehicalPurchaseLoan) =>
          currentStepIndex === "5" && vehicalPurchaseLoan === "yes",
        then: () =>
          Yup.string()
            .required("Vehicle Financier Name is Required")
            .min(4, "Minimum length must be 4 characters"),
      }
    ),

    financierNameValidaton: Yup.string().when("currentStepIndex", {
      is: "5",
      then: () =>
        Yup.string().required("Vehicle Registration Number Is Required"),
    }),

    financierID: Yup.string().when(
      ["currentStepIndex", "vehicalPurchaseLoan", "financierNameValidaton"],
      {
        is: (currentStepIndex, vehicalPurchaseLoan, financierNameValidaton) =>
          currentStepIndex === "5" &&
          vehicalPurchaseLoan === "yes" &&
          financierNameValidaton === "yes",
        then: () =>
          Yup.string().required(
            "Name not found,please pick name from dropdown only"
          ),
      }
    ),
    engineNumber: Yup.string().when("currentStepIndex", {
      is: "5",
      then: () =>
        Yup.string()
          .matches(
            /^[a-zA-Z0-9/ ]*$/,
            "Only letters, numbers, and spaces are allowed"
          )
          .max(20, "Maximum length must be 20 characters")
          // .min(5, "Minimum length must be 5 characters")
          .required("Engine Number Is Required"),
    }),

    chassisNumber: Yup.string().when("currentStepIndex", {
      is: "5",
      then: () =>
        Yup.string()
          .matches(/^[a-zA-Z0-9/]*$/, "Only letters and numbers are allowed")
          .min(17, "Minimum length must be 17 characters")
          .max(25, "Maximum length must be 25 characters")
          .required("Chassis Number Is Required"),
    }),

    registeredRto: Yup.string().when("currentStepIndex", {
      is: "5",
      then: () =>
        Yup.string()
          .required("Vehicle Registration Number Is Required")
          .matches(
            /^(?:[A-Z]{2}\d{2}[A-Z]{1,2}\d{4}|[A-Z]{3}\d{4}|[A-Z]{2}\d{2} ?[A-Z]{1,2} ?\d{4}|CD \d{2} \d{3}|[A-Z]{2} \d{5}|[A-Z]{2}\d[A-Z]{1,2} ?[A-Z]{1,3} ?\d{4}|[0-9]{2}BH[0-9]{4})$/,
            "Invalid Vehicle Number, e.g., MH12AB1234, MAY2731, or 22BH0000"
          ),
    }),

    // registeredRto: Yup.string().when("currentStepIndex", {
    //   is: "5",
    //   then: () =>
    //     Yup.string()
    //       .required("Vehicle Registration Number Is Required")
    //       .matches(
    //         /^[A-Z]{2}\d{2}[A-Z]{1,2}\d{4}$/,
    //         "Invalid Vehicle Number,Eg MH12AAXXXX"
    //       ),
    // }),

    vehicalTermAccept: Yup.boolean().when("currentStepIndex", {
      is: "5",
      then: () =>
        Yup.boolean()
          .oneOf([true], "You must accept the terms and conditions")
          .required("You must accept the terms and conditions"),
    }),
  });

  const validationSchema2 = Yup.object().shape({
    // First page
    kycNominePanNumber: Yup.string()
      .trim()
      .matches(/^[A-Z]{5}[0-9]{4}[A-Z]$/, "Invalid PAN Number")
      .required("PAN Number Is Required"),

    kycNomineDob: Yup.string()
      .trim()
      .required("DOB Is Required")
      .test("not blank", "Select value", (value) => value !== "")
      .test("valid date", "Future date is not allowed", (value) => {
        const currentDate = new Date();
        const inputDate = new Date(value);
        return inputDate <= currentDate;
      })
      .test(
        "valid range",
        "Date should be within the past 100 years",
        (value) => {
          return yearValidation(value, 100);
        }
      ),

    kycNomineGender: Yup.string()
      .trim()
      .required("Gender Is Required")
      .test("not blank", "Select value", (value) => {
        return value !== "";
      }),

    currentStepIndex: Yup.string().required("Field is required"),

    //second page
    ownerPrefix: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () =>
        Yup.string()
          .required(" Prefix Is Required")
          .test("not blank", "Select value", (value) => {
            return value !== "";
          }),
    }),
    ownerFirstName: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () =>
        Yup.string()
          .matches(/^[a-zA-Z ]*$/, "Invalid First Name")
          .required(" First Name Is Required"),
    }),

    ownerMiddleName: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () => Yup.string().matches(/^[a-zA-Z ]*$/, "Invalid Middle Name"),
    }),

    ownerLastName: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () =>
        Yup.string()
          .matches(/^[a-zA-Z ]*$/, "Invalid Last Name")
          .required(" Last Name Is Required"),
    }),

    // ownerEmail: Yup.string().when("currentStepIndex", {
    //   is: '1',
    //   then: () => Yup.string()
    //     .required('Email is required')
    //     .email('Invalid email format')
    //     .matches(/@.*\./, 'Email must contain "@" and "."'),
    // }),

    ownerEmail: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () =>
        Yup.string()
          .required("Email is required")
          .email("Invalid email format")
          .matches(
            /^(?!\.)(?!.*\.\.)(?!.*\.$)[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
            "Invalid email format"
          ),
    }),

    ownerPhoneNumber: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () =>
        Yup.string()
          .matches(/^\d{10}$/, "Phone Number must be 10 digits")
          .required(" Phone Number Is Required"),
    }),

    ownerMaritialStus: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () =>
        Yup.string()
          .required(" Maritial Status Is Required")
          .test("not blank", "Select value", (value) => {
            return value !== "";
          }),
    }),

    // third page
    nomineFirstName: Yup.string().when("currentStepIndex", {
      is: "2",
      then: () =>
        Yup.string()
          .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed")
          .required(" First Name Is Required"),
    }),

    nomineLastName: Yup.string().when("currentStepIndex", {
      is: "2",
      then: () =>
        Yup.string()
          .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed")
          .required(" Last Name Is Required"),
    }),

    nomineDob: Yup.string().when(
      ["currentStepIndex", "nomineRelation", "kycNomineDob"],
      {
        is: (currentStepIndex) => currentStepIndex === "2",
        then: () =>
          Yup.string()
            .required("DOB Is Required")
            .test("not blank", "Select value", (value) => value !== "")
            .test("valid date", "Future date is not allowed", (value) => {
              const currentDate = new Date();
              const inputDate = new Date(value);
              return inputDate <= currentDate;
            })
            .test(
              "valid range",
              "Date should be within the past 100 years",
              (value) => {
                return yearValidation(value, 100);
              }
            )
            .test("date comparison", "Invalid Date", function (value) {
              const userDob = new Date(this.parent.kycNomineDob);
              const nomineeDob = new Date(value);

              if (
                this.parent.nomineRelation === "son" ||
                this.parent.nomineRelation === "daughter"
              ) {
                return (
                  nomineeDob > userDob ||
                  this.createError({
                    message: "Nominee DOB should be after user DOB",
                  })
                );
              } else if (
                this.parent.nomineRelation === "mother" ||
                this.parent.nomineRelation === "father"
              ) {
                return (
                  nomineeDob < userDob ||
                  this.createError({
                    message: "Nominee DOB should be before user DOB",
                  })
                );
              }

              return true;
            }),
        else: Yup.string(),
      }
    ),

    nomineRelation: Yup.string().when("currentStepIndex", {
      is: "2",
      then: () =>
        Yup.string()
          .required(" Relation Of Nomine Is Required")
          .test("not blank", "Select value", (value) => {
            return value !== "";
          }),
    }),

    // 4th page

    ownerFlatNumber: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () =>
        Yup.string()
          .matches(/^[0-9]+$/, "Flat Number must be a number")
          .required(" Flat Number Is Required"),
    }),

    ownerAddress: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required("Address is required"),
      // .max(120, "Address cannot be longer than 120 characters"), // max length validation
    }),

    ownerLocation: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required(" Location Is Required"),
    }),
    ownerPincode: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () =>
        Yup.string()
          .matches(/^\d{6}$/, "Invalid PIN code")
          .required(" Pincode Is Required"),
    }),

    ownerCity: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required(" City Is Required"),
    }),

    ownerState: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required(" State Is Required"),
    }),
    isRegisteredAddressSame: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required("Field is required"),
    }),

    ownerFlatNumberReg: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () =>
        Yup.string()
          .matches(/^[0-9]+$/, "Flat Number must be a number")
          .required("  Reg Flat Number Is Required"),
    }),
    ownerAddressReg: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required(" Reg Address Is Required"),
    }),
    ownerLocationReg: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required(" Reg Location Is Required"),
    }),
    ownerPincodeReg: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () =>
        Yup.string()
          .matches(/^\d{6}$/, "Invalid PIN code")
          .required(" Reg Pincode Is Required"),
    }),
    ownerCityReg: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required(" Reg City Is Required"),
    }),
    ownerStateReg: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required("Reg State Is Required"),
    }),
    // page 6

    vehicalMakeModel: Yup.string().when("currentStepIndex", {
      is: "4",
      then: () => Yup.string().required(" Make and Model Is Required"),
    }),

    vehicalRegistionDate: Yup.string().when("currentStepIndex", {
      is: "4",
      then: () =>
        Yup.string()
          .required("Registration Date Is Required")
          .test("not blank", "Select value", (value) => value !== "")
          .test("valid date", "Future date is not allowed", (value) => {
            const currentDate = new Date();
            const inputDate = new Date(value);
            return inputDate <= currentDate;
          })
          .test(
            "valid range",
            "Date should be within the past 50 years",
            (value) => {
              return yearValidation(value, 50);
            }
          ),
    }),

    vehicalManufaDate: Yup.string().when("currentStepIndex", {
      is: "4",
      then: () => Yup.string().required(" Manufacturing Year Is Required"),
    }),

    vehicalPurchaseLoan: Yup.string().when("currentStepIndex", {
      is: "4",
      then: () =>
        Yup.string()
          .required("Bike Purchased on Loan Is Required")
          .test("not blank", "Select value", (value) => {
            return value !== "";
          }),
    }),

    vehicalFinancierName: Yup.string().when(
      ["currentStepIndex", "vehicalPurchaseLoan"],
      {
        is: (currentStepIndex, vehicalPurchaseLoan) =>
          currentStepIndex === "4" && vehicalPurchaseLoan === "yes",
        then: () =>
          Yup.string()
            .required("Vehicle Financier Name is Required")
            .min(4, "Minimum length must be 4 characters"),
      }
    ),
    financierNameValidaton: Yup.string().when("currentStepIndex", {
      is: "4",
      then: () =>
        Yup.string().required("Vehicle Registration Number Is Required"),
    }),

    financierID: Yup.string().when(
      ["currentStepIndex", "vehicalPurchaseLoan", "financierNameValidaton"],
      {
        is: (currentStepIndex, vehicalPurchaseLoan, financierNameValidaton) =>
          currentStepIndex === "4" &&
          vehicalPurchaseLoan === "yes" &&
          financierNameValidaton === "yes",
        then: () =>
          Yup.string().required(
            "Name not found,please pick name from dropdown only"
          ),
      }
    ),

    engineNumber: Yup.string().when("currentStepIndex", {
      is: "5",
      then: () =>
        Yup.string()
          .matches(
            /^[a-zA-Z0-9/ ]*$/,
            "Only letters, numbers, and spaces are allowed"
          )
          .max(17, "Maximum length must be 17 characters")
          .min(5, "Minimum length must be 5 characters")
          .required("Engine Number Is Required"),
    }),

    chassisNumber: Yup.string().when("currentStepIndex", {
      is: "4",
      then: () =>
        Yup.string()
          .matches(/^[a-zA-Z0-9/]*$/, "Only letters and numbers are allowed")
          .min(17, "Minimum length must be 17 characters")
          .max(25, "Maximum length must be 25 characters")
          .required("Chassis Number Number Is Required"),
    }),

    // registeredRto: Yup.string().when("currentStepIndex", {
    //   is: "4",
    //   then: () =>
    //     Yup.string()
    //       .required("Vehicle Registration Number Is Required")
    //       .matches(
    //        /^(?:[A-Z]{2}\d{2}[A-Z]{1,2}\d{4}|[A-Z]{2}\d{2} ?[A-Z]{1,2} ?\d{4}|CD \d{2} \d{3}|[A-Z]{2} \d{5}|[A-Z]{2}\d[A-Z]{1,2} ?[A-Z]{1,3} ?\d{4}|[0-9]{2}BH[0-9]{4})$/,
    //     "Invalid Vehicle Number, e.g., MH12AB1234, DL1SBF1234, or 22BH0000"
    //       ),
    // }),
    registeredRto: Yup.string().when("currentStepIndex", {
      is: "4",
      then: () =>
        Yup.string().required("Vehicle Registration Number Is Required"),
    }),
    vehicalTermAccept: Yup.boolean().when("currentStepIndex", {
      is: "4",
      then: () =>
        Yup.boolean()
          .oneOf([true], "You must accept the terms and conditions")
          .required("You must accept the terms and conditions"),
    }),
  });

  const validationSchema3 = Yup.object().shape({
    // First page
    kycNominePanNumber: Yup.string()
      .trim()
      .matches(/^[A-Z]{5}[0-9]{4}[A-Z]$/, "Invalid PAN Number")
      .required("PAN Number Is Required"),

    kycNomineDob: Yup.string()
      .trim()
      .required("DOB Is Required")
      .test("not blank", "Select value", (value) => value !== "")
      .test("valid date", "Future date is not allowed", (value) => {
        const currentDate = new Date();
        const inputDate = new Date(value);
        return inputDate <= currentDate;
      })
      .test(
        "valid range",
        "Date should be within the past 100 years",
        (value) => {
          return yearValidation(value, 100);
        }
      ),

    kycNomineGender: Yup.string()
      .trim()
      .required("Gender Is Required")
      .test("not blank", "Select value", (value) => {
        return value !== "";
      }),

    currentStepIndex: Yup.string().required("Field is required"),

    //second page
    ownerPrefix: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () =>
        Yup.string()
          .required(" Prefix Is Required")
          .test("not blank", "Select value", (value) => {
            return value !== "";
          }),
    }),
    ownerFirstName: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () =>
        Yup.string()
          .matches(/^[a-zA-Z ]*$/, "Invalid First Name")
          .required(" First Name Is Required"),
    }),

    ownerMiddleName: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () => Yup.string().matches(/^[a-zA-Z ]*$/, "Invalid Middle Name"),
      //  .required(" Middle Name Is Required"),
    }),

    ownerLastName: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () =>
        Yup.string()
          .matches(/^[a-zA-Z ]*$/, "Invalid Last Name")
          .required(" Last Name Is Required"),
    }),
    ownerEmail: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () =>
        Yup.string()
          .required("Email is required")
          .email("Invalid email format")
          .matches(
            /^(?!\.)(?!.*\.\.)(?!.*\.$)[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
            "Invalid email format"
          ),
    }),

    ownerPhoneNumber: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () =>
        Yup.string()
          .matches(/^\d{10}$/, "Phone Number must be 10 digits")
          .required(" Phone Number Is Required"),
    }),

    ownerMaritialStus: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () =>
        Yup.string()
          .required(" Maritial Status Is Required")
          .test("not blank", "Select value", (value) => {
            return value !== "";
          }),
    }),

    // third page
    nomineFirstName: Yup.string().when("currentStepIndex", {
      is: "2",
      then: () =>
        Yup.string()
          .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed")
          .required(" First Name Is Required"),
    }),

    nomineLastName: Yup.string().when("currentStepIndex", {
      is: "2",
      then: () =>
        Yup.string()
          .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed")
          .required(" Last Name Is Required"),
    }),

    nomineDob: Yup.string().when(
      ["currentStepIndex", "nomineRelation", "kycNomineDob"],
      {
        is: (currentStepIndex) => currentStepIndex === "2",
        then: () =>
          Yup.string()
            .required("DOB Is Required")
            .test("not blank", "Select value", (value) => value !== "")
            .test("valid date", "Future date is not allowed", (value) => {
              const currentDate = new Date();
              const inputDate = new Date(value);
              return inputDate <= currentDate;
            })
            .test(
              "valid range",
              "Date should be within the past 100 years",
              (value) => {
                return yearValidation(value, 100);
              }
            )
            .test("date comparison", "Invalid Date", function (value) {
              const userDob = new Date(this.parent.kycNomineDob);
              const nomineeDob = new Date(value);

              if (
                this.parent.nomineRelation === "son" ||
                this.parent.nomineRelation === "daughter"
              ) {
                return (
                  nomineeDob > userDob ||
                  this.createError({
                    message: "Nominee DOB should be after user DOB",
                  })
                );
              } else if (
                this.parent.nomineRelation === "mother" ||
                this.parent.nomineRelation === "father"
              ) {
                return (
                  nomineeDob < userDob ||
                  this.createError({
                    message: "Nominee DOB should be before user DOB",
                  })
                );
              }

              return true;
            }),
        else: Yup.string(),
      }
    ),

    nomineRelation: Yup.string().when("currentStepIndex", {
      is: "2",
      then: () =>
        Yup.string()
          .required(" Relation Of Nomine Is Required")
          .test("not blank", "Select value", (value) => {
            return value !== "";
          }),
    }),

    // 4th page

    ownerFlatNumber: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () =>
        Yup.string()
          .matches(/^[0-9]+$/, "Flat Number must be a number")
          .required(" Flat Number Is Required"),
    }),

    ownerAddress: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required("Address is required"),
      // .max(120, "Address cannot be longer than 120 characters"), // max length validation
    }),

    ownerLocation: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required(" Location Is Required"),
    }),
    ownerPincode: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () =>
        Yup.string()
          .matches(/^\d{6}$/, "Invalid PIN code")
          .required(" Pincode Is Required"),
    }),

    ownerCity: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required(" City Is Required"),
    }),

    ownerState: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required(" State Is Required"),
    }),
    isRegisteredAddressSame: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required("Field is required"),
    }),

    ownerFlatNumberReg: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () =>
        Yup.string()
          .matches(/^[0-9]+$/, "Flat Number must be a number")
          .required("  Reg Flat Number Is Required"),
    }),

    ownerAddressReg: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required(" Reg Address Is Required"),
    }),

    ownerLocationReg: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required(" Reg Location Is Required"),
    }),
    ownerPincodeReg: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () =>
        Yup.string()
          .matches(/^\d{6}$/, "Invalid PIN code")
          .required(" Reg Pincode Is Required"),
    }),

    ownerCityReg: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required(" Reg City Is Required"),
    }),

    ownerStateReg: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required("Reg State Is Required"),
    }),

    //page 5
    policyNumber: Yup.string().when("currentStepIndex", {
      is: "4",
      then: () =>
        Yup.string()
          .matches(
            /^[a-zA-Z0-9\/]*$/,
            "Only letters, numbers, and '/' are allowed"
          )
          .max(50, "Maximum length must be 50 characters")
          .min(4, "Minimum length must be 4 characters")
          .required("Policy Number Is Required"),
    }),

    policyInsuranceNamePrevious: Yup.string().when("currentStepIndex", {
      is: "4",
      then: () => Yup.string().required("Previous Policy Name Is Required"),
    }),

    PolicyEndtDate: Yup.string().when("currentStepIndex", {
      is: "4",
      then: () =>
        Yup.string()
          .required("Policy End Date Is Required")
          .test(
            "valid date",
            "Policy End Date should be within the past 50 years",
            (value) => {
              const currentDate = new Date();
              const selectedDate = new Date(value);
              const yearDifference =
                currentDate.getFullYear() - selectedDate.getFullYear();
              return yearDifference <= 50;
            }
          ),
    }),

    policyInsuranceName: Yup.string().when("currentStepIndex", {
      is: "4",
      then: () =>
        Yup.string()
          .required(" Policy Insurance Name Is Required")
          .test("not blank", "Select value", (value) => {
            return value !== "";
          }),
    }),

    tpPolicyInsuranceName: Yup.string().when(
      ["currentStepIndex", "policyType"],
      {
        is: (currentStepIndex, policyType) =>
          currentStepIndex === "4" && policyType === "OD",
        then: () =>
          Yup.string()
            .required("TP Policy Insurer Name Is Required")
            .test("not blank", "Select value", (value) => {
              return value !== "";
            }),
      }
    ),

    tpPolicyNumber: Yup.string().when(["currentStepIndex", "policyType"], {
      is: (currentStepIndex, policyType) =>
        currentStepIndex === "4" && policyType === "OD",
      then: () =>
        Yup.string()
          .matches(
            /^[a-zA-Z0-9\/-]*$/,
            "Only letters, numbers, '/', and '-' are allowed"
          )
          .max(30, "Maximum length must be 30 characters")
          .min(4, "Minimum length must be 4 characters")
          .required("TP policy Number Is Required"),
    }),

    policyPreviousType: Yup.string().when("currentStepIndex", {
      is: "4",
      then: () =>
        Yup.string()
          .required("Previous policy Type Is Required")
          .test("not blank", "Select value", (value) => {
            return value !== "";
          }),
    }),

    // page 6

    vehicalMakeModel: Yup.string().when("currentStepIndex", {
      is: "5",
      then: () => Yup.string().required(" Make and Model Is Required"),
    }),

    vehicalRegistionDate: Yup.string().when("currentStepIndex", {
      is: "5",
      then: () =>
        Yup.string()
          .required("Registration Date Is Required")
          .test("not blank", "Select value", (value) => value !== "")
          .test("valid date", "Future date is not allowed", (value) => {
            const currentDate = new Date();
            const inputDate = new Date(value);
            return inputDate <= currentDate;
          })
          .test(
            "valid range",
            "Date should be within the past 50 years",
            (value) => {
              return yearValidation(value, 50);
            }
          ),
    }),

    vehicalManufaDate: Yup.string().when("currentStepIndex", {
      is: "5",
      then: () => Yup.string().required(" Manufacturing Year Is Required"),
    }),

    vehicalPurchaseLoan: Yup.string().when("currentStepIndex", {
      is: "5",
      then: () =>
        Yup.string()
          .required("Bike Purchased on Loan Is Required")
          .test("not blank", "Select value", (value) => {
            return value !== "";
          }),
    }),

    vehicalFinancierName: Yup.string().when(
      ["currentStepIndex", "vehicalPurchaseLoan"],
      {
        is: (currentStepIndex, vehicalPurchaseLoan) =>
          currentStepIndex === "5" && vehicalPurchaseLoan === "yes",
        then: () =>
          Yup.string()
            .required("Vehicle Financier Name is Required")
            .min(4, "Minimum length must be 4 characters"),
      }
    ),

    financierNameValidaton: Yup.string().when("currentStepIndex", {
      is: "5",
      then: () =>
        Yup.string().required("Vehicle Registration Number Is Required"),
    }),

    financierID: Yup.string().when(
      ["currentStepIndex", "vehicalPurchaseLoan", "financierNameValidaton"],
      {
        is: (currentStepIndex, vehicalPurchaseLoan, financierNameValidaton) =>
          currentStepIndex === "5" &&
          vehicalPurchaseLoan === "yes" &&
          financierNameValidaton === "yes",
        then: () =>
          Yup.string().required(
            "Name not found,please pick name from dropdown only"
          ),
      }
    ),
    engineNumber: Yup.string().when("currentStepIndex", {
      is: "5",
      then: () =>
        Yup.string()
          .matches(/^[a-zA-Z0-9/]*$/, "Only letters and numbers are allowed")
          // .max(20, "Maximum length must be 20 characters")
          .min(5, "Minimum length must be 5 characters")
          .required("Engine Number Is Required"),
    }),

    chassisNumber: Yup.string().when("currentStepIndex", {
      is: "5",
      then: () =>
        Yup.string()
          .matches(/^[a-zA-Z0-9/]*$/, "Only letters and numbers are allowed")
          .min(17, "Minimum length must be 17 characters")
          .max(25, "Maximum length must be 25 characters")
          .required("Chassis Number Is Required"),
    }),

    registeredRto: Yup.string().when("currentStepIndex", {
      is: "5",
      then: () =>
        Yup.string()
          .required("Vehicle Registration Number Is Required")
          .matches(
            /^(?:[A-Z]{2}\d{2}[A-Z]{1,2}\d{4}|[A-Z]{3}\d{4}|[A-Z]{2}\d{2} ?[A-Z]{1,2} ?\d{4}|CD \d{2} \d{3}|[A-Z]{2} \d{5}|[A-Z]{2}\d[A-Z]{1,2} ?[A-Z]{1,3} ?\d{4}|[0-9]{2}BH[0-9]{4})$/,
            "Invalid Vehicle Number, e.g., MH12AB1234, MAY2731, or 22BH0000"
          ),
    }),

    // registeredRto: Yup.string().when("currentStepIndex", {
    //   is: "5",
    //   then: () =>
    //     Yup.string()
    //       .required("Vehicle Registration Number Is Required")
    //       .matches(
    //         /^[A-Z]{2}\d{2}[A-Z]{1,2}\d{4}$/,
    //         "Invalid Vehicle Number,Eg MH12AAXXXX"
    //       ),
    // }),

    vehicalTermAccept: Yup.boolean().when("currentStepIndex", {
      is: "5",
      then: () =>
        Yup.boolean()
          .oneOf([true], "You must accept the terms and conditions")
          .required("You must accept the terms and conditions"),
    }),
  });

  const validationSchema4 = Yup.object().shape({
    // First page
    kycNominePanNumber: Yup.string()
      .trim()
      .matches(/^[A-Z]{5}[0-9]{4}[A-Z]$/, "Invalid PAN Number")
      .required("PAN Number Is Required"),

    kycNomineDob: Yup.string()
      .trim()
      .required("DOB Is Required")
      .test("not blank", "Select value", (value) => value !== "")
      .test("valid date", "Future date is not allowed", (value) => {
        const currentDate = new Date();
        const inputDate = new Date(value);
        return inputDate <= currentDate;
      })
      .test(
        "valid range",
        "Date should be within the past 100 years",
        (value) => {
          return yearValidation(value, 100);
        }
      ),

    kycNomineGender: Yup.string()
      .trim()
      .required("Gender Is Required")
      .test("not blank", "Select value", (value) => {
        return value !== "";
      }),

    currentStepIndex: Yup.string().required("Field is required"),

    //second page
    ownerPrefix: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () =>
        Yup.string()
          .required(" Prefix Is Required")
          .test("not blank", "Select value", (value) => {
            return value !== "";
          }),
    }),
    ownerFirstName: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () =>
        Yup.string()
          .matches(/^[a-zA-Z ]*$/, "Invalid First Name")
          .required(" First Name Is Required"),
    }),

    ownerMiddleName: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () => Yup.string().matches(/^[a-zA-Z ]*$/, "Invalid Middle Name"),
      //    .required(" Middle Name Is Required"),
    }),

    ownerLastName: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () =>
        Yup.string()
          .matches(/^[a-zA-Z ]*$/, "Invalid Last Name")
          .required(" Last Name Is Required"),
    }),

    ownerEmail: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () =>
        Yup.string()
          .required("Email is required")
          .email("Invalid email format")
          .matches(
            /^(?!\.)(?!.*\.\.)(?!.*\.$)[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
            "Invalid email format"
          ),
    }),

    ownerPhoneNumber: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () =>
        Yup.string()
          .matches(/^\d{10}$/, "Phone Number must be 10 digits")
          .required(" Phone Number Is Required"),
    }),

    ownerMaritialStus: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () =>
        Yup.string()
          .required(" Maritial Status Is Required")
          .test("not blank", "Select value", (value) => {
            return value !== "";
          }),
    }),

    // third page
    nomineFirstName: Yup.string().when("currentStepIndex", {
      is: "2",
      then: () =>
        Yup.string()
          .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed")
          .required(" First Name Is Required"),
    }),

    nomineLastName: Yup.string().when("currentStepIndex", {
      is: "2",
      then: () =>
        Yup.string()
          .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed")
          .required(" Last Name Is Required"),
    }),

    nomineDob: Yup.string().when(
      ["currentStepIndex", "nomineRelation", "kycNomineDob"],
      {
        is: (currentStepIndex) => currentStepIndex === "2",
        then: () =>
          Yup.string()
            .required("DOB Is Required")
            .test("not blank", "Select value", (value) => value !== "")
            .test("valid date", "Future date is not allowed", (value) => {
              const currentDate = new Date();
              const inputDate = new Date(value);
              return inputDate <= currentDate;
            })
            .test(
              "valid range",
              "Date should be within the past 100 years",
              (value) => {
                return yearValidation(value, 100);
              }
            )
            .test("date comparison", "Invalid Date", function (value) {
              const userDob = new Date(this.parent.kycNomineDob);
              const nomineeDob = new Date(value);

              if (
                this.parent.nomineRelation === "son" ||
                this.parent.nomineRelation === "daughter"
              ) {
                return (
                  nomineeDob > userDob ||
                  this.createError({
                    message: "Nominee DOB should be after user DOB",
                  })
                );
              } else if (
                this.parent.nomineRelation === "mother" ||
                this.parent.nomineRelation === "father"
              ) {
                return (
                  nomineeDob < userDob ||
                  this.createError({
                    message: "Nominee DOB should be before user DOB",
                  })
                );
              }

              return true;
            }),
        else: Yup.string(),
      }
    ),

    nomineRelation: Yup.string().when("currentStepIndex", {
      is: "2",
      then: () =>
        Yup.string()
          .required(" Relation Of Nomine Is Required")
          .test("not blank", "Select value", (value) => {
            return value !== "";
          }),
    }),

    // 4th page

    ownerFlatNumber: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () =>
        Yup.string()
          .matches(/^[0-9]+$/, "Flat Number must be a number")
          .required(" Flat Number Is Required"),
    }),

    ownerAddress: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required("Address is required"),
      // .max(120, "Address cannot be longer than 120 characters"), // max length validation
    }),

    ownerLocation: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required(" Location Is Required"),
    }),
    ownerPincode: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () =>
        Yup.string()
          .matches(/^\d{6}$/, "Invalid PIN code")
          .required(" Pincode Is Required"),
    }),

    ownerCity: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required(" City Is Required"),
    }),

    ownerState: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required(" State Is Required"),
    }),
    isRegisteredAddressSame: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required("Field is required"),
    }),

    ownerFlatNumberReg: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () =>
        Yup.string()
          .matches(/^[0-9]+$/, "Flat Number must be a number")
          .required("  Reg Flat Number Is Required"),
    }),
    ownerAddressReg: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required(" Reg Address Is Required"),
    }),
    ownerLocationReg: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required(" Reg Location Is Required"),
    }),
    ownerPincodeReg: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () =>
        Yup.string()
          .matches(/^\d{6}$/, "Invalid PIN code")
          .required(" Reg Pincode Is Required"),
    }),
    ownerCityReg: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required(" Reg City Is Required"),
    }),
    ownerStateReg: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required("Reg State Is Required"),
    }),
    // page 6

    vehicalMakeModel: Yup.string().when("currentStepIndex", {
      is: "4",
      then: () => Yup.string().required(" Make and Model Is Required"),
    }),

    vehicalRegistionDate: Yup.string().when("currentStepIndex", {
      is: "4",
      then: () =>
        Yup.string()
          .required("Registration Date Is Required")
          .test("not blank", "Select value", (value) => value !== "")
          .test("valid date", "Future date is not allowed", (value) => {
            const currentDate = new Date();
            const inputDate = new Date(value);
            return inputDate <= currentDate;
          })
          .test(
            "valid range",
            "Date should be within the past 50 years",
            (value) => {
              return yearValidation(value, 50);
            }
          ),
    }),

    vehicalManufaDate: Yup.string().when("currentStepIndex", {
      is: "4",
      then: () => Yup.string().required(" Manufacturing Year Is Required"),
    }),

    vehicalPurchaseLoan: Yup.string().when("currentStepIndex", {
      is: "4",
      then: () =>
        Yup.string()
          .required("Bike Purchased on Loan Is Required")
          .test("not blank", "Select value", (value) => {
            return value !== "";
          }),
    }),

    vehicalFinancierName: Yup.string().when(
      ["currentStepIndex", "vehicalPurchaseLoan"],
      {
        is: (currentStepIndex, vehicalPurchaseLoan) =>
          currentStepIndex === "4" && vehicalPurchaseLoan === "yes",
        then: () =>
          Yup.string()
            .required("Vehicle Financier Name is Required")
            .min(4, "Minimum length must be 4 characters"),
      }
    ),
    financierNameValidaton: Yup.string().when("currentStepIndex", {
      is: "4",
      then: () =>
        Yup.string().required("Vehicle Registration Number Is Required"),
    }),

    financierID: Yup.string().when(
      ["currentStepIndex", "vehicalPurchaseLoan", "financierNameValidaton"],
      {
        is: (currentStepIndex, vehicalPurchaseLoan, financierNameValidaton) =>
          currentStepIndex === "4" &&
          vehicalPurchaseLoan === "yes" &&
          financierNameValidaton === "yes",
        then: () =>
          Yup.string().required(
            "Name not found,please pick name from dropdown only"
          ),
      }
    ),

    engineNumber: Yup.string().when("currentStepIndex", {
      is: "4",
      then: () =>
        Yup.string()
          .matches(/^[a-zA-Z0-9/]*$/, "Only letters and numbers are allowed")
          // .max(17, "Maximum length must be 17 characters")
          .min(5, "Minimum length must be 5 characters")
          .required("EngineNumber Number Is Required"),
    }),

    chassisNumber: Yup.string().when("currentStepIndex", {
      is: "4",
      then: () =>
        Yup.string()
          .matches(/^[a-zA-Z0-9/]*$/, "Only letters and numbers are allowed")
          // .max(17, "Maximum length must be 17 characters")
          .min(17, "Minimum length must be 17 characters")
          .required("Chassis Number Number Is Required"),
    }),

    // registeredRto: Yup.string().when("currentStepIndex", {
    //   is: "4",
    //   then: () =>
    //     Yup.string()
    //       .required("Vehicle Registration Number Is Required")
    //       .matches(
    //         /^(?:[A-Z]{2}\d{2}[A-Z]{1,2}\d{4}|[A-Z]{2}\d{2} ?[A-Z]{1,2} ?\d{4}|CD \d{2} \d{3}|[A-Z]{2} \d{5}|[A-Z]{2}\d[A-Z]{1,2} ?[A-Z]{1,3} ?\d{4}|[0-9]{2}BH[0-9]{4})$/,
    //     "Invalid Vehicle Number, e.g., MH12AB1234, DL1SBF1234, or 22BH0000"
    //       ),
    // }),

    registeredRto: Yup.string().when("currentStepIndex", {
      is: "4",
      then: () =>
        Yup.string().required("Vehicle Registration Number Is Required"),
    }),
    vehicalTermAccept: Yup.boolean().when("currentStepIndex", {
      is: "4",
      then: () =>
        Yup.boolean()
          .oneOf([true], "You must accept the terms and conditions")
          .required("You must accept the terms and conditions"),
    }),
  });

  const validationSchema5 = Yup.object().shape({
    // First page
    kycNominePanNumber: Yup.string()
      .trim()
      .matches(/^[A-Z]{5}[0-9]{4}[A-Z]$/, "Invalid PAN Number")
      .required("PAN Number Is Required"),

    kycNomineDob: Yup.string()
      .trim()
      .required("DOB Is Required")
      .test("not blank", "Select value", (value) => value !== "")
      .test("valid date", "Future date is not allowed", (value) => {
        const currentDate = new Date();
        const inputDate = new Date(value);
        return inputDate <= currentDate;
      })
      .test(
        "valid range",
        "Date should be within the past 100 years",
        (value) => {
          return yearValidation(value, 100);
        }
      ),

    kycNomineGender: Yup.string()
      .trim()
      .required("Gender Is Required")
      .test("not blank", "Select value", (value) => {
        return value !== "";
      }),

    currentStepIndex: Yup.string().required("Field is required"),

    //second page
    ownerPrefix: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () =>
        Yup.string()
          .required(" Prefix Is Required")
          .test("not blank", "Select value", (value) => {
            return value !== "";
          }),
    }),
    ownerFirstName: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () =>
        Yup.string()
          .matches(/^[a-zA-Z ]*$/, "Invalid First Name")
          .required(" First Name Is Required"),
    }),

    ownerMiddleName: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () => Yup.string().matches(/^[a-zA-Z ]*$/, "Invalid Middle Name"),
    }),

    ownerLastName: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () =>
        Yup.string()
          .matches(/^[a-zA-Z ]*$/, "Invalid Last Name")
          .required(" Last Name Is Required"),
    }),

    ownerEmail: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () =>
        Yup.string()
          .required("Email is required")
          .test("isValidEmail", "Invalid email format", (value) => {
            return value?.includes("@") && value?.includes(".");
          }),
    }),
  
    ownerPhoneNumber: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () =>
        Yup.string()
          .required("Phone Number is required")
          .matches(/^[a-zA-Z0-9]{10}$/, "Phone Number must be exactly 10 alphanumeric characters"),
    }),

    ownerMaritialStus: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () =>
        Yup.string()
          .required(" Maritial Status Is Required")
          .test("not blank", "Select value", (value) => {
            return value !== "";
          }),
    }),

    // third page
    nomineFirstName: Yup.string().when("currentStepIndex", {
      is: "2",
      then: () =>
        Yup.string()
          .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed")
          .required(" First Name Is Required"),
    }),

    nomineLastName: Yup.string().when("currentStepIndex", {
      is: "2",
      then: () =>
        Yup.string()
          .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed")
          .required(" Last Name Is Required"),
    }),

    nomineDob: Yup.string().when(
      ["currentStepIndex", "nomineRelation", "kycNomineDob"],
      {
        is: (currentStepIndex) => currentStepIndex === "2",
        then: () =>
          Yup.string()
            .required("DOB Is Required")
            .test("not blank", "Select value", (value) => value !== "")
            .test("valid date", "Future date is not allowed", (value) => {
              const currentDate = new Date();
              const inputDate = new Date(value);
              return inputDate <= currentDate;
            })
            .test(
              "valid range",
              "Date should be within the past 100 years",
              (value) => {
                return yearValidation(value, 100);
              }
            )
            .test("date comparison", "Invalid Date", function (value) {
              const userDob = new Date(this.parent.kycNomineDob);
              const nomineeDob = new Date(value);

              if (
                this.parent.nomineRelation === "son" ||
                this.parent.nomineRelation === "daughter"
              ) {
                return (
                  nomineeDob > userDob ||
                  this.createError({
                    message: "Nominee DOB should be after user DOB",
                  })
                );
              } else if (
                this.parent.nomineRelation === "mother" ||
                this.parent.nomineRelation === "father"
              ) {
                return (
                  nomineeDob < userDob ||
                  this.createError({
                    message: "Nominee DOB should be before user DOB",
                  })
                );
              }

              return true;
            }),
        else: Yup.string(),
      }
    ),

    nomineRelation: Yup.string().when("currentStepIndex", {
      is: "2",
      then: () =>
        Yup.string()
          .required(" Relation Of Nomine Is Required")
          .test("not blank", "Select value", (value) => {
            return value !== "";
          }),
    }),

    // 4th page

    ownerFlatNumber: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () =>
        Yup.string()
          .matches(/^[0-9]+$/, "Flat Number must be a number")
          .required(" Flat Number Is Required"),
    }),

    ownerAddress: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required("Address is required"),
      // .max(120, "Address cannot be longer than 120 characters"), // max length validation
    }),

    ownerLocation: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required(" Location Is Required"),
    }),
    ownerPincode: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () =>
        Yup.string()
          .matches(/^\d{6}$/, "Invalid PIN code")
          .required(" Pincode Is Required"),
    }),

    ownerCity: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required(" City Is Required"),
    }),

    ownerState: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required(" State Is Required"),
    }),
    isRegisteredAddressSame: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required("Field is required"),
    }),

    ownerFlatNumberReg: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () =>
        Yup.string()
          .matches(/^[0-9]+$/, "Flat Number must be a number")
          .required("  Reg Flat Number Is Required"),
    }),

    ownerAddressReg: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required(" Reg Address Is Required"),
    }),

    ownerLocationReg: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required(" Reg Location Is Required"),
    }),
    ownerPincodeReg: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () =>
        Yup.string()
          .matches(/^\d{6}$/, "Invalid PIN code")
          .required(" Reg Pincode Is Required"),
    }),

    ownerCityReg: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required(" Reg City Is Required"),
    }),

    ownerStateReg: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required("Reg State Is Required"),
    }),

    //page 5
    policyNumber: Yup.string().when("currentStepIndex", {
      is: "4",
      then: () =>
        Yup.string()
          .matches(
            /^[a-zA-Z0-9\/-]*$/,
            "Only letters, numbers, and '/' are allowed"
          )
          .max(50, "Maximum length must be 50 characters")
          .min(4, "Minimum length must be 4 characters")
          .required("Policy Number Is Required"),
    }),

    policyInsuranceNamePrevious: Yup.string().when("currentStepIndex", {
      is: "4",
      then: () => Yup.string().required("Previous Policy Name Is Required"),
    }),

    PolicyEndtDate: Yup.string().when("currentStepIndex", {
      is: "4",
      then: () =>
        Yup.string()
          .required("Policy End Date Is Required")
          .test(
            "valid date",
            "Policy End Date should be within the past 50 years",
            (value) => {
              const currentDate = new Date();
              const selectedDate = new Date(value);
              const yearDifference =
                currentDate.getFullYear() - selectedDate.getFullYear();
              return yearDifference <= 50;
            }
          ),
    }),

    policyInsuranceName: Yup.string().when("currentStepIndex", {
      is: "4",
      then: () =>
        Yup.string()
          .required(" Policy Insurance Name Is Required")
          .test("not blank", "Select value", (value) => {
            return value !== "";
          }),
    }),

    tpPolicyInsuranceName: Yup.string().when(
      ["currentStepIndex", "policyType"],
      {
        is: (currentStepIndex, policyType) =>
          currentStepIndex === "4" && policyType === "OD",
        then: () =>
          Yup.string()
            .required("TP Policy Insurer Name Is Required")
            .test("not blank", "Select value", (value) => {
              return value !== "";
            }),
      }
    ),

    tpPolicyNumber: Yup.string().when(["currentStepIndex", "policyType"], {
      is: (currentStepIndex, policyType) =>
        currentStepIndex === "4" && policyType === "OD",
      then: () =>
        Yup.string()
          .matches(
            /^[a-zA-Z0-9\/-]*$/,
            "Only letters, numbers, '/', and '-' are allowed"
          )
          .max(30, "Maximum length must be 30 characters")
          .min(4, "Minimum length must be 4 characters")
          .required("TP policy Number Is Required"),
    }),

    policyPreviousType: Yup.string().when("currentStepIndex", {
      is: "4",
      then: () =>
        Yup.string()
          .required("Previous policy Type Is Required")
          .test("not blank", "Select value", (value) => {
            return value !== "";
          }),
    }),

    // page 6

    vehicalMakeModel: Yup.string().when("currentStepIndex", {
      is: "5",
      then: () => Yup.string().required(" Make and Model Is Required"),
    }),

    vehicalRegistionDate: Yup.string().when("currentStepIndex", {
      is: "5",
      then: () =>
        Yup.string()
          .required("Registration Date Is Required")
          .test("not blank", "Select value", (value) => value !== "")
          .test("valid date", "Future date is not allowed", (value) => {
            const currentDate = new Date();
            const inputDate = new Date(value);
            return inputDate <= currentDate;
          })
          .test(
            "valid range",
            "Date should be within the past 50 years",
            (value) => {
              return yearValidation(value, 50);
            }
          ),
    }),

    vehicalManufaDate: Yup.string().when("currentStepIndex", {
      is: "5",
      then: () => Yup.string().required(" Manufacturing Year Is Required"),
    }),

    vehicalPurchaseLoan: Yup.string().when("currentStepIndex", {
      is: "5",
      then: () =>
        Yup.string()
          .required("Bike Purchased on Loan Is Required")
          .test("not blank", "Select value", (value) => {
            return value !== "";
          }),
    }),

    vehicalFinancierName: Yup.string().when(
      ["currentStepIndex", "vehicalPurchaseLoan"],
      {
        is: (currentStepIndex, vehicalPurchaseLoan) =>
          currentStepIndex === "5" && vehicalPurchaseLoan === "yes",
        then: () =>
          Yup.string()
            .required("Vehicle Financier Name is Required")
            .min(4, "Minimum length must be 4 characters"),
      }
    ),

    financierNameValidaton: Yup.string().when("currentStepIndex", {
      is: "5",
      then: () =>
        Yup.string().required("Vehicle Registration Number Is Required"),
    }),

    financierID: Yup.string().when(
      ["currentStepIndex", "vehicalPurchaseLoan", "financierNameValidaton"],
      {
        is: (currentStepIndex, vehicalPurchaseLoan, financierNameValidaton) =>
          currentStepIndex === "5" &&
          vehicalPurchaseLoan === "yes" &&
          financierNameValidaton === "yes",
        then: () =>
          Yup.string().required(
            "Name not found,please pick name from dropdown only"
          ),
      }
    ),
    engineNumber: Yup.string().when("currentStepIndex", {
      is: "5",
      then: () =>
        Yup.string()
          .matches(/^[a-zA-Z0-9/]*$/, "Only letters and numbers are allowed")
          .max(25, "Maximum length must be 25 characters")
          .min(5, "Minimum length must be 5 characters")
          .required("Engine Number Is Required"),
    }),
    chassisNumber: Yup.string().when("currentStepIndex", {
      is: "5",
      then: () =>
        Yup.string()
          .matches(/^[a-zA-Z0-9/]*$/, "Only letters and numbers are allowed")
          .min(17, "Minimum length must be 17 characters")
          .max(25, "Maximum length must be 25 characters")
          .required("Chassis Number is required"),
    }),

    registeredRto: Yup.string().when("currentStepIndex", {
      is: "5",
      then: () =>
        Yup.string()
          .required("Vehicle Registration Number Is Required")
          .matches(
            /^(?:[A-Z]{2}\d{2}[A-Z]{1,2}\d{4}|[A-Z]{3}\d{4}|[A-Z]{2}\d{2} ?[A-Z]{1,2} ?\d{4}|CD \d{2} \d{3}|[A-Z]{2} \d{5}|[A-Z]{2}\d[A-Z]{1,2} ?[A-Z]{1,3} ?\d{4}|[0-9]{2}BH[0-9]{4})$/,
            "Invalid Vehicle Number, e.g., MH12AB1234, MAY2731, or 22BH0000"
          ),
    }),

    // registeredRto: Yup.string().when("currentStepIndex", {
    //   is: "5",
    //   then: () =>
    //     Yup.string()
    //       .required("Vehicle Registration Number Is Required")
    //       .matches(
    //         /^[A-Z]{2}\d{2}[A-Z]{1,2}\d{4}$/,
    //         "Invalid Vehicle Number,Eg MH12AAXXXX"
    //       ),
    // }),

    vehicalTermAccept: Yup.boolean().when("currentStepIndex", {
      is: "5",
      then: () =>
        Yup.boolean()
          .oneOf([true], "You must accept the terms and conditions")
          .required("You must accept the terms and conditions"),
    }),

    puc: Yup.string(),

    pucNumber: Yup.string().when("puc", {
      is: "yes",
      then: () => Yup.string().required("PUC number is required"),
    }),
    pucExpiryDate: Yup.string().when("puc", {
      is: "yes",
      then: () => Yup.string().required("PUC Expiry Date Is Required"),
    }),
  });

  const validationSchema6 = Yup.object().shape({
    // First page
    kycNominePanNumber: Yup.string()
      .trim()
      .matches(/^[A-Z]{5}[0-9]{4}[A-Z]$/, "Invalid PAN Number")
      .required("PAN Number Is Required"),

    kycNomineDob: Yup.string()
      .trim()
      .required("DOB Is Required")
      .test("not blank", "Select value", (value) => value !== "")
      .test("valid date", "Future date is not allowed", (value) => {
        const currentDate = new Date();
        const inputDate = new Date(value);
        return inputDate <= currentDate;
      })
      .test(
        "valid range",
        "Date should be within the past 100 years",
        (value) => {
          return yearValidation(value, 100);
        }
      ),

    kycNomineGender: Yup.string()
      .trim()
      .required("Gender Is Required")
      .test("not blank", "Select value", (value) => {
        return value !== "";
      }),

    currentStepIndex: Yup.string().required("Field is required"),

    //second page
    ownerPrefix: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () =>
        Yup.string()
          .required(" Prefix Is Required")
          .test("not blank", "Select value", (value) => {
            return value !== "";
          }),
    }),
    ownerFirstName: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () =>
        Yup.string()
          .matches(/^[a-zA-Z ]*$/, "Invalid First Name")
          .required(" First Name Is Required"),
    }),

    ownerMiddleName: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () => Yup.string().matches(/^[a-zA-Z ]*$/, "Invalid Middle Name"),
    }),

    ownerLastName: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () =>
        Yup.string()
          .matches(/^[a-zA-Z ]*$/, "Invalid Last Name")
          .required(" Last Name Is Required"),
    }),

    ownerEmail: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () =>
        Yup.string()
          .required("Email is required")
          .test("isValidEmail", "Invalid email format", (value) => {
            return value?.includes("@") && value?.includes(".");
          }),
    }),
  
    ownerPhoneNumber: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () =>
        Yup.string()
          .required("Phone Number is required")
          .matches(/^[a-zA-Z0-9]{10}$/, "Phone Number must be exactly 10 alphanumeric characters"),
    }),

    ownerMaritialStus: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () =>
        Yup.string()
          .required(" Maritial Status Is Required")
          .test("not blank", "Select value", (value) => {
            return value !== "";
          }),
    }),

    // third page
    nomineFirstName: Yup.string().when("currentStepIndex", {
      is: "2",
      then: () =>
        Yup.string()
          .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed")
          .required(" First Name Is Required"),
    }),

    nomineLastName: Yup.string().when("currentStepIndex", {
      is: "2",
      then: () =>
        Yup.string()
          .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed")
          .required(" Last Name Is Required"),
    }),

    nomineDob: Yup.string().when(
      ["currentStepIndex", "nomineRelation", "kycNomineDob"],
      {
        is: (currentStepIndex) => currentStepIndex === "2",
        then: () =>
          Yup.string()
            .required("DOB Is Required")
            .test("not blank", "Select value", (value) => value !== "")
            .test("valid date", "Future date is not allowed", (value) => {
              const currentDate = new Date();
              const inputDate = new Date(value);
              return inputDate <= currentDate;
            })
            .test(
              "valid range",
              "Date should be within the past 100 years",
              (value) => {
                return yearValidation(value, 100);
              }
            )
            .test("date comparison", "Invalid Date", function (value) {
              const userDob = new Date(this.parent.kycNomineDob);
              const nomineeDob = new Date(value);

              if (
                this.parent.nomineRelation === "son" ||
                this.parent.nomineRelation === "daughter"
              ) {
                return (
                  nomineeDob > userDob ||
                  this.createError({
                    message: "Nominee DOB should be after user DOB",
                  })
                );
              } else if (
                this.parent.nomineRelation === "mother" ||
                this.parent.nomineRelation === "father"
              ) {
                return (
                  nomineeDob < userDob ||
                  this.createError({
                    message: "Nominee DOB should be before user DOB",
                  })
                );
              }

              return true;
            }),
        else: Yup.string(),
      }
    ),

    nomineRelation: Yup.string().when("currentStepIndex", {
      is: "2",
      then: () =>
        Yup.string()
          .required(" Relation Of Nomine Is Required")
          .test("not blank", "Select value", (value) => {
            return value !== "";
          }),
    }),

    // 4th page

    ownerFlatNumber: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () =>
        Yup.string()
          .matches(/^[0-9]+$/, "Flat Number must be a number")
          .required(" Flat Number Is Required"),
    }),

    ownerAddress: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required("Address is required"),
      // .max(120, "Address cannot be longer than 120 characters"), // max length validation
    }),

    ownerLocation: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required(" Location Is Required"),
    }),
    ownerPincode: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () =>
        Yup.string()
          .matches(/^\d{6}$/, "Invalid PIN code")
          .required(" Pincode Is Required"),
    }),

    ownerCity: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required(" City Is Required"),
    }),

    ownerState: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required(" State Is Required"),
    }),
    isRegisteredAddressSame: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required("Field is required"),
    }),

    ownerFlatNumberReg: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () =>
        Yup.string()
          .matches(/^[0-9]+$/, "Flat Number must be a number")
          .required("  Reg Flat Number Is Required"),
    }),
    ownerAddressReg: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required(" Reg Address Is Required"),
    }),
    ownerLocationReg: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required(" Reg Location Is Required"),
    }),
    ownerPincodeReg: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () =>
        Yup.string()
          .matches(/^\d{6}$/, "Invalid PIN code")
          .required(" Reg Pincode Is Required"),
    }),
    ownerCityReg: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required(" Reg City Is Required"),
    }),
    ownerStateReg: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required("Reg State Is Required"),
    }),
    // page 6

    vehicalMakeModel: Yup.string().when("currentStepIndex", {
      is: "4",
      then: () => Yup.string().required(" Make and Model Is Required"),
    }),

    vehicalRegistionDate: Yup.string().when("currentStepIndex", {
      is: "4",
      then: () =>
        Yup.string()
          .required("Registration Date Is Required")
          .test("not blank", "Select value", (value) => value !== "")
          .test("valid date", "Future date is not allowed", (value) => {
            const currentDate = new Date();
            const inputDate = new Date(value);
            return inputDate <= currentDate;
          })
          .test(
            "valid range",
            "Date should be within the past 50 years",
            (value) => {
              return yearValidation(value, 50);
            }
          ),
    }),

    vehicalManufaDate: Yup.string().when("currentStepIndex", {
      is: "4",
      then: () => Yup.string().required(" Manufacturing Year Is Required"),
    }),

    vehicalPurchaseLoan: Yup.string().when("currentStepIndex", {
      is: "4",
      then: () =>
        Yup.string()
          .required("Bike Purchased on Loan Is Required")
          .test("not blank", "Select value", (value) => {
            return value !== "";
          }),
    }),

    vehicalFinancierName: Yup.string().when(
      ["currentStepIndex", "vehicalPurchaseLoan"],
      {
        is: (currentStepIndex, vehicalPurchaseLoan) =>
          currentStepIndex === "4" && vehicalPurchaseLoan === "yes",
        then: () =>
          Yup.string()
            .required("Vehicle Financier Name is Required")
            .min(4, "Minimum length must be 4 characters"),
      }
    ),
    financierNameValidaton: Yup.string().when("currentStepIndex", {
      is: "4",
      then: () =>
        Yup.string().required("Vehicle Registration Number Is Required"),
    }),

    financierID: Yup.string().when(
      ["currentStepIndex", "vehicalPurchaseLoan", "financierNameValidaton"],
      {
        is: (currentStepIndex, vehicalPurchaseLoan, financierNameValidaton) =>
          currentStepIndex === "4" &&
          vehicalPurchaseLoan === "yes" &&
          financierNameValidaton === "yes",
        then: () =>
          Yup.string().required(
            "Name not found,please pick name from dropdown only"
          ),
      }
    ),

    engineNumber: Yup.string().when("currentStepIndex", {
      is: "4",
      then: () =>
        Yup.string()
          .matches(/^[a-zA-Z0-9/]*$/, "Only letters and numbers are allowed")
          .max(25, "Maximum length must be 25 characters")
          .min(5, "Minimum length must be 5 characters")
          .required("EngineNumber Number Is Required"),
    }),

    chassisNumber: Yup.string().when("currentStepIndex", {
      is: "4",
      then: () =>
        Yup.string()
          .matches(/^[a-zA-Z0-9/]*$/, "Only letters and numbers are allowed")
          .min(17, "Minimum length must be 17 characters")
          .max(25, "Maximum length must be 25 characters")
          .required("Chassis Number is required"),
    }),

    registeredRto: Yup.string().when("currentStepIndex", {
      is: "4",
      then: () =>
        Yup.string().required("Vehicle Registration Number Is Required"),
    }),
    vehicalTermAccept: Yup.boolean().when("currentStepIndex", {
      is: "4",
      then: () =>
        Yup.boolean()
          .oneOf([true], "You must accept the terms and conditions")
          .required("You must accept the terms and conditions"),
    }),
    puc: Yup.string(),

    pucNumber: Yup.string().when("puc", {
      is: "yes",
      then: () => Yup.string().required("PUC number is required"),
    }),
    pucExpiryDate: Yup.string().when("puc", {
      is: "yes",
      then: () => Yup.string().required("PUC Expiry Date Is Required"),
    }),
  });

  const validationSchema7 = Yup.object().shape({
    kycType: Yup.string().required("KYC Type Is Required"),

    kycNominePanNumber: Yup.string().when("kycType", {
      is: "pan",
      then: () =>
        Yup.string()
          .trim()
          .matches(/^[A-Z]{5}[0-9]{4}[A-Z]$/, "Invalid PAN Number")
          .required("PAN Number Is Required"),
    }),

    kycNomineAadharNumber: Yup.string().when("kycType", {
      is: "aadhar",
      then: () =>
        Yup.string()
          .trim()
          .matches(/^[0-9]{12}$/, "Invalid Aadhaar Number")
          .required("Aadhaar Number Is Required"),
    }),
    kycNomineFullName: Yup.string().when("kycType", {
      is: "aadhar",
      then: () =>
        Yup.string()
          .trim()
          .matches(
            /^[a-zA-Z\s]+$/,
            "Name should contain only letters and spaces"
          )
          .required("Full Name Is Required"),
    }),

    kycNomineDob: Yup.string()
      .trim()
      .required("DOB Is Required")
      .test("not blank", "Select value", (value) => value !== "")
      .test("valid date", "Future date is not allowed", (value) => {
        const currentDate = new Date();
        const inputDate = new Date(value);
        return inputDate <= currentDate;
      })
      .test(
        "valid range",
        "Date should be within the past 100 years",
        (value) => {
          return yearValidation(value, 100);
        }
      ),

    kycNomineGender: Yup.string()
      .trim()
      .required("Gender Is Required")
      .test("not blank", "Select value", (value) => {
        return value !== "";
      }),

    currentStepIndex: Yup.string().required("Field is required"),

    //second page
    ownerPrefix: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () =>
        Yup.string()
          .required(" Prefix Is Required")
          .test("not blank", "Select value", (value) => {
            return value !== "";
          }),
    }),
    ownerFirstName: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () =>
        Yup.string()
          .matches(/^[a-zA-Z ]*$/, "Invalid First Name")
          .required(" First Name Is Required"),
    }),

    ownerMiddleName: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () => Yup.string().matches(/^[a-zA-Z ]*$/, "Invalid Middle Name"),
    }),

    ownerLastName: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () =>
        Yup.string()
          .matches(/^[a-zA-Z ]*$/, "Invalid Last Name")
          .required(" Last Name Is Required"),
    }),

    ownerEmail: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () =>
        Yup.string()
          .required("Email is required")
          .email("Invalid email format")
          .matches(
            /^(?!\.)(?!.*\.\.)(?!.*\.$)[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
            "Invalid email format"
          ),
    }),

    ownerPhoneNumber: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () =>
        Yup.string()
          .matches(/^\d{10}$/, "Phone Number must be 10 digits")
          .required(" Phone Number Is Required"),
    }),

    ownerMaritialStus: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () =>
        Yup.string()
          .required(" Maritial Status Is Required")
          .test("not blank", "Select value", (value) => {
            return value !== "";
          }),
    }),

    // third page
    nomineFirstName: Yup.string().when("currentStepIndex", {
      is: "2",
      then: () =>
        Yup.string().matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed"),
    }),

    nomineLastName: Yup.string().when("currentStepIndex", {
      is: "2",
      then: () =>
        Yup.string().matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed"),
    }),

    // nomineDob: Yup.string().when(
    //   ["currentStepIndex", "nomineRelation", "kycNomineDob"],
    //   {
    //     is: (currentStepIndex) => currentStepIndex === "2",
    //     then: () =>
    //       Yup.string()
    //         .test("not blank", "Select value", (value) => value !== "")
    //         .test("valid date", "Future date is not allowed", (value) => {
    //           const currentDate = new Date();
    //           const inputDate = new Date(value);
    //           return inputDate <= currentDate;
    //         })
    //         .test(
    //           "valid range",
    //           "Date should be within the past 100 years",
    //           (value) => {
    //             return yearValidation(value, 100);
    //           }
    //         )
    //         .test("date comparison", "Invalid Date", function (value) {
    //           const userDob = new Date(this.parent.kycNomineDob);
    //           const nomineeDob = new Date(value);

    //           if (
    //             this.parent.nomineRelation === "son" ||
    //             this.parent.nomineRelation === "daughter"
    //           ) {
    //             return (
    //               nomineeDob > userDob ||
    //               this.createError({
    //                 message: "Nominee DOB should be after user DOB",
    //               })
    //             );
    //           } else if (
    //             this.parent.nomineRelation === "mother" ||
    //             this.parent.nomineRelation === "father"
    //           ) {
    //             return (
    //               nomineeDob < userDob ||
    //               this.createError({
    //                 message: "Nominee DOB should be before user DOB",
    //               })
    //             );
    //           }

    //           return true;
    //         }),
    //     else: Yup.string(),
    //   }
    // ),

    nomineDob: Yup.string()
      .nullable()
      .notRequired()
      .when(["currentStepIndex", "nomineRelation", "kycNomineDob"], {
        is: (currentStepIndex) => currentStepIndex === "2",
        then: () =>
          Yup.string()
            .test(
              "not blank",
              "Select value",
              (value) => !value || value !== ""
            )
            .test("valid date", "Future date is not allowed", (value) => {
              if (!value) return true; // Skip validation if empty
              const currentDate = new Date();
              const inputDate = new Date(value);
              return inputDate <= currentDate;
            })
            .test(
              "valid range",
              "Date should be within the past 100 years",
              (value) => {
                if (!value) return true; // Skip validation if empty
                return yearValidation(value, 100);
              }
            )
            .test("date comparison", "Invalid Date", function (value) {
              if (!value) return true; // Skip validation if empty
              const userDob = new Date(this.parent.kycNomineDob);
              const nomineeDob = new Date(value);

              if (
                this.parent.nomineRelation === "son" ||
                this.parent.nomineRelation === "daughter"
              ) {
                return (
                  nomineeDob > userDob ||
                  this.createError({
                    message: "Nominee DOB should be after user DOB",
                  })
                );
              } else if (
                this.parent.nomineRelation === "mother" ||
                this.parent.nomineRelation === "father"
              ) {
                return (
                  nomineeDob < userDob ||
                  this.createError({
                    message: "Nominee DOB should be before user DOB",
                  })
                );
              }

              return true;
            }),
        else: Yup.string().nullable().notRequired(),
      }),

    nomineRelation: Yup.string().when("currentStepIndex", {
      is: "2",
      then: () =>
        Yup.string().test("not blank", "Select value", (value) => {
          return value !== "";
        }),
    }),

    // 4th page

    ownerFlatNumber: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () =>
        Yup.string()
          .matches(/^[0-9]+$/, "Flat Number must be a number")
          .required(" Flat Number Is Required"),
    }),

    ownerAddress: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required("Address is required"),
      // .max(120, "Address cannot be longer than 120 characters"), // max length validation
    }),

    ownerLocation: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required(" Location Is Required"),
    }),
    ownerPincode: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () =>
        Yup.string()
          .matches(/^\d{6}$/, "Invalid PIN code")
          .required(" Pincode Is Required"),
    }),

    ownerCity: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required(" City Is Required"),
    }),

    ownerState: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required(" State Is Required"),
    }),
    isRegisteredAddressSame: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required("Field is required"),
    }),

    ownerFlatNumberReg: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () =>
        Yup.string()
          .matches(/^[0-9]+$/, "Flat Number must be a number")
          .required("  Reg Flat Number Is Required"),
    }),

    ownerAddressReg: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required(" Reg Address Is Required"),
    }),

    ownerLocationReg: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required(" Reg Location Is Required"),
    }),
    ownerPincodeReg: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () =>
        Yup.string()
          .matches(/^\d{6}$/, "Invalid PIN code")
          .required(" Reg Pincode Is Required"),
    }),

    ownerCityReg: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required(" Reg City Is Required"),
    }),

    ownerStateReg: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required("Reg State Is Required"),
    }),

    //page 5
    policyNumber: Yup.string().when("currentStepIndex", {
      is: "4",
      then: () =>
        Yup.string()
          .matches(
            /^[a-zA-Z0-9\/-]*$/,
            "Only letters, numbers, and '/' are allowed"
          )
          .max(50, "Maximum length must be 50 characters")
          .min(4, "Minimum length must be 4 characters")
          .required("Policy Number Is Required"),
    }),

    policyInsuranceNamePrevious: Yup.string().when("currentStepIndex", {
      is: "4",
      then: () => Yup.string().required("Previous Policy Name Is Required"),
    }),

    PolicyEndtDate: Yup.string().when("currentStepIndex", {
      is: "4",
      then: () =>
        Yup.string()
          .required("Policy End Date Is Required")
          .test(
            "valid date",
            "Policy End Date should be within the past 50 years",
            (value) => {
              const currentDate = new Date();
              const selectedDate = new Date(value);
              const yearDifference =
                currentDate.getFullYear() - selectedDate.getFullYear();
              return yearDifference <= 50;
            }
          ),
    }),

    policyInsuranceName: Yup.string().when("currentStepIndex", {
      is: "4",
      then: () =>
        Yup.string()
          .required(" Policy Insurance Name Is Required")
          .test("not blank", "Select value", (value) => {
            return value !== "";
          }),
    }),

    tpPolicyInsuranceName: Yup.string().when(
      ["currentStepIndex", "policyType"],
      {
        is: (currentStepIndex, policyType) =>
          currentStepIndex === "4" && policyType === "OD",
        then: () =>
          Yup.string()
            .required("TP Policy Insurer Name Is Required")
            .test("not blank", "Select value", (value) => {
              return value !== "";
            }),
      }
    ),

    tpPolicyNumber: Yup.string().when(["currentStepIndex", "policyType"], {
      is: (currentStepIndex, policyType) =>
        currentStepIndex === "4" && policyType === "OD",
      then: () =>
        Yup.string()
          .matches(
            /^[a-zA-Z0-9\/-]*$/,
            "Only letters, numbers, '/', and '-' are allowed"
          )
          .max(30, "Maximum length must be 30 characters")
          .min(4, "Minimum length must be 4 characters")
          .required("TP policy Number Is Required"),
    }),

    policyPreviousType: Yup.string().when("currentStepIndex", {
      is: "4",
      then: () =>
        Yup.string()
          .required("Previous policy Type Is Required")
          .test("not blank", "Select value", (value) => {
            return value !== "";
          }),
    }),

    // page 6

    vehicalMakeModel: Yup.string().when("currentStepIndex", {
      is: "5",
      then: () => Yup.string().required(" Make and Model Is Required"),
    }),

    vehicalRegistionDate: Yup.string().when("currentStepIndex", {
      is: "5",
      then: () =>
        Yup.string()
          .required("Registration Date Is Required")
          .test("not blank", "Select value", (value) => value !== "")
          .test("valid date", "Future date is not allowed", (value) => {
            const currentDate = new Date();
            const inputDate = new Date(value);
            return inputDate <= currentDate;
          })
          .test(
            "valid range",
            "Date should be within the past 50 years",
            (value) => {
              return yearValidation(value, 50);
            }
          ),
    }),

    vehicalManufaDate: Yup.string().when("currentStepIndex", {
      is: "5",
      then: () => Yup.string().required(" Manufacturing Year Is Required"),
    }),

    vehicalPurchaseLoan: Yup.string().when("currentStepIndex", {
      is: "5",
      then: () =>
        Yup.string()
          .required("Bike Purchased on Loan Is Required")
          .test("not blank", "Select value", (value) => {
            return value !== "";
          }),
    }),

    vehicalFinancierName: Yup.string().when(
      ["currentStepIndex", "vehicalPurchaseLoan"],
      {
        is: (currentStepIndex, vehicalPurchaseLoan) =>
          currentStepIndex === "5" && vehicalPurchaseLoan === "yes",
        then: () =>
          Yup.string()
            .required("Vehicle Financier Name is Required")
            .min(4, "Minimum length must be 4 characters"),
      }
    ),

    financierNameValidaton: Yup.string().when("currentStepIndex", {
      is: "5",
      then: () =>
        Yup.string().required("Vehicle Registration Number Is Required"),
    }),

    financierID: Yup.string().when(
      ["currentStepIndex", "vehicalPurchaseLoan", "financierNameValidaton"],
      {
        is: (currentStepIndex, vehicalPurchaseLoan, financierNameValidaton) =>
          currentStepIndex === "5" &&
          vehicalPurchaseLoan === "yes" &&
          financierNameValidaton === "yes",
        then: () =>
          Yup.string().required(
            "Name not found,please pick name from dropdown only"
          ),
      }
    ),
    engineNumber: Yup.string().when("currentStepIndex", {
      is: "5",
      then: () =>
        Yup.string()
          .matches(
            /^[a-zA-Z0-9/ ]*$/,
            "Only letters, numbers, and spaces are allowed"
          )
          .max(20, "Maximum length must be 20 characters")
          .min(5, "Minimum length must be 5 characters")
          .required("Engine Number Is Required"),
    }),

    chassisNumber: Yup.string().when("currentStepIndex", {
      is: "5",
      then: () =>
        Yup.string()
          .matches(/^[a-zA-Z0-9/]*$/, "Only letters and numbers are allowed")
          .min(17, "Minimum length must be 17 characters")
          .max(25, "Maximum length must be 25 characters")
          .required("Chassis Number is required"),
    }),

    registeredRto: Yup.string().when("currentStepIndex", {
      is: "5",
      then: () =>
        Yup.string()
          .required("Vehicle Registration Number Is Required")
          .matches(
            /^(?:[A-Z]{2}\d{2}[A-Z]{1,2}\d{4}|[A-Z]{3}\d{4}|[A-Z]{2}\d{2} ?[A-Z]{1,2} ?\d{4}|CD \d{2} \d{3}|[A-Z]{2} \d{5}|[A-Z]{2}\d[A-Z]{1,2} ?[A-Z]{1,3} ?\d{4}|[0-9]{2}BH[0-9]{4})$/,
            "Invalid Vehicle Number, e.g., MH12AB1234, DL1SBF1234, or 22BH0000"
          ),
    }),

    // registeredRto: Yup.string().when("currentStepIndex", {
    //   is: "5",
    //   then: () =>
    //     Yup.string()
    //       .required("Vehicle Registration Number Is Required")
    //       .matches(
    //         /^[A-Z]{2}\d{2}[A-Z]{1,2}\d{4}$/,
    //         "Invalid Vehicle Number,Eg MH12AAXXXX"
    //       ),
    // }),

    vehicalTermAccept: Yup.boolean().when("currentStepIndex", {
      is: "5",
      then: () =>
        Yup.boolean()
          .oneOf([true], "You must accept the terms and conditions")
          .required("You must accept the terms and conditions"),
    }),
  });

  const validationSchema8 = Yup.object().shape({
    kycType: Yup.string().required("KYC Type Is Required"),

    kycNominePanNumber: Yup.string().when("kycType", {
      is: "pan",
      then: () =>
        Yup.string()
          .trim()
          .matches(/^[A-Z]{5}[0-9]{4}[A-Z]$/, "Invalid PAN Number")
          .required("PAN Number Is Required"),
    }),

    kycNomineAadharNumber: Yup.string().when("kycType", {
      is: "aadhar",
      then: () =>
        Yup.string()
          .trim()
          .matches(/^[0-9]{12}$/, "Invalid Aadhaar Number")
          .required("Aadhaar Number Is Required"),
    }),
    kycNomineFullName: Yup.string().when("kycType", {
      is: "aadhar",
      then: () =>
        Yup.string()
          .trim()
          .matches(
            /^[a-zA-Z\s]+$/,
            "Name should contain only letters and spaces"
          )
          .required("Full Name Is Required"),
    }),

    kycNomineDob: Yup.string()
      .trim()
      .required("DOB Is Required")
      .test("not blank", "Select value", (value) => value !== "")
      .test("valid date", "Future date is not allowed", (value) => {
        const currentDate = new Date();
        const inputDate = new Date(value);
        return inputDate <= currentDate;
      })
      .test(
        "valid range",
        "Date should be within the past 100 years",
        (value) => {
          return yearValidation(value, 100);
        }
      ),

    kycNomineGender: Yup.string()
      .trim()
      .required("Gender Is Required")
      .test("not blank", "Select value", (value) => {
        return value !== "";
      }),

    currentStepIndex: Yup.string().required("Field is required"),

    //second page
    ownerPrefix: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () =>
        Yup.string()
          .required(" Prefix Is Required")
          .test("not blank", "Select value", (value) => {
            return value !== "";
          }),
    }),
    ownerFirstName: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () =>
        Yup.string()
          .matches(/^[a-zA-Z ]*$/, "Invalid First Name")
          .required(" First Name Is Required"),
    }),

    ownerMiddleName: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () => Yup.string().matches(/^[a-zA-Z ]*$/, "Invalid Middle Name"),
    }),

    ownerLastName: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () =>
        Yup.string()
          .matches(/^[a-zA-Z ]*$/, "Invalid Last Name")
          .required(" Last Name Is Required"),
    }),

    ownerEmail: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () =>
        Yup.string()
          .required("Email is required")
          .email("Invalid email format")
          .matches(
            /^(?!\.)(?!.*\.\.)(?!.*\.$)[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
            "Invalid email format"
          ),
    }),

    ownerPhoneNumber: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () =>
        Yup.string()
          .matches(/^\d{10}$/, "Phone Number must be 10 digits")
          .required(" Phone Number Is Required"),
    }),

    ownerMaritialStus: Yup.string().when("currentStepIndex", {
      is: "1",
      then: () =>
        Yup.string()
          .required(" Maritial Status Is Required")
          .test("not blank", "Select value", (value) => {
            return value !== "";
          }),
    }),

    // third page
    nomineFirstName: Yup.string().when("currentStepIndex", {
      is: "2",
      then: () =>
        Yup.string().matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed"),
    }),

    nomineLastName: Yup.string().when("currentStepIndex", {
      is: "2",
      then: () =>
        Yup.string().matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed"),
    }),

    // nomineDob: Yup.string().when(
    //   ["currentStepIndex", "nomineRelation", "kycNomineDob"],
    //   {
    //     is: (currentStepIndex) => currentStepIndex === "2",
    //     then: () =>
    //       Yup.string()
    //         .test("not blank", "Select value", (value) => value !== "")
    //         .test("valid date", "Future date is not allowed", (value) => {
    //           const currentDate = new Date();
    //           const inputDate = new Date(value);
    //           return inputDate <= currentDate;
    //         })
    //         .test(
    //           "valid range",
    //           "Date should be within the past 100 years",
    //           (value) => {
    //             return yearValidation(value, 100);
    //           }
    //         )
    //         .test("date comparison", "Invalid Date", function (value) {
    //           const userDob = new Date(this.parent.kycNomineDob);
    //           const nomineeDob = new Date(value);

    //           if (
    //             this.parent.nomineRelation === "son" ||
    //             this.parent.nomineRelation === "daughter"
    //           ) {
    //             return (
    //               nomineeDob > userDob ||
    //               this.createError({
    //                 message: "Nominee DOB should be after user DOB",
    //               })
    //             );
    //           } else if (
    //             this.parent.nomineRelation === "mother" ||
    //             this.parent.nomineRelation === "father"
    //           ) {
    //             return (
    //               nomineeDob < userDob ||
    //               this.createError({
    //                 message: "Nominee DOB should be before user DOB",
    //               })
    //             );
    //           }

    //           return true;
    //         }),
    //     else: Yup.string(),
    //   }
    // ),

    nomineDob: Yup.string()
      .nullable()
      .notRequired()
      .when(["currentStepIndex", "nomineRelation", "kycNomineDob"], {
        is: (currentStepIndex) => currentStepIndex === "2",
        then: () =>
          Yup.string()
            .test(
              "not blank",
              "Select value",
              (value) => !value || value !== ""
            )
            .test("valid date", "Future date is not allowed", (value) => {
              if (!value) return true; // Skip validation if empty
              const currentDate = new Date();
              const inputDate = new Date(value);
              return inputDate <= currentDate;
            })
            .test(
              "valid range",
              "Date should be within the past 100 years",
              (value) => {
                if (!value) return true; // Skip validation if empty
                return yearValidation(value, 100);
              }
            )
            .test("date comparison", "Invalid Date", function (value) {
              if (!value) return true; // Skip validation if empty
              const userDob = new Date(this.parent.kycNomineDob);
              const nomineeDob = new Date(value);

              if (
                this.parent.nomineRelation === "son" ||
                this.parent.nomineRelation === "daughter"
              ) {
                return (
                  nomineeDob > userDob ||
                  this.createError({
                    message: "Nominee DOB should be after user DOB",
                  })
                );
              } else if (
                this.parent.nomineRelation === "mother" ||
                this.parent.nomineRelation === "father"
              ) {
                return (
                  nomineeDob < userDob ||
                  this.createError({
                    message: "Nominee DOB should be before user DOB",
                  })
                );
              }

              return true;
            }),
        else: Yup.string().nullable().notRequired(),
      }),

    nomineRelation: Yup.string().when("currentStepIndex", {
      is: "2",
      then: () =>
        Yup.string().test("not blank", "Select value", (value) => {
          return value !== "";
        }),
    }),
    // 4th page

    ownerFlatNumber: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () =>
        Yup.string()
          .matches(/^[0-9]+$/, "Flat Number must be a number")
          .required(" Flat Number Is Required"),
    }),

    ownerAddress: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required("Address is required"),
      // .max(120, "Address cannot be longer than 120 characters"), // max length validation
    }),

    ownerLocation: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required(" Location Is Required"),
    }),
    ownerPincode: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () =>
        Yup.string()
          .matches(/^\d{6}$/, "Invalid PIN code")
          .required(" Pincode Is Required"),
    }),

    ownerCity: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required(" City Is Required"),
    }),

    ownerState: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required(" State Is Required"),
    }),
    isRegisteredAddressSame: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required("Field is required"),
    }),

    ownerFlatNumberReg: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () =>
        Yup.string()
          .matches(/^[0-9]+$/, "Flat Number must be a number")
          .required("  Reg Flat Number Is Required"),
    }),
    ownerAddressReg: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required(" Reg Address Is Required"),
    }),
    ownerLocationReg: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required(" Reg Location Is Required"),
    }),
    ownerPincodeReg: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () =>
        Yup.string()
          .matches(/^\d{6}$/, "Invalid PIN code")
          .required(" Reg Pincode Is Required"),
    }),
    ownerCityReg: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required(" Reg City Is Required"),
    }),
    ownerStateReg: Yup.string().when("currentStepIndex", {
      is: "3",
      then: () => Yup.string().required("Reg State Is Required"),
    }),
    // page 6

    vehicalMakeModel: Yup.string().when("currentStepIndex", {
      is: "4",
      then: () => Yup.string().required(" Make and Model Is Required"),
    }),

    vehicalRegistionDate: Yup.string().when("currentStepIndex", {
      is: "4",
      then: () =>
        Yup.string()
          .required("Registration Date Is Required")
          .test("not blank", "Select value", (value) => value !== "")
          .test("valid date", "Future date is not allowed", (value) => {
            const currentDate = new Date();
            const inputDate = new Date(value);
            return inputDate <= currentDate;
          })
          .test(
            "valid range",
            "Date should be within the past 50 years",
            (value) => {
              return yearValidation(value, 50);
            }
          ),
    }),

    vehicalManufaDate: Yup.string().when("currentStepIndex", {
      is: "4",
      then: () => Yup.string().required(" Manufacturing Year Is Required"),
    }),

    vehicalPurchaseLoan: Yup.string().when("currentStepIndex", {
      is: "4",
      then: () =>
        Yup.string()
          .required("Bike Purchased on Loan Is Required")
          .test("not blank", "Select value", (value) => {
            return value !== "";
          }),
    }),

    vehicalFinancierName: Yup.string().when(
      ["currentStepIndex", "vehicalPurchaseLoan"],
      {
        is: (currentStepIndex, vehicalPurchaseLoan) =>
          currentStepIndex === "4" && vehicalPurchaseLoan === "yes",
        then: () =>
          Yup.string()
            .required("Vehicle Financier Name is Required")
            .min(4, "Minimum length must be 4 characters"),
      }
    ),
    financierNameValidaton: Yup.string().when("currentStepIndex", {
      is: "4",
      then: () =>
        Yup.string().required("Vehicle Registration Number Is Required"),
    }),

    financierID: Yup.string().when(
      ["currentStepIndex", "vehicalPurchaseLoan", "financierNameValidaton"],
      {
        is: (currentStepIndex, vehicalPurchaseLoan, financierNameValidaton) =>
          currentStepIndex === "4" &&
          vehicalPurchaseLoan === "yes" &&
          financierNameValidaton === "yes",
        then: () =>
          Yup.string().required(
            "Name not found,please pick name from dropdown only"
          ),
      }
    ),

    engineNumber: Yup.string().when("currentStepIndex", {
      is: "5",
      then: () =>
        Yup.string()
          .matches(
            /^[a-zA-Z0-9/ ]*$/,
            "Only letters, numbers, and spaces are allowed"
          )
          .max(17, "Maximum length must be 17 characters")
          .min(5, "Minimum length must be 5 characters")
          .required("Engine Number Is Required"),
    }),

    chassisNumber: Yup.string().when("currentStepIndex", {
      is: "4",
      then: () =>
        Yup.string()
          .matches(/^[a-zA-Z0-9/]*$/, "Only letters and numbers are allowed")
          .min(17, "Minimum length must be 17 characters")
          .max(25, "Maximum length must be 25 characters")
          .required("Chassis Number is required"),
    }),

    // registeredRto: Yup.string().when("currentStepIndex", {
    //   is: "4",
    //   then: () =>
    //     Yup.string()
    //       .required("Vehicle Registration Number Is Required")
    //       .matches(
    //        /^(?:[A-Z]{2}\d{2}[A-Z]{1,2}\d{4}|[A-Z]{2}\d{2} ?[A-Z]{1,2} ?\d{4}|CD \d{2} \d{3}|[A-Z]{2} \d{5}|[A-Z]{2}\d[A-Z]{1,2} ?[A-Z]{1,3} ?\d{4}|[0-9]{2}BH[0-9]{4})$/,
    //     "Invalid Vehicle Number, e.g., MH12AB1234, DL1SBF1234, or 22BH0000"
    //       ),
    // }),
    registeredRto: Yup.string().when("currentStepIndex", {
      is: "4",
      then: () =>
        Yup.string().required("Vehicle Registration Number Is Required"),
    }),
    vehicalTermAccept: Yup.boolean().when("currentStepIndex", {
      is: "4",
      then: () =>
        Yup.boolean()
          .oneOf([true], "You must accept the terms and conditions")
          .required("You must accept the terms and conditions"),
    }),
  });

  const formik = useFormik({
    initialValues: initialData,
    validationSchema:
      formContext?.singleQuotesData?.insuranceCompany ===
      "HDFC ERGO General Insurance Company"
        ? policyType === "renew"
          ? validationSchema3
          : validationSchema4
        : formContext?.singleQuotesData?.insuranceCompany ===
          "Future Generali India Insurance Co. Ltd"
        ? policyType === "renew"
          ? validationSchema5
          : validationSchema6
        : formContext?.singleQuotesData?.insuranceCompany ===
          "ICICI Lombard General Insurance Co. Ltd"
        ? policyType === "renew"
          ? validationSchema7
          : validationSchema8
        : policyType === "renew"
        ? validationSchema
        : validationSchema2,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      console.log("hiiiiiii");
      setSubmitting(true);
      if (!isLastStep) {
        if (isFirstStep) {
          if (
            formContext?.singleQuotesData?.insuranceCompany ===
              "Reliance General Insurance Co. Ltd" ||
            formContext?.singleQuotesData?.insuranceCompany ===
              "Future Generali India Insurance Co. Ltd" ||
            formContext?.singleQuotesData?.insuranceCompany ===
              "Bajaj Allianz General Insurance Co. Ltd" ||
            formContext?.singleQuotesData?.insuranceCompany ===
              "HDFC ERGO General Insurance Company" ||
            formContext?.singleQuotesData?.insuranceCompany ===
              "ICICI Lombard General Insurance Co. Ltd"
          ) {
            switch (formContext?.singleQuotesData?.insuranceCompany) {
              case "Reliance General Insurance Co. Ltd":
                kycveryfy();
                break;
              case "Future Generali India Insurance Co. Ltd":
                futureKycApi(values);
                break;
              case "Bajaj Allianz General Insurance Co. Ltd":
                bajajKycVerifyAPI(
                  formContext?.singleQuotesData?.transactionid,
                  values
                );
                break;
              case "HDFC ERGO General Insurance Company":
                HdfcKycVerify(
                  formContext?.singleQuotesData?.transactionid,
                  values
                );
                break;
              case "ICICI Lombard General Insurance Co. Ltd":
                iciciKyc(values);
                break;

              default:
              // code block
            }
          } else {
            return next();
          }
        } else if (
          isSecondLast &&
          formContext.singleQuotesData?.insuranceCompany ===
            "ICICI Lombard General Insurance Co. Ltd"
        ) {
          apiCalls(values, props);
          if (!sessionStorage.getItem("isAdmin")) {
            CrmMethod();
          }
          next();
        } else {
          return next();
        }
      }
      if (isLastStep) {
        if (formContext?.proposalData) {
          formContext.setloaderStatus(true);
          if (
            formContext.singleQuotesData.insuranceCompany ===
            "GO DIGIT General Insurance CO. LTD"
          ) {
            api
              .godigitTokenApi()
              .then((res) => {
                breakInStatus(res?.data?.data?.access_token);
              })
              .catch((res) => {
                formContext.setloaderStatus(false);
              });
          } else {
            paymentApicalls(
              "",
              formContext?.proposalData?.bikeProposalResponse ||
                formContext?.proposalData?.carProposalResponse
            );
          }
        } else {
          if (
            formContext?.singleQuotesData.insuranceCompany ===
            "ICICI Lombard General Insurance Co. Ltd"
          ) {
            // const value = iciciValueForPayment;
            // const data = iciciDataForPayment;
            // // formContext.setloaderStatus(false);
            // paymentApicalls(value, data);
          } else {
            apiCalls(values, props);
            if (!sessionStorage.getItem("isAdmin")) {
              CrmMethod();
            }
          }
        }
      }
    },
  });
  console.log("validation error", formik.errors);
  console.log("values ", formik.values);
  const getFormContext = async () => {
    if (!formContext?.singleQuotesData) {
      props = JSON.parse(localStorage.getItem("prop"));
      formContext.setvahanData(props.vahanData);
      formContext.setsingleQuotesData(props.singleQuotesData);
      formContext.setcarbikeformikValues(props.carbikeformikValues);
      formContext.setcreatequotesresult(props.createquotesresult);
      formContext.setquotesPageFormikData(props.quotesPageFormikData);
      formContext.setPolicyDates(props.PolicyDates);
      console.log(
        "singleQuotesData,props",
        formContext?.singleQuotesData,
        props
      );
      const uniqueId = localStorage.getItem("quoteId");
      formik.setFieldValue("isRegisteredAddressSame", false);
      const kycData = JSON.parse(localStorage.getItem("nomineeDetails"));
      console.log("kycData", kycData);
      // window.location.href
      formik.setFieldValue("kycNominePanNumber", kycData?.kycNominePanNumber);
      formik.setFieldValue(
        "kycNomineAadharNumber",
        kycData?.kycNomineAadharNumber
      );
      formik.setFieldValue("kycNomineDob", kycData?.kycNomineDob);
      formik.setFieldValue("kycNomineGender", kycData?.kycNomineGender);
      formik.setFieldValue("kycNomineFullName", kycData?.kycNomineFullName);
      console.log("before kyc call")
      if (kycData) {
        formContext.setFgLoader(true)
        console.log("in if kycData")
        formContext.setloaderStatus(true);
       // formContext.setSpinner(true);
        formContext.setSpinnerDisapear(true);
        const proposal_id = localStorage.getItem("fgProposalId");
        await api
          .getKycStatusFuture(proposal_id)
          .then((res) => {
            if (
              res?.data?.data?.Final_Status === "1" ||
              res?.data?.data?.Final_Status === "3"
            ) {
              formContext.setkycApiRes(res.data.data.result);
              formContext.setcarrierName("future");
            }
            console.log("res", res);
          })
          .catch((err) => {
            console.log("err", err);
          });

        next();
        formContext.setFgLoader(false)
        formContext.setloaderStatus(false);
        //formContext.setSpinner(false);
        formContext.setSpinnerDisapear(false);
        window.location.hash = "#ownercontactform";
      }
      api
        .shareQuotes(uniqueId)
        .then((data) => {
          console.log("data", data);
          const jsonData = JSON.parse(data.data);
          const businessLineId = jsonData?.data.businessLineId;
          var nestedDataVeh;
          var nestedData;
          var nestedDate;
          if (businessLineId === 2) {
            nestedData = jsonData?.data?.bikeQuoteRequest?.quoteParam;
            localStorage.setItem("policyType", nestedData?.planType);
            nestedDataVeh = jsonData?.data?.bikeQuoteRequest?.vehicleInfo;
            nestedDate = jsonData?.data?.bikeQuoteRequest;
          } else {
            nestedData = jsonData?.data?.carQuoteRequest?.quoteParam;
            nestedDataVeh = jsonData?.data?.carQuoteRequest?.vehicleInfo;
            nestedDate = jsonData?.data?.carQuoteRequest;
          }
          var policyTypeArray = nestedData?.planType?.split("-");
          var policyType;
          var policyTerm;

          if (policyTypeArray?.length === 3) {
            policyType = policyTypeArray[0] + "-" + policyTypeArray[1];
            policyTerm = policyTypeArray[2];
          } else {
            policyType = policyTypeArray[0];
            policyTerm = policyTypeArray[1];
            if (businessLineId === 3 && policyTypeArray?.length === 2) {
              policyType = policyTypeArray[0] + "-" + policyTypeArray[1];
            } else if (businessLineId === 3 && policyTypeArray?.length === 1) {
              policyType = policyTypeArray[0];
            }
          }

          const objForCarBike = {
            noClaimBonus: nestedData?.ncb,
            insuranceFor: nestedData?.policyType,
            addOnCover: nestedData?.riders,
            lastName: nestedData?.lastName,
            insuranceClaim: nestedDataVeh?.previousClaim,
            previousPolicy:
              nestedData?.policyType === "new"
                ? valuesForExpDate.todayConvertedValue
                : nestedDataVeh?.PreviousPolicyExpiryDate,
            firstName: nestedData?.firstName,
            email: nestedData?.email,
            phoneNumber: nestedData?.phoneNumber,
            currentStepIndex: "1",
            policyType: policyType,
            policyTerms: policyTerm,
            make: nestedDataVeh.make,
            model: nestedDataVeh.model,
            varient: nestedDataVeh.variant,
            registeredRto: nestedDataVeh?.registrationPlace
              ? nestedDataVeh?.registrationPlace
              : nestedDataVeh?.RTOCode,
            registrationYear: convertDate(nestedDataVeh?.dateOfRegistration, 2),
            formtype: jsonData?.data.businessLineId === 2 ? "bike" : "car",
            fuel: nestedDataVeh.fuel ? nestedDataVeh.fuel : "petrol",
          };

          const objForQuotesPage = {
            noClaimBonus: nestedData?.ncb,
            insuranceFor: nestedData?.policyType,
            addOnCover: nestedData?.riders,
            insuranceClaim: nestedDataVeh?.previousClaim,
            policyType: policyType,
            policyTerms: policyTerm,
            make: nestedDataVeh.make,
            model: nestedDataVeh.model,
            varient: nestedDataVeh.variant,
            registeredRto: nestedDataVeh?.registrationPlace
              ? nestedDataVeh?.registrationPlace
              : nestedDataVeh.RTOCode,
            registrationYear: convertDate(nestedDataVeh?.dateOfRegistration, 2),
            expiryDate: convertDate(nestedDataVeh?.PreviousPolicyExpiryDate, 2),
            fuel: nestedDataVeh.fuel ? nestedDataVeh.fuel : "petrol",
            idv: nestedDataVeh?.IDV,
            ownedBy:
              nestedData?.ownedBy === "Individual"
                ? "Individual"
                : "Organization",
            zeroDep: "",
            zeroDepPlan: "",
            personalCover: 100000,
            personalCoverFlag: "",
            personalAccidentCover: false,
            driverAccidentCover: "",
            driverAccidentCoverAmount: 10000,
            lpgCngKit: "",
            lpgCngKitAmount: "",
            accessories: "",
            electricalAccessories: "",
            electricalAccessoriesAmount: 10000,
            nonElectricalAccessories: "",
            nonElectricalAccessoriesAmount: 10000,
            roadSideAssistance: "",
            roadSideAssistancePlan: "RSA-199",
            ncbProtection: "",
            ncbPlan: "plan1",
            engineProtector: "",
            tyreProtection: "",
            tyreDetails: "",
            tyreProtectionAmount: "",
            keyProtection: "",
            keyProtectionAmount: "",
            consumablesCover: "",
            baggageCover: "",
            invoiceCover: "",
            transportHotelExpenses: "",
            transportHotelExpensesAmount: "",
            employeeAccidentCover: "",
            employeeAccidentCoversAmount: 1,
            voluntaryDeductableCover: "",
            voluntaryDeductableCoverAmount: 1000,
            emeCover: "",
            emeCoverNumber: "1",
            emeCoverAmount:
              formContext.carbikeformikValues?.formtype === "bike"
                ? "EME-99"
                : "EME-Standard",
            liabilityToPaidDriver: "",
            liabilityToPaidDriverAmount: 1,
            batteryProtectCover: "",
            smartSaverPlus: false,
            smartSaverPlusPlan: "smartSaverPlus",
            smartSaver: false,
            smartSaverPlan: "T+2*500",
            garageCash: "",
            garageCashPlan: "garageCashPlan",
            emiProtect: "",
            emiCount: "1",
            emiAmount: "",
            IsTyreProtect: "no",
            IsEngineProtect: "no",
            IsZeroDepriciation: "no",
            IsConsumable: "no",
            IsRTI: "no",
            validLicence: false,
            tpPolicyStartDate: nestedDataVeh?.TPPolicyStartDate
              ? nestedDataVeh.TPPolicyStartDate === "undefined-undefined-"
                ? null
                : nestedDataVeh?.TPPolicyStartDate
              : null,
            tpPolicyEndtDate: nestedDataVeh?.TPPolicyExpiryDate
              ? nestedDataVeh?.TPPolicyExpiryDate === "undefined-undefined-"
                ? null
                : nestedDataVeh?.TPPolicyExpiryDate
              : null,
            isvehNumberMissing: true,
            idvSelectedValue: nestedDataVeh?.IDV,
            formtype: jsonData?.data.businessLineId === 2 ? "bike" : "car",
            alternatePolicyExpDateFuture: "",
            existingPAPolicyNo: "",
            existingPASumInsured: 1500000,
            insurerName: "",
            validLicense: "",
          };

          const valuesForCarBike = {
            ...bikeFormikIntialData,
            ...objForCarBike,
          };
          const valuesForQuotes = {
            ...initialDataQuotesPage,
            ...objForQuotesPage,
          };

          console.log("valuesForQuotes", valuesForQuotes);
          formik.setValues(valuesForQuotes);
          formContext.setquotesPageFormikData(valuesForQuotes);
          formContext.setcarbikeformikValues(valuesForCarBike);
          const filteredQuotes = jsonData?.data?.carQuoteResponse?.filter(
            (quote) =>
              quote.insuranceCompany ===
              "Future Generali India Insurance Co. Ltd"
          );
          formContext.setsingleQuotesData(filteredQuotes[0]);
          // formContext.setsingleQuotesData(valuesForQuotes)
          // saving dates and date which are required while creating quotes and on propsal page respectively
          const policydates = {
            QUOTE_ID: jsonData?.data?.QUOTE_ID,
            policyDate: {
              policyStartDate:
                nestedDate?.systemPolicyStartDate?.sysPolicyStartDate,
            },
          };
          formContext.setPolicyDates(policydates);

          // setting up rider values
          nestedData?.riders?.forEach((element) => {
            // riders for bike
            if (businessLineId === 2) {
              switch (element.riderId) {
                case 10:
                  formik.setFieldValue("personalAccidentCover", true);
                  break;
                case 11:
                  formik.setFieldValue("zeroDep", true);
                  formik.setFieldValue("zeroDepPlan", element?.riderAmount);

                  break;
                case 28:
                  formik.setFieldValue("personalCoverFlag", true);
                  formik.setFieldValue("personalCover", element?.riderAmount);
                  break;

                case 12:
                  formik.setFieldValue("ncbProtection", true);
                  formik.setFieldValue("ncbPlan", element?.riderAmount);
                  break;
                case 14:
                  formik.setFieldValue("roadSideAssistance", true);
                  formik.setFieldValue(
                    "roadSideAssistancePlan",
                    element?.riderAmount
                  );
                  break;
                case 15:
                  formik.setFieldValue("invoiceCover", true);
                  break;
                case 13:
                  formik.setFieldValue("engineProtector", true);
                  break;
                case 25:
                  formik.setFieldValue("electricalAccessories", true);
                  formik.setFieldValue(
                    "electricalAccessoriesAmount",
                    element?.riderAmount
                  );
                  formik.setFieldValue("accessories", true);
                  break;
                case 30:
                  formik.setFieldValue("nonElectricalAccessories", true);
                  formik.setFieldValue(
                    "nonElectricalAccessoriesAmount",
                    element?.riderAmount
                  );
                  formik.setFieldValue("accessories", true);
                  break;
                case 50:
                  formik.setFieldValue("consumablesCover", true);
                  break;
                case 19:
                  formik.setFieldValue("employeeAccidentCover", true);
                  formik.setFieldValue(
                    "employeeAccidentCoversAmount",
                    element.riderAmount
                  );
                  break;
                case 23:
                  formik.setFieldValue("voluntaryDeductableCover", true);
                  formik.setFieldValue(
                    "voluntaryDeductableCoverAmount",
                    element?.riderAmount
                  );
                  break;
                case 24:
                  formik.setFieldValue("tyreSecure", true);
                  break;
                case 22:
                  formik.setFieldValue("batteryProtectCover", true);
                  break;
                case 16:
                  formik.setFieldValue("emeCover", true);
                  formik.setFieldValue("emeCoverNumber", element?.riderNumber);
                  formik.setFieldValue("emeCoverAmount", element?.riderAmount);
                  break;
                case 17:
                  formik.setFieldValue("keyProtection", true);
                  formik.setFieldValue(
                    "keyProtectionAmount",
                    element?.riderAmount
                  );
                  break;
                case 44:
                  formik.setFieldValue("liabilityToPaidDriver", true);
                  formik.setFieldValue(
                    "liabilityToPaidDriverAmount",
                    element?.riderAmount
                  );
                  break;

                case 51:
                  formik.setFieldValue("existingPersonalCoverFlag", true);
                  break;

                case 52:
                  formik.setFieldValue("validLicence", true);
                  break;

                default:
                  break;
              }
            } else {
              // riders for cars
              switch (element.riderId) {
                case 11:
                  formik.setFieldValue("personalAccidentCover", true);
                  break;
                case 6:
                  formik.setFieldValue("zeroDep", true);
                  formik.setFieldValue("zeroDepPlan", element?.riderAmount);
                  formik.setFieldValue("IsZeroDepriciation", element?.riderPYP);
                  break;
                case 21:
                  formik.setFieldValue("personalCoverFlag", true);
                  formik.setFieldValue("personalCover", element?.riderAmount);
                  break;

                case 20:
                  formik.setFieldValue("driverAccidentCover", true);
                  formik.setFieldValue(
                    "driverAccidentCoverAmount",
                    element?.riderAmount
                  );
                  break;

                case 35:
                  formik.setFieldValue("lpgCngKit", true);
                  formik.setFieldValue("lpgCngKitAmount", element?.riderAmount);
                  break;

                case 25:
                  formik.setFieldValue("electricalAccessories", true);
                  formik.setFieldValue(
                    "electricalAccessoriesAmount",
                    element?.riderAmount
                  );
                  formik.setFieldValue("accessories", true);
                  break;

                case 30:
                  formik.setFieldValue("nonElectricalAccessories", true);
                  formik.setFieldValue(
                    "nonElectricalAccessoriesAmount",
                    element?.riderAmount
                  );
                  formik.setFieldValue("accessories", true);
                  break;

                case 9:
                  formik.setFieldValue("roadSideAssistance", true);
                  formik.setFieldValue(
                    "roadSideAssistancePlan",
                    element?.riderAmount
                  );
                  break;

                case 7:
                  formik.setFieldValue("ncbProtection", true);
                  formik.setFieldValue("ncbPlan", element?.riderAmount);
                  break;

                case 8:
                  formik.setFieldValue("engineProtector", true);
                  formik.setFieldValue("IsEngineProtect", element?.riderPYP);
                  break;

                case 37:
                  formik.setFieldValue("tyreProtection", true);
                  formik.setFieldValue(
                    "tyreProtectionAmount",
                    element?.riderAmount
                  );
                  formik.setFieldValue("IsTyreProtect", element?.riderPYP);
                  break;
                case 23:
                  formik.setFieldValue("keyProtection", true);
                  formik.setFieldValue(
                    "keyProtectionAmount",
                    element?.riderAmount
                  );
                  break;

                case 10:
                  formik.setFieldValue("invoiceCover", true);
                  formik.setFieldValue("IsRTI", element?.riderPYP);
                  break;

                case 41:
                  formik.setFieldValue("transportHotelExpenses", true);
                  formik.setFieldValue(
                    "transportHotelExpensesAmount",
                    element?.riderAmount
                  );
                  break;
                case 24:
                  formik.setFieldValue("consumablesCover", true);
                  formik.setFieldValue("IsConsumable", element?.riderPYP);
                  break;
                case 40:
                  formik.setFieldValue("baggageCover", true);
                  break;
                case 39:
                  formik.setFieldValue("employeeAccidentCover", true);
                  formik.setFieldValue(
                    "employeeAccidentCoversAmount",
                    element.riderAmount
                  );
                  break;
                case 45:
                  formik.setFieldValue("voluntaryDeductableCover", true);
                  formik.setFieldValue(
                    "voluntaryDeductableCoverAmount",
                    element?.riderAmount
                  );
                  break;
                case 42:
                  formik.setFieldValue("batteryProtectCover", true);
                  break;
                case 43:
                  formik.setFieldValue("emeCover", true);
                  formik.setFieldValue("emeCoverNumber", element?.riderNumber);
                  formik.setFieldValue("emeCoverAmount", element?.riderAmount);
                  break;
                case 44:
                  formik.setFieldValue("liabilityToPaidDriver", true);
                  formik.setFieldValue(
                    "liabilityToPaidDriverAmount",
                    element?.riderAmount
                  );
                  break;
                case 47:
                  formik.setFieldValue("smartSaverPlus", true);
                  formik.setFieldValue(
                    "smartSaverPlusPlan",
                    element?.riderPlan
                  );
                  break;
                case 46:
                  formik.setFieldValue("smartSaver", true);
                  formik.setFieldValue("smartSaverPlan", element?.riderPlan);
                  break;
                case 51:
                  formik.setFieldValue("existingPersonalCoverFlag", true);
                  break;
                case 54:
                  formik.setFieldValue("validLicence", true);
                  break;
                case 53:
                  formik.setFieldValue("garageCash", true);
                  formik.setFieldValue("garageCashPlan", element?.riderPlan);

                  break;
                case 52:
                  formik.setFieldValue("emiProtect", true);
                  formik.setFieldValue("emiCount", element?.riderNumber);
                  formik.setFieldValue("emiAmount", element?.riderAmount);
                  break;
                default:
                  break;
              }
            }
          });

          // idv selected value
          setidvSelectedValue(nestedDataVeh?.IDV);
          // to fix personalAccCover checkbox issue(by default selected)
          const singleRider = nestedData?.riders.find(
            (data) => data.riderName === "Personal Accident cover"
          );
          const singleRiderForLpg = nestedData?.riders.find(
            (data) => data.riderName === "LPG-CNG Kit"
          );
          goTo(1);
        })
        .catch((err) => {})
        .finally((fin) => {
          formContext.setloaderStatus(false);
        });
    }

    // if (!formContext?.singleQuotesData) {
    //   console.log("Fetching data...");

    //   try {
    //     const response = await axios.get(
    //       "https://json-db.policies365.com/formContextObj"
    //     );

    //     console.log("API Response:", response.data);

    //     if (response?.data?.length) {
    //       // Filter the data based on QuotationNo
    //       const filteredRecord = response.data.find((item) => {
    //         console.log(
    //           "Checking:",
    //           item?.singleQuotesData?.QuotationNo,
    //           "vs",
    //           uniqueId
    //         );
    //         return (
    //           String(item?.singleQuotesData?.QuotationNo).trim() ===
    //           String(uniqueId).trim()
    //         );
    //       });

    //       console.log("Filtered Record:", filteredRecord);

    //       if (filteredRecord) {
    //         formContext.setquotesPageFormikData(
    //           filteredRecord.quotesPageFormikData
    //         );
    //         formContext.setsingleQuotesData(filteredRecord.singleQuotesData);
    //         formik.setFieldValue(
    //           "kycNominePanNumber",
    //           filteredRecord?.kycData?.kycNominePanNumber
    //         );
    //         formik.setFieldValue(
    //           "kycNomineAadharNumber",
    //           filteredRecord?.kycData?.kycNomineAadharNumber
    //         );
    //         formik.setFieldValue(
    //           "kycNomineDob",
    //           filteredRecord?.kycData?.kycNomineDob
    //         );
    //         formik.setFieldValue(
    //           "kycNomineGender",
    //           filteredRecord?.kycData?.kycNomineGender
    //         );
    //         formik.setFieldValue(
    //           "kycNomineFullName",
    //           filteredRecord?.kycData?.kycNomineFullName
    //         );
    //         formContext.setproposalData(filteredRecord.proposalData);
    //         formContext?.setcrmLoggedUser(filteredRecord.crmLoggedUser);
    //         formContext?.setcarbikeformikValues(
    //           filteredRecord?.carbikeformikValues
    //         );
    //         formContext?.setPolicyDates(filteredRecord?.PolicyDates);
    //         formContext?.setKycApiRes(filteredRecord?.kycApiRes);
    //         formContext?.setloaderStatus(filteredRecord?.loaderStatus);
    //         formContext?.setSpinner(filteredRecord?.spinner);
    //         formContext?.setSpinnerDisapear(filteredRecord?.spinnerDisapear);
    //         // Delete the specific record
    //         goTo(1);
    //         // window.location.href = "https://uat1.policies365.com/proposal#ownercontactform";
    //         // await axios.delete(
    //         //   `https://json-db.policies365.com/formContextObj/${filteredRecord.id}`
    //         // );
    //         console.log("Record deleted successfully:", filteredRecord.id);
    //       } else {
    //         console.log("No matching record found.");
    //       }
    //     }
    //   } catch (error) {
    //     console.error("Error fetching form context:", error);
    //   }
    // }
  };
  useEffect(() => {
    if (!formContext?.singleQuotesData) {
      getFormContext();
    }
  }, []);

  const removeCircularReferences = (obj, seen = new WeakSet()) => {
    if (typeof obj !== "object" || obj === null) {
      return obj;
    }

    if (seen.has(obj)) {
      return undefined; // Remove circular reference
    }

    seen.add(obj);

    if (Array.isArray(obj)) {
      return obj.map((item) => removeCircularReferences(item, seen));
    }

    return Object.keys(obj).reduce((acc, key) => {
      acc[key] = removeCircularReferences(obj[key], seen);
      return acc;
    }, {});
  };

  const breakInStatus = (token) => {
    const proposalData =
      formContext?.proposalData?.bikeProposalResponse ||
      formContext?.proposalData?.carProposalResponse;

    const handleCommonErrorMessage = (data) => {
      commonErrorMessage(
        <>
          {`ProposalId: ${proposalData?.proposalId}`}
          <br />
          {`PolicyNumber: ${data?.policyNumber}`}
          <br />
          <h6
            style={{ color: "red" }}
          >{`Policy Status - ${data.policyStatus}`}</h6>
        </>
      );
    };

    const handlePayment = (
      token,
      applicationId,
      proposalId,
      businessLineId
    ) => {
      api
        .godigitPayment(token, applicationId, proposalId, businessLineId)
        .then((res) => {
          formContext.setloaderStatus(false);
          if (res.data?.data?.paymentLink) {
            window.open(
              res.data?.data?.paymentLink,
              "_self",
              "toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=100,width=1100,height=1100"
            );
          } else {
            formContext.notifyError("Error");
          }
        })
        .catch((err) => {})
        .finally(() => {
          formContext.setloaderStatus(false);
        });
    };

    api
      .godigitBreakInstaus(token, proposalData?.policyNumber)
      .then((data) => {
        const kycStatus = data.data?.data?.kycStatus?.policyStatus;
        const policyStatus = data.data?.data?.policyStatus;

        formContext.setloaderStatus(false);
        if (kycStatus) {
          // if (kycStatus !== "INCOMPLETE" || kycStatus !== "PRE_INSPECTION_APPROVED") {
          // handleCommonErrorMessage(data.data.data);
          // } else {
          handlePayment(
            token,
            data.data?.data?.applicationId,
            proposalData?.proposalId,
            proposalData?.businessLineId
          );
          // }
        } else {
          // if (policyStatus !== "PRE_INSPECTION_APPROVED") {
          // handleCommonErrorMessage(data.data.data);
          // } else {
          handlePayment(
            token,
            data.data?.data?.applicationId,
            proposalData?.proposalId,
            proposalData?.businessLineId
          );
          // }
        }
      })
      .catch((res) => {
        formContext.setloaderStatus(false);
      });
  };

  const CrmMethod = () => {
    setTimeout(() => {
      if (!formContext.crmLoggedUser) {
        api
          .crmLogin()
          .then((res) => {
            formContext.setcrmToken(res.data.token);
            setLoggeduser(res.data.token);
          })
          .catch((err) => {
            formContext.setcrmToken("");
          });
      } else {
        // call crm api
        createCrmLead(1);
      }
    }, 2000);
  };

  const crmStateUpdate = async (propsalData) => {
    objForUpdateStage.customer_name =
      formContext.carbikeformikValues?.firstName +
      " " +
      formContext.carbikeformikValues?.lastName;
    objForUpdateStage.mobile_number =
      formContext.carbikeformikValues?.phoneNumber;
    objForUpdateStage.RTO_no = formContext.carbikeformikValues?.registeredRto;
    objForUpdateStage.module = "motor";
    objForUpdateStage.quote_stage = "completed";
    objForUpdateStage.quote_link = `${process.env.REACT_APP_FRONTEND_SERVER}/quotes?quoteID=${formContext?.PolicyDates?.QUOTE_ID}`;

    if (kycFailUrl) {
      objForUpdateStage.KYC_link = kycFailUrl;
      objForUpdateStage.KYC_Status = "Not completed";
    }

    if (formContext.kycApiRes) {
      objForUpdateStage.KYC_Status = "completed";
    } else {
      if (
        formContext?.singleQuotesData?.insuranceCompany ===
          "Future Generali India Insurance Co. Ltd" ||
        formContext?.singleQuotesData?.insuranceCompany ===
          "Bajaj Allianz General Insurance Co. Ltd" ||
        formContext?.singleQuotesData?.insuranceCompany ===
          "HDFC ERGO General Insurance Company" ||
        formContext?.singleQuotesData?.insuranceCompany ===
          "ICICI Lombard General Insurance Co. Ltd"
      ) {
        objForUpdateStage.KYC_Status = "not completed";
        setkycStatus("not completed");
      }
    }
    if (objForUpdateStage?.KYC_link) {
      objForUpdateStage.KYC_Status = "Not completed";
    }

    objForUpdateStage.proposal_stage = "completed";
    objForUpdateStage.proposal_link = `${
      process.env.REACT_APP_FRONTEND_SERVER
    }/myaccount?proposalId=${
      propsalData?.proposalId
    }&mob=${localStorage.getItem("ownerPhoneNumber")}`;

    objForUpdateStage.policy_stage = "Not completed";
    objForUpdateStage.policy_link = "";
    if (kycStatus) {
      objForUpdateStage.KYC_Status = kycStatus;
    }
  };

  const setLoggeduser = (token) => {
    api
      .crmGetLoginUser(token)
      .then((res) => {
        formContext.setcrmLoggedUser(res?.data?.output?.motor?.[0]);

        createCrmLead(2);
      })
      .catch((err) => {
        formContext.setcrmLoggedUser();
      });
  };
  const createCrmLead = async (type) => {
    objForCreateLead.customer_name =
      formContext.carbikeformikValues?.firstName +
      " " +
      formContext.carbikeformikValues?.lastName;
    objForCreateLead.mobile_number =
      formContext.carbikeformikValues?.phoneNumber;
    objForCreateLead.email_id = formContext.carbikeformikValues?.email;
    objForCreateLead.RTO_no = formContext.quotesPageFormikData?.registeredRto;

    objForCreateLead.make = formContext.quotesPageFormikData?.make;
    objForCreateLead.model = formContext.quotesPageFormikData?.model;
    objForCreateLead.varient = formContext.quotesPageFormikData?.varient;
    objForCreateLead.registration_date = convertDate(
      formContext.quotesPageFormikData?.registrationYear,
      1
    );
    objForCreateLead.Policy_Exp_date = convertDate(
      formContext.quotesPageFormikData?.expiryDate,
      1
    );

    objForCreateLead.residential_address = formik.values?.ownerAddress;
    objForCreateLead.pan_number = formik.values?.kycNominePanNumber;
    objForCreateLead.date_of_birth = convertDate(
      formik.values?.kycNomineDob,
      1
    );

    objForCreateLead.chassis_no = formik.values?.chassisNumber;
    objForCreateLead.engine_no = formik.values?.engineNumber;
    // objForCreateLead.policy_no = formik.values?.varient
    objForCreateLead.insurance_company =
      formContext.singleQuotesData?.insuranceCompany;

    objForCreateLead.caller_name = formContext?.crmLoggedUser?.username
      ? formContext?.crmLoggedUser?.username
      : "NIB048";

    const crmToken = await formContext.setCrmTokenMethod();
    // condition to decide whether create lead or update lead
    if (type === 2) {
      api
        .crmCreateLead(crmToken?.data?.token, objForCreateLead)
        .then((res) => {})
        .catch((err) => {});
    } else {
      api
        .crmLeadUpdate(crmToken?.data?.token, objForCreateLead)
        .then((res) => {})
        .catch((err) => {});
    }
  };

  useEffect(() => {
    if (props?.id) {
      const data = formContext?.proposalData;
      const dataNestd =
        formContext?.proposalData?.proposalRequest?.proposerDetails;
      const dataNestd3rd =
        formContext?.proposalData?.proposalRequest?.nominationDetails;
      const dataNestd4th =
        formContext?.proposalData?.proposalRequest?.proposerDetails
          ?.communicationAddress;
      const dataNestd5th =
        formContext?.proposalData?.proposalRequest?.insuranceDetails;
      const dataNestd6th =
        formContext?.proposalData?.proposalRequest?.vehicleDetails;

      formik.setFieldValue(
        "kycNominePanNumber",
        dataNestd?.panNumber ? dataNestd?.panNumber : ""
      );
      formik.setFieldValue(
        "kycNomineDob",
        dataNestd?.dateOfBirth
          ? convertDate(
              dataNestd?.dateOfBirth,
              dataNestd?.dateOfBirth.includes("/") ? 2 : 3
            )
          : ""
      );
      formik.setFieldValue("kycNomineGender", data?.gender ? data?.gender : "");
      //2
      formik.setFieldValue(
        "ownerPrefix",
        dataNestd?.salutation ? dataNestd?.salutation : ""
      );
      formik.setFieldValue(
        "ownerFirstName",
        dataNestd?.firstName ? dataNestd?.firstName : ""
      );
      formik.setFieldValue(
        "ownerLastName",
        dataNestd?.lastName ? dataNestd?.lastName : ""
      );
      formik.setFieldValue(
        "ownerEmail",
        dataNestd?.emailId ? dataNestd?.emailId : ""
      );
      formik.setFieldValue(
        "ownerPhoneNumber",
        dataNestd?.mobileNumber ? dataNestd?.mobileNumber : ""
      );
      formik.setFieldValue(
        "ownerDob",
        dataNestd?.dateOfBirth ? convertDate(dataNestd?.dateOfBirth, 3) : ""
      );
      formik.setFieldValue(
        "kycNomineGender",
        dataNestd?.gender ? dataNestd?.gender : ""
      );
      formik.setFieldValue(
        "ownerMaritialStus",
        dataNestd?.maritalStatus ? dataNestd?.maritalStatus : ""
      );
      formik.setFieldValue(
        "ownerGstin",
        dataNestd?.GSTIN ? dataNestd?.GSTIN : ""
      );
      //3
      formik.setFieldValue(
        "nomineFirstName",
        dataNestd3rd?.nomFirstName ? dataNestd3rd?.nomFirstName : ""
      );
      formik.setFieldValue(
        "nomineLastName",
        dataNestd3rd?.nomLastName ? dataNestd3rd?.nomLastName : ""
      );
      formik.setFieldValue(
        "nomineRelation",
        dataNestd3rd?.nominationRelation ? dataNestd3rd?.nominationRelation : ""
      );
      formik.setFieldValue(
        "nomineDob",
        dataNestd3rd?.nomDateOfBirth
          ? convertDate(dataNestd3rd?.nomDateOfBirth, 3)
          : ""
      );
      //4
      formik.setFieldValue(
        "ownerPincode",
        dataNestd4th?.comPincode ? dataNestd4th?.comPincode : ""
      );
      formik.setFieldValue(
        "ownerFlatNumber",
        dataNestd4th?.comDoorNo ? dataNestd4th?.comDoorNo : ""
      );
      formik.setFieldValue(
        "ownerAddress",
        dataNestd4th?.comArea ? dataNestd4th?.comArea : ""
      );
      formik.setFieldValue(
        "ownerLocation",
        dataNestd4th?.comDistrict ? dataNestd4th?.comDistrict : ""
      );
      formik.setFieldValue(
        "ownerState",
        dataNestd4th?.comState ? dataNestd4th?.comState : ""
      );
      formik.setFieldValue(
        "ownerCity",
        dataNestd4th?.comCity ? dataNestd4th?.comCity : ""
      );
      formik.setFieldValue("isRegisteredAddressSame", true);
      //5
      formik.setFieldValue(
        "policyNumber",
        dataNestd5th?.policyNumber ? dataNestd5th?.policyNumber : ""
      );
      setTimeout(() => {
        // formik.setFieldValue("policyInsuranceName", dataNestd5th?.insurerName ? dataNestd5th?.insurerName : "")
      }, 4000);
      formik.setFieldValue(
        "PolicyEndtDate",
        data?.policyExpiryDate ? convertDate(data?.policyExpiryDate, 2) : ""
      );
      formik.setFieldValue(
        "policyInsuranceNamePrevious",
        "comprehensive insurance"
      );
      formik.setFieldValue("policyPreviousType", "new");
      formik.setFieldValue(
        "policyInsuranceId",
        dataNestd5th?.insurerId ? dataNestd5th?.insurerId : ""
      );
      formik.setFieldValue(
        "tpPolicyInsuranceName",
        dataNestd5th?.TPPolicyInsurer ? dataNestd5th?.TPPolicyInsurer : ""
      );
      formik.setFieldValue(
        "tpPolicyNumber",
        dataNestd5th?.TPPolicyNumber ? dataNestd5th?.TPPolicyNumber : ""
      );
      //6
      const currentDate = new Date();
      const day = ("0" + currentDate.getDate()).slice(-2);
      const month = ("0" + (currentDate.getMonth() + 1)).slice(-2);
      formik.setFieldValue(
        "vehicalRegistionDate",
        dataNestd6th?.registrationDate
          ? convertDate(dataNestd6th?.registrationDate, 2)
          : ""
      );
      formik.setFieldValue(
        "vehicalManufaDate",
        dataNestd6th?.manufacturerYear ? dataNestd6th?.manufacturerYear : ""
      );
      formik.setFieldValue(
        "vehicalPurchaseLoan",
        dataNestd6th?.purchasedLoan ? dataNestd6th?.purchasedLoan : ""
      );
      formik.setFieldValue(
        "registeredRto",
        dataNestd6th?.registrationNumber ? dataNestd6th?.registrationNumber : ""
      );
      formik.setFieldValue("vehicalTermAccept", true);
      formik.setFieldValue("puc", "yes");
      formik.setFieldValue(
        "vehicalFinancierName",
        dataNestd6th?.financeInstitution ? dataNestd6th?.financeInstitution : ""
      );
    }
  }, [props.id]);

  // kyc  first page of form
  const kycveryfy = async () => {
    formContext.setloaderStatus(true);
    api
      .kycVeryfy(formik.values)
      .then((data) => {
        setkycFailUrl(data?.data?.data?.Endpoint_2_URL);
        if (data.data.data.message === "OK") {
          formik.setFieldValue(
            "kycFailedData",
            data.data?.data?.kyc_data?.CKYC?.result?.PERSONAL_DETAILS?.CKYC_NO
          );
          formContext.setkycApiRes(data.data.data);
          formContext.setcarrierName("reliance");
          clearFormikForKyc();
          return next();
        }
        // formContext.notifyError(data.data.data.message)
        if (data.data?.data?.KYC_Verified === "false") {
          formik.setFieldValue("kycFailedData", data.data?.data?.Unique_Id);
          openIframePopUp(data.data.data, 1);
        }
        if (
          data.data?.data?.KYC_Verified === "true" &&
          data.data.data.message === null &&
          data.data?.data?.kyc_data?.CKYC?.result?.PERSONAL_DETAILS?.CKYC_NO
        ) {
          formik.setFieldValue(
            "kycFailedData",
            data.data?.data?.kyc_data?.CKYC?.result?.PERSONAL_DETAILS?.CKYC_NO
          );
          formContext.setkycApiRes(data.data.data);
          formContext.setcarrierName("reliance");
          clearFormikForKyc();
          return next();
        }
      })
      .catch((err) => {})
      .finally(() => {
        formContext.setloaderStatus(false);
      });
  };
  const futureKycApi = (values) => {
    formContext.setloaderStatus(true);
    api
      .getTokenForFutureApi()
      .then((data) => {
        kycveryfyFuture(data, values);
      })
      .catch((err) => {
        formContext.setloaderStatus(false);
        next();
      });
  };
  const kycveryfyFuture = (data, values) => {
    api
      .getKycFutureApi(data?.data?.data[0]?.ProposalNo, values)
      .then((data) => {
        if (data?.data?.data?.url) {
          setkycFailUrl(data?.data?.data?.url);
          formik.setFieldValue("kycFailedData", data.data?.data?.proposal_id);
          sessionStorage.setItem(
            "futureProposal_ID",
            data?.data?.data?.proposal_id
          );
          openIframePopUp(data.data.data, 2);
          formContext.setkycApiRes();
        }
        if (data?.data?.data?.result?.customer_name) {
          formik.setFieldValue(
            "kycFailedData",
            data.data?.data?.result?.ckyc_number
          );
          sessionStorage.setItem(
            "futureProposal_ID",
            data?.data?.data?.proposal_id
          );
          clearFormikForKyc();
          formContext.setkycApiRes(data?.data?.data?.result);
          formContext.setcarrierName("future");

          return next();
        }
      })
      .catch((err) => {})
      .finally((fin) => {
        formContext.setloaderStatus(false);
      });
  };
  const iciciKyc = (values) => {
    formContext.setloaderStatus(true);
    api
      .iciciKycVerify(kycTokenIcici, values)
      .then((data) => {
        iciciKycPart2(data);
      })
      .catch((err) => {
        formContext.setloaderStatus(false);
        next();
      });
  };
  const iciciKycPart2 = (data) => {
    formContext.setloaderStatus(false);
    // success
    if (data?.data?.data?.message === "OK") {
      clearFormikForKyc();
      formContext.setkycApiRes(data?.data?.data?.kyc_details);
      formContext.setcarrierName("icici");
      return next();
    }
    //  failure
    else {
      setkycFailUrl("NA");
      setkycPopUp(true);
      seticicikycError(false);
      iciciKycData({
        poiFile: "", //proof of identity
        poaFile: "",
        poitype: "",
        poatype: "",
      });
    }
  };

  const notify = () => {
    formContext.notifySuccess("KYC Successful!");
  };

  async function HdfcKycVerify(txnid, values) {
    formContext.setloaderStatus(true);
    let data = {
      kycRequestJson: {
        pan: values?.kycNominePanNumber,
        dob: convertDate(values?.kycNomineDob, 1),
        txn_id: txnid,
      },
      tokenUrl:
        "https://ekyc-uat.hdfcergo.com/e-kyc/tgt/generate-token?api_key=595d52aa-fc66-4a",
      kycUrl: "https://ekyc-uat.hdfcergo.com/e-kyc/primary/kyc-verified",
      tokenKey: "token",
    };
    await axios
      .post(
        process.env.REACT_APP_BASE_URL +
          "/cxf/kycservice/request/integrate/invoke",
        data
      )
      .then((result) => {
        if (JSON.parse(result?.data?.kycResponse)?.data?.iskycVerified === 1) {
          formik.setFieldValue(
            "kycFailedData",
            JSON.parse(result?.data?.kycResponse)?.data?.ckycNumber
          );
          formContext.setkycApiRes(JSON.parse(result?.data?.kycResponse)?.data);
          formContext.setcarrierName("hdfc");
          notify();
          next();
        }
        if (
          JSON.parse(result?.data?.kycResponse)?.data?.iskycVerified === 0 &&
          JSON.parse(result?.data?.kycResponse)?.data?.redirect_link
        ) {
          setkycFailUrl(
            JSON.parse(result?.data?.kycResponse)?.data?.redirect_link
          );

          formik.setFieldValue(
            "kycFailedData",
            JSON.parse(result?.data?.kycResponse)?.data
          );
          openIframePopUp(JSON.parse(result?.data?.kycResponse)?.data, 4);
          formContext.setkycApiRes();
        }
      })
      .catch((err) => {
        formContext.setloaderStatus(false);
        next();
      })
      .finally((fin) => {
        formContext.setloaderStatus(false);
      });
  }

  const bajajKycVerifyAPI = (transactionId, values) => {
    formContext.setloaderStatus(true);
    bajajKycVerify(transactionId, values).then((result) => {
      if (result?.data?.data) {
        const kycData = result?.data?.data;
        // Add validaiton

        if (kycData?.poiStatus === "NOT_FOUND") {
          formContext.setloaderStatus(false);
          alert("Proof Of Identity Failed. We can not Proceed ahead.");
          window.location.href = "/";
          // setShowBajajError(true);
        }
        if (
          kycData?.kycStatus === "KYC_SUCCESS" &&
          kycData?.ckycStatus === "FOUND"
        ) {
          formik.setFieldValue("kycFailedData", kycData?.ckycNumber);
          clearFormikForKyc();
          formContext.setkycApiRes(kycData);
          formContext.setcarrierName("bajaj");
          formContext.setloaderStatus(false);
          next();
        }

        if (kycData?.poiStatus === "FOUND" && kycData?.poaStatus === "NA") {
          formik.setFieldValue("kycFailedData", kycData?.transactionId);
          formContext.setkycApiRes();
          //changes for prod
          //====
          formContext.setcarrierName("bajaj");
          formContext.setloaderStatus(false);
          //  next();
          setShowBajajPOA(true);
        }
        // if (kycData?.poiStatus === "FOUND" && kycData?.poaStatus === "NA") {

        // }
        if (
          result.status === 200 &&
          kycData?.poiStatus === null &&
          kycData?.poaStatus === null
        ) {
          formContext.setcarrierName("bajaj");
          formContext.setloaderStatus(false);
          next();
        }
      }
    });
  };
  const handleClosePopup = (e) => {
    e.preventDefault();
    setShowBajajError(false);
    window.location.href = "/";
  };
  const bajajDocUploadDataSubmit = (values) => {
    bajajDocumentUploadForKyc(
      formContext?.singleQuotesData?.transactionid,
      values
    )
      .then((res) => {
        setShowBajajDocUpload(false);
        formContext.setloaderStatus(false);
        next();
      })
      .catch((err) => {
        setShowBajajDocUpload(false);
        formContext.setloaderStatus(false);
        next();
      });
  };

  const bajajPOASubmit = (values) => {
    console.log("bajajPOASubmit", values);
    bajajKycVerifyPOA(
      formContext?.singleQuotesData?.transactionid,
      formik?.values?.kycNomineDob,
      values
    )
      .then((result) => {
        if (result?.data?.data) {
          const kycData = result?.data?.data;
          if (
            kycData?.poiStatus === "FOUND" &&
            kycData?.poaStatus === "FOUND"
          ) {
            next();
          } else if (
            kycData?.poaStatus === "NA" ||
            kycData?.poaStatus === "NOT_FOUND"
          ) {
            setShowBajajPOA(false);
            // open document upload
            formik.setFieldValue("kycFailedData", kycData?.transactionId);
            formContext.setkycApiRes();
            //changes for prod
            //====
            formContext.setcarrierName("bajaj");
            formContext.setloaderStatus(false);
            //  next();
            setShowBajajDocUpload(true);
            //===
          }
        }
      })
      .catch((err) => {
        setShowBajajDocUpload(false);
        formContext.setloaderStatus(false);
        // next();
      });
  };

  const apiCalls = async (values, props) => {
    formContext.setloaderStatus(true);
    // vahan api for new ind only
    if (
      formContext.singleQuotesData?.insuranceCompany ===
        "New India Assurance" &&
      formContext.carbikeformikValues !== "new"
    ) {
      const result = await api.newIndVahanApi(formik.values?.registeredRto);

      if (result.data?.data?.Status === "Failure") {
        formContext.notifyError(result.data?.data?.Remarks);
        formContext.setloaderStatus(false);
        return;
      }
    }

    api
      .generateBikeproposal(
        values,
        props,
        formContext.carbikeformikValues,
        formContext.singleQuotesData
      )
      .then((data) => {
        console.log("data?.data.data", data?.data.data);
        formContext?.setIciciData(data?.data.data);
        if (
          data?.data?.message?.error?.validationMessages ||
          (data?.message && data?.status.toLowerCase() !== "success") ||
          data?.data?.message.length > 9
        ) {
          commonErrorMessage(
            data?.data?.data?.ErrorMessages ||
              data?.data?.message?.error?.validationMessages ||
              data.message ||
              data?.data?.message
          );
          formContext.setloaderStatus(false);
          return;
        }
        if (data?.data?.data?.Error) {
          commonErrorMessage(data.data?.data?.Error);
        } else if (data.data?.data?.ErrorMessages) {
          commonErrorMessage(data.data?.data?.ErrorMessages);
          // } else if (
          //   data.data?.data?.policyStatus &&
          //   formContext?.singleQuotesData?.insuranceCompany ===
          //     "GO DIGIT General Insurance CO. LTD" &&
          // (  data.data.data.policyStatus !== "INCOMPLETE" ||  data.data.data.policyStatus !== "PRE_INSPECTION_APPROVED")
          // ) {
          //   commonErrorMessage(
          //     <>
          //       {`ProposalId: ${data?.data?.data?.proposalId}`}
          //       <br />
          //       {`PolicyNumber: ${data?.data?.data?.policyNumber}`}
          //       <br />
          //       <h6 style={{ color: "red" }}>
          //         {`Policy Status - ${data.data.data.policyStatus}`}
          //       </h6>
          //     </>
          //   );
        } else if (data.data?.status === "Failure") {
          if (data.data?.data?.errorlist?.length > 0) {
            commonErrorMessage(data.data?.data?.errorlist[0]?.errtext);
          } else {
            commonErrorMessage();
          }
        } else {
          if (
            formContext?.singleQuotesData?.insuranceCompany ===
              "GO DIGIT General Insurance CO. LTD" &&
            data?.data?.data?.KYCLink
          ) {
            setkycFailUrl(data?.data?.data?.KYCLink);
            objForUpdateStage.KYC_link = data?.data?.data?.KYCLink;
            objForUpdateStage.KYC_Status = "not completed";
            formContext.setgodigitpayment(data?.data?.data);

            //openIframe(data?.data);
            openIframePopUp(data?.data?.data?.KYCLink, 6);
          } else {
            if (
              formContext?.singleQuotesData.insuranceCompany ===
                "New India Assurance" &&
              data?.data?.data?.linkUrl
            ) {
              formContext.setgodigitpayment(data?.data?.data);
              openIframePopUp(data?.data?.data, 3);
              objForUpdateStage.KYC_link = data?.data?.data?.linkUrl;
              setkycFailUrl(data?.data?.data?.linkUrl);
              objForUpdateStage.KYC_Status = "not completed";
            } else {
              if (
                formContext?.singleQuotesData.insuranceCompany ===
                  "HDFC ERGO General Insurance Company" &&
                data?.data?.data?.PDF_BYTES
              ) {
                setPdfDataUrl(
                  `data:application/pdf;base64,${data?.data?.data?.PDF_BYTES}`
                );
                setShowPdfModel(true);
                sethdfcDataForPayment(data?.data.data);
                sethdfcValuesForPayment(values);
                // paymentApicalls(values,)
              } else if (
                formContext?.singleQuotesData.insuranceCompany ===
                  "HDFC ERGO General Insurance Company" &&
                !data?.data?.data?.PDF_BYTES
              ) {
                commonErrorMessage(
                  "Could Not generate Customer Information Sheet. Please try again"
                );
              }
              // else if (
              //   formContext?.singleQuotesData.insuranceCompany ===
              //   "ICICI Lombard General Insurance Co. Ltd"
              // ) {
              //   seticiciDataForPayment(data?.data.data);
              //   seticiciValuesForPayment(values);
              //   formContext.setloaderStatus(false);
              // }
              else {
                paymentApicalls(values, data?.data.data);
              }
            }
          }
          // for crm updates
          if (
            formContext?.singleQuotesData.insuranceCompany ===
              "New India Assurance" &&
            !data?.data?.data?.linkUrl
          ) {
            objForUpdateStage.KYC_Status = "completed";
            setkycStatus("completed");
          }
          if (
            formContext?.singleQuotesData?.insuranceCompany ===
              "GO DIGIT General Insurance CO. LTD" &&
            !data?.data?.data?.KYCLink
          ) {
            setkycStatus("completed");
            objForUpdateStage.KYC_Status = "completed";
          }
        }
      })
      .catch((err) => {
        commonErrorMessage();
      });
  };

  useEffect(() => {
    formContext?.setIciciData(iciciDataForPayment);
    formContext?.setIsLastStep(true);
  }, [iciciDataForPayment]);

  useEffect(() => {
    if (consentGiven) {
      if (consentSave) {
        const value = hdfcValueForPayment;
        const data = hdfcDataForPayment;
        formContext.setloaderStatus(false);
        paymentApicalls(value, data);
      }
    }
  }, [consentGiven, consentSave]);

  const clearFormikForKyc = () => {
    formik.setFieldValue("kycNomineGender", "");
    formik.setFieldValue("ownerPrefix", "");
    formik.setFieldValue("ownerFirstName", "");
    formik.setFieldValue("ownerLastName", "");
    formik.setFieldValue("ownerMiddleName", "");
    formik.setFieldValue("ownerPhoneNumber", "");
    formik.setFieldValue("ownerEmail", "");
    formik.setFieldValue("ownerPincode", "");
    formik.setFieldValue("ownerCity", "");
    formik.setFieldValue("ownerState", "");
    formik.setFieldValue("ownerLocation", "");
    formik.setFieldValue("ownerAddress", "");
  };

  // for godigit
  const openIframe = (data) => {
    setiframeOpenedCarrier("godigit");
    setiframeUrl(data?.data?.KYCLink);
    setiframeStatus(true);
  };

  // for reliance
  const openIframePopUp = (data, type) => {
    // reliance
    if (type === 1) {
      setiframeOpenedCarrier("reliance");
      setiframeUrl(data?.Endpoint_2_URL);
      setErrorMessage("KYC details not found");
      setkycPopUpStatus(true);
    }
    // future
    if (type === 2) {
      console.log(data);
      setiframeOpenedCarrier("future");
      localStorage.setItem("fgProposalId", data?.proposal_id);
      setfutureKycProposalId(data?.proposal_id);
      // window.open(
      //   data?.url,
      //   "_self",
      //   "toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=100,width=1100,height=1100"
      // );
      setiframeUrl(data?.url);
      setErrorMessage("KYC details not found");
      setkycPopUpStatus(true);
    }
    // new india
    if (type === 3) {
      setiframeOpenedCarrier("newindia");
      setiframeUrl(data?.linkUrl);
      setErrorMessage("KYC details not found");
      setkycPopUpStatusNewInd(true);
    }
    //hdfc
    if (type === 4) {
      setiframeOpenedCarrier("hfdc");
      setiframeUrl(data?.redirect_link);
      setErrorMessage("KYC details not found");
      setkycPopUpStatus(true);
    }

    //go digit
    if (type === 6) {
      setiframeOpenedCarrier("godigit");
      setiframeUrl(data);
      setErrorMessage("KYC details not found");
      setkycPopUpStatus(true);
    }
  };
  const paymentApicalls = (values, data) => {
    api
      .paymentApi(data ? data : formContext.godigitpayment)
      .then((item) => {
        const searchCityData = JSON.parse(item?.data);

        if (!sessionStorage.getItem("isAdmin")) {
          crmStateUpdate(data);
        }
        if (
          formContext?.singleQuotesData.insuranceCompany ===
          "ICICI Lombard General Insurance Co. Ltd"
        ) {
          let urlCombined = searchCityData?.data?.paymentURL;
          console.log("urlCombined", urlCombined);
          let url = searchCityData?.data?.paramterList?.map(
            (mapdata, index) => {
              urlCombined =
                urlCombined +
                `${index === 0 ? "?" : "&"}` +
                mapdata.name +
                "=" +
                mapdata.value;
              // console.log("urlCombined",urlCombined)
              return urlCombined;
            }
          );
          // console.log("url",url[0])
          setShareUrl(url[0]);
          setIciciPyamentLink(true);
        } else {
          openpaymentWindow(searchCityData?.data, data);
        }
      })
      .catch((err) => {
        commonErrorMessage();
      })
      .finally(() => {
        formContext.setloaderStatus(false);
      });
  };

  const openpaymentWindow = (arraydata, generateproData) => {
    let urlCombined = arraydata?.paymentURL;
    var urlCombinedForFutureG =
      formContext?.singleQuotesData.insuranceCompany ===
      "HDFC ERGO General Insurance Company"
        ? "/hdfcinsurance"
        : "/futureinsurance";

    arraydata?.paramterList?.map((mapdata, index) => {
      urlCombined =
        urlCombined +
        `${index === 0 ? "?" : "&"}` +
        mapdata.name +
        "=" +
        mapdata.value;

      urlCombinedForFutureG =
        urlCombinedForFutureG +
        `${index === 0 ? "?" : "&"}` +
        mapdata.name +
        "=" +
        mapdata.value;

      return (paymentdataDestructure[mapdata.name] = mapdata.value);
    });

    urlCombinedForFutureG =
      urlCombinedForFutureG + "&&paymentURL=" + arraydata?.paymentURL;
    let urlCombinedForhdfc =
      urlCombinedForFutureG + "&&paymentURL=" + arraydata?.paymentURL;

    // code for futureindia
    if (
      formContext?.singleQuotesData.insuranceCompany ===
      "Future Generali India Insurance Co. Ltd"
    ) {
      history.push(urlCombinedForFutureG);
    } else if (
      formContext?.singleQuotesData.insuranceCompany ===
      "HDFC ERGO General Insurance Company"
    ) {
      consentGiven &&
        window.open(
          urlCombinedForhdfc,
          "_self",
          "toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=100,width=1100,height=1100"
        );
    } else {
      window.open(
        urlCombined,
        "_self",
        "toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=100,width=1100,height=1100"
      );
    }
  };

  const commonErrorMessage = (message) => {
    setErrorMessage(message ? message : "An error occurred. Please try again.");
    setErrorModalShow(true);
    formContext.setloaderStatus(false);
  };
  const onIframeHide = () => {
    if (iframeOpenedCarrier === "godigit") {
      GoDigitEmailTrigger();
      paymentApicalls();
      // formContext.setloaderStatus(false);
      // setiframeStatus(!iframeStatus);
      // setkycPopUpStatus(false);
      // next()
    } else {
      setkycPopUpStatus(false);
      setkycPopUp(false);
    }
  };

  const kycProceed = () => {
    setkycPopUpStatus(false);
    window.open(
      iframeUrl,
      "_blank",
      "toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=100,width=1100,height=1100"
    );
    next();
  };
  const newIndiaKycClose = (type) => {
    if (type === 1) {
      setkycPopUpStatusNewInd(false);

      formContext.setloaderStatus(false);
      //  paymentApicalls();
    } else {
      setkycPopUpStatusNewInd(false);
      formContext.setloaderStatus(false);
      // paymentApicalls();
      window.open(
        iframeUrl,
        "_blank",
        "toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=100,width=1100,height=1100"
      );
    }
  };
  const titleArray = [
    "Required information for KYC verification",
    "Person who is owning the vehicle and contact information",
    "Person who will receive the benefit in case of death of owner of the vehicle",
    "Customer Address",
    "Previous Policy Details",
    "Vehicle Details",
    "Payment Process",
  ];

  const pagesForRenew = [
    <KYCForm {...props} formik={formik} />,
    <OwnerContactInfo {...props} formik={formik} />,
    <Benefits {...props} formik={formik} />,
    <AddressDetailsofVehicalDetails {...props} formik={formik} />,
    <PreviousPolicyForm {...props} formik={formik} />,
    <InsuredVehical {...props} formik={formik} />,
    <PaymentForm {...props} formik={formik} proposalNo={iciciDataForPayment} />,
  ];

  const pagesForNew = [
    <KYCForm {...props} formik={formik} />,
    <OwnerContactInfo {...props} formik={formik} />,
    <Benefits {...props} formik={formik} />,
    <AddressDetailsofVehicalDetails {...props} formik={formik} />,
    <InsuredVehical {...props} formik={formik} />,
    <PaymentForm {...props} formik={formik} proposalNo={iciciDataForPayment} />,
  ];
  // Destructuring variables from the custom hook UseMultistepForm
  const {
    steps,
    currentStepIndex,
    step,
    isFirstStep,
    isLastStep,
    isSecondLast,
    goTo,
    back,
    next,
  } = UseMultistepForm(policyType === "renew" ? pagesForRenew : pagesForNew);

  /*Update the Formik field "currentStepIndex" whenever the value of
   "currentStepIndex" changes, ensuring the form reflects the current step.*/
  useEffect(() => {
    formik.setFieldValue("currentStepIndex", currentStepIndex);
  }, [currentStepIndex]);

  const returnData = (file, previewURL, type) => {
    if (type === 1) {
      seticiciKycData({ ...iciciKycData, poiFile: file });
    } else {
      seticiciKycData({ ...iciciKycData, poaFile: file });
    }
  };
  const docTypeChanges = (value, type) => {
    if (type === 1) {
      seticiciKycData({ ...iciciKycData, poitype: value });
    } else {
      seticiciKycData({ ...iciciKycData, poatype: value });
    }
  };
  // const iciciKycSubmit = () => {
  //   seticiciApiError(false);
  //   seticicikycError(false);
  //   setKycLoader(true);
  //   for (let i in iciciKycData) {
  //     if (!iciciKycData[i]) {
  //       seticicikycError(true);
  //       return;
  //     }
  //   }
  //   api
  //     .iciciKycDocSubmit(
  //       kycTokenIcici,
  //       props.carbikeformikValues?.email,
  //       iciciKycData
  //     )
  //     .then((data) => {
  //       const il_kyc_ref_no = JSON.parse(data?.data?.data)?.kyc_details
  //         ?.il_kyc_ref_no;
  //       if (il_kyc_ref_no) {
  //         setKycLoader(false);

  //         formik?.setFieldValue("ilKycRefNo", il_kyc_ref_no);
  //         setkycPopUp(false);
  //       } else {
  //         const err = JSON.parse(data?.data?.data);
  //         seticiciApiError(err?.message);
  //         setKycLoader(false);
  //         return back();
  //       }
  //     })
  //     .catch((err) => {});
  // };
 

 // const [ isSubmitting,setIsSubmitting ] = useState(false)
  const iciciKycSubmit = async () => {
    if (isSubmitting) return;
    try {
      setIsSubmitting(true);
      seticiciApiError(false);
      seticicikycError(false);
      setKycLoader(true);
        for (let i in iciciKycData) {
        if (!iciciKycData[i]) {
          seticicikycError(true);
          setIsSubmitting(false);
          setKycLoader(false);
          return;
        }
      }

      const data = await api.iciciKycDocSubmit(
        kycTokenIcici,
        props.carbikeformikValues?.email,
        iciciKycData
      );

      const il_kyc_ref_no = JSON.parse(data?.data?.data)?.kyc_details?.il_kyc_ref_no;
      
      if (il_kyc_ref_no) {
        formik?.setFieldValue("ilKycRefNo", il_kyc_ref_no);
        setkycPopUp(false);
      } else {
        const err = JSON.parse(data?.data?.data);
        seticiciApiError(err?.message);
        return back();
      }
    } catch (err) {
      console.error("ICICI KYC submission error:", err);
      seticiciApiError("Something went wrong. Please try again.");
    } finally {
      setKycLoader(false);
      setIsSubmitting(false);
    }
  };
 
  useEffect(() => {
    if (props.quotesPageFormikData?.vehicalRegType === "organization") {
      setpoiType([
        { value: "", label: "Please select" },
        { value: "PAN", label: "PAN" },
      ]);
      setpoAType([
        { value: "", label: "Please select" },
        { value: "CIN", label: "CIN" },
        { value: "GSTIN", label: "GSTIN" },
      ]);
    }
  }, [props.quotesPageFormikData?.vehicalRegType]);

  // token required for icici kyc
  useEffect(() => {
    if (
      formContext?.singleQuotesData?.insuranceCompany ===
      "ICICI Lombard General Insurance Co. Ltd"
    ) {
      api
        .iciciKycToken()
        .then((data) => {
          setkycTokenIcici(data?.data?.data?.access_token);
        })
        .catch((err) => {});
    }
  }, []);

  const popShareQuotes = (
    <div className="Demo__container share-quote-content">
      <div className="d-flex">
        <div
          className="Demo__some-network social-icon"
          title="Email"
          style={{ margin: "5rm" }}
        >
          <EmailShareButton
            url={shareUrl}
            subject={title}
            body="body"
            className="Demo__some-network__share-button"
            style={{ margine: "5px" }}
          >
            <EmailIcon size={32} round />
          </EmailShareButton>
        </div>

        <div
          className="Demo__some-network "
          //  onClick={closeShareQuptes}
          title="Whatsapp"
        >
          <WhatsappShareButton
            url={shareUrl}
            title={title}
            separator=":: "
            className="Demo__some-network__share-button"
            style={{ margine: "5px" }}
          >
            <WhatsappIcon size={32} round />
          </WhatsappShareButton>
        </div>
      </div>
    </div>
  );

  async function handleData(safeCombinedFormData) {
    const uniqueId = localStorage.getItem("uniqueId");

    try {
      // Fetch all records and convert to JSON
      const response = await fetch(
        "https://json-db.policies365.com/formContextObj"
      );
      const records = await response.json();
      console.log("records", records);
      // Ensure records is an array before using find
      const matchingRecord = Array.isArray(records)
        ? records.find(
            (record) => record.singleQuotesData?.QuotationNo === uniqueId
          )
        : null;
      console.log("matchingRecord", matchingRecord);
      if (matchingRecord) {
        // Delete existing record
        await fetch(
          `https://json-db.policies365.com/formContextObj/${matchingRecord.id}`,
          {
            method: "DELETE",
            headers: { "Content-Type": "application/json" },
          }
        );
      }

      // Post new data
      const postResponse = await fetch(
        "https://json-db.policies365.com/formContextObj",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(safeCombinedFormData), // Stringify the request body
        }
      );

      if (postResponse.ok) {
        console.log("Data posted successfully");
      } else {
        console.error("Failed to post data");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  }

  return (
    <div className="forms-wrap">
      <ToastContainer />
      <div className="form-title">
        <p className="form-sub-title">{titleArray[currentStepIndex]}</p>
        <p className="page-nuber">
          ({currentStepIndex + 1} / {steps.length})
        </p>
      </div>
      <form onSubmit={formik.handleSubmit}>
        {step}

        <div
          style={{
            display: "flex",
            gap: ".5rem",
            justifyContent: "flex-end",
            right: "10px",
            bottom: "20px",
          }}
          className="btn-spinner"
        >
          {!isFirstStep && (
            <Button
              onClick={back}
              className=" back-btn"
              disabled={formContext.loaderStatus}
            >
              Back
            </Button>
          )}
          {iciciPyamentLink && shareUrl && isLastStep && (
            <Button
              className=" back-btn"
              onClick={copyToClipboard}
              disabled={formContext.loaderStatus}
            >
              {isCopied ? "Copied!" : "Copy Link"}
            </Button>
          )}
          <Button
            type="submit"
            className="primary-btn"
            disabled={formContext.loaderStatus || formContext.fgLoader}
            style={{ width: "144px" }}
            onClick={(e) => {
              console.log("clicked");
              if (!isLastStep) {
                e.preventDefault(); // Only prevent default when it's not the last step
              }
              if (
                isLastStep &&
                formContext?.singleQuotesData.insuranceCompany ===
                  "ICICI Lombard General Insurance Co. Ltd" &&
                iciciPyamentLink
              ) {
                window.open(
                  shareUrl,
                  "_self",
                  "toolbar=yes,scrollbars=yes,resizable=yes,top=100,left=100,width=1100,height=1100"
                );

                // Delay form submission slightly to ensure execution
                setTimeout(() => {
                  formik.handleSubmit();
                }, 0);
              } else {
                formik.handleSubmit();
              }
            }}
          >
            {isLastStep
              ? formContext?.singleQuotesData.insuranceCompany ===
                  "ICICI Lombard General Insurance Co. Ltd" && iciciPyamentLink
                ? "Proceed to pay"
                : "Finish"
              : isSecondLast &&
                formContext?.singleQuotesData.insuranceCompany ===
                  "ICICI Lombard General Insurance Co. Ltd"
              ? "Proceed"
              : formContext.fgLoader ? "Redirecting"  : "Next"}
              
            {formContext.loaderStatus &&
              formContext.spinner &&
              !formContext.setSpinnerDisapear}
          </Button>
        </div>
        {/* Error Modal */}
        <VerticallyCenteredModal
          show={errorModalShow}
          onHide={() => setErrorModalShow(false)}
          heading="Error"
        >
          <p>{errorMessage}</p>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <Button
              className=" back-btn"
              onClick={() => setErrorModalShow(false)}
            >
              Cancel
            </Button>
            {/* You can add any logic or handle actions needed for the "OK" button */}
            <Button
              className=" primary-btn"
              onClick={() => setErrorModalShow(false)}
            >
              OK
            </Button>
          </div>
        </VerticallyCenteredModal>
        {/* <VerticallyCenteredModal
          show={kycPopUp}
          onHide={() => {
            back();
            onIframeHide();
          }}
          heading="KYC not found"
          hideCloseIcon={true}
        >
          <div className="kyc-doc-wrap">
            <p className="text-center mt-0">
              Please Upload identity and address proof
            </p>
            <div className="upload-section">
              <div className="document-container">
                <div className="form-floating mb-4">
                  <select
                    id="iduniqu"
                    className="form-select"
                    onChange={(e) => docTypeChanges(e.target.value, 1)}
                  >
                    {poiType?.map((data) => {
                      return <option value={data?.value}>{data?.label}</option>;
                    })}
                  </select>
                  <label htmlFor="iduniqu select-label">
                    identity Document
                  </label>
                </div>
                <FileUpload returnData={returnData} type={1} />
              </div>
              <div className="document-container">
                <div className="form-floating mb-4">
                  <select
                    id="idunique"
                    className="form-select"
                    onChange={(e) => docTypeChanges(e.target.value, 2)}
                  >
                    {poAType?.map((data) => {
                      return <option value={data?.value}>{data?.label}</option>;
                    })}
                  </select>
                  <label htmlFor="idunique select-label">
                    Address Document
                  </label>
                </div>
                <FileUpload returnData={returnData} type={2} />
              </div>
            </div>
            {icicikycError && (
              <p className="text-center" style={{ color: "red", fontSize: 16 }}>
                All four fileds are required{" "}
              </p>
            )}
            {iciciApiError && (
              <p className="text-center" style={{ color: "red", fontSize: 16 }}>
                {iciciApiError}
              </p>
            )}
            <p className="text-center mb-0">
              <span className="font-weight-bold">Note:</span>Front and back side
              must be uploaded as a single image for Voter ID, Passport,
              AADHAAR.{" "}
            </p>
            <p className="text-center mb-0">
              Expected formats are: pdf, jpg, jpeg, png, and max size up to
              10MB.{" "}
            </p>
            <div className="text-center mt-3">
              <Button
                className="cancel-btn"
                onClick={() => {
                  back();
                  onIframeHide();
                }}
              >
                Cancel
              </Button>
<<<<<<< HEAD
              <Button className="primary-btn" disabled={kycLoader} onClick={() => iciciKycSubmit()}>
=======
              <Button
                className="primary-btn"
                onClick={() => iciciKycSubmit()}
                disabled={isSubmitting}
              >
>>>>>>> c38973b6d9a05c3dc8026e21522e38a2af8e1cca
                Submit
              </Button>
            </div>
          </div>
        </VerticallyCenteredModal> */}
<VerticallyCenteredModal
          show={kycPopUp}
          onHide={() => {
            back();
            onIframeHide();
          }}
          heading="KYC not found"
          hideCloseIcon={true}
        >
          <div className="kyc-doc-wrap">
            <p className="text-center mt-0">
              Please Upload identity and address proof
            </p>
            <div className="upload-section">
              <div className="document-container">
                <div className="form-floating mb-4">
                  <select
                    id="iduniqu"
                    className="form-select"
                    onChange={(e) => docTypeChanges(e.target.value, 1)}
                  >
                    {poiType?.map((data) => {
                      return <option value={data?.value}>{data?.label}</option>;
                    })}
                  </select>
                  <label htmlFor="iduniqu select-label">
                    identity Document
                  </label>
                </div>
                <FileUpload returnData={returnData} type={1} />
              </div>
              <div className="document-container">
                <div className="form-floating mb-4">
                  <select
                    id="idunique"
                    className="form-select"
                    onChange={(e) => docTypeChanges(e.target.value, 2)}
                  >
                    {poAType?.map((data) => {
                      return <option value={data?.value}>{data?.label}</option>;
                    })}
                  </select>
                  <label htmlFor="idunique select-label">
                    Address Document
                  </label>
                </div>
                <FileUpload returnData={returnData} type={2} />
              </div>
            </div>
            {icicikycError && (
              <p className="text-center" style={{ color: "red", fontSize: 16 }}>
                All four fileds are required{" "}
              </p>
            )}
            {iciciApiError && (
              <p className="text-center" style={{ color: "red", fontSize: 16 }}>
                {iciciApiError}
              </p>
            )}
            <p className="text-center mb-0">
              <span className="font-weight-bold">Note:</span>Front and back side must be uploaded as a single image for Voter ID, Passport, AADHAAR.{" "}
            </p>
            <p className="text-center mb-0">
            Expected formats are: pdf, jpg, jpeg, png, and max size up to 10MB.{" "}
            </p>
            <div className="text-center mt-3">
              <Button
                className="cancel-btn"
                onClick={() => {
                  back();
                  onIframeHide();
                }}
              >
                Cancel
              </Button>
              <Button
                className="primary-btn"
                onClick={() => iciciKycSubmit()}
                disabled={isSubmitting}
              >
                Submit
              </Button>
            </div>
          </div>
        </VerticallyCenteredModal>
        {/* <VerticallyCenteredModal 
          show={iciciPyamentLink}
          onHide={() => {
            setIciciPyamentLink(false)
          }}
          heading="ICICI Payment Link"
        >
          <div >
          {popShareQuotes}
          </div>
          <div>
            <p>The link is valid for a single use only. If the customer misses the link, please generate a new proposal and create a new link for the customer.</p>
          </div>
          
        </VerticallyCenteredModal> */}
      </form>
      <>
        <SuccessModal show={modalShow} onHide={() => setModalShow(false)} />

        {/* Iframe value popup */}
        <VerticallyCenteredModal
          show={iframeStatus}
          onHide={() => {
            onIframeHide();
          }}
          heading="This step is mandatory to download the policy."
        >
          <div>
            <iframe
              src={iframeUrl}
              title="Please fill all data"
              width={"100%"}
              height={575}
            ></iframe>
          </div>
        </VerticallyCenteredModal>
        {/* for popup kyc */}
        <VerticallyCenteredModal
          show={kycPopUpStatus}
          onHide={() => {
            onIframeHide();
          }}
          heading="This step is mandatory to download the policy."
        >
          <div className="kyc-failed-popup">
            <div className="text-center mt-2">
              <p className="mt-2" style={{ color: "red" }}>
                {errorMessage}
              </p>
              <div className="text-center mt-3 mb-3 text-info-label d-flex justify-content-center align-items-center">
                <BiSolidError className="failed-icon-custom" />
                Click on below Button for KYC verification
              </div>
              <div className=" footer-btn-wrap">
                <Button
                  className="back-btn"
                  onClick={() => {
                    if (iframeOpenedCarrier === "godigit") {
                      setkycPopUpStatus(false);
                      formContext.setloaderStatus(false);
                      GoDigitEmailTrigger();
                      paymentApicalls();
                    } else {
                      setkycPopUpStatus(false);
                      return next();
                    }
                  }}
                >
                  Skip
                </Button>
                <Button
                  className="primary-btn"
                  onClick={async () => {
                    if (iframeOpenedCarrier === "godigit") {
                      // paymentApicalls();
                      kycProceed();
                      formContext.setloaderStatus(false);
                    }
                    if (iframeOpenedCarrier === "future") {
                      const url = encryptEmail(iframeUrl);
                      // const combinedFormData = {
                      //   quotesPageFormikData: formContext?.quotesPageFormikData,
                      //   singleQuotesData: formContext?.singleQuotesData,
                      //   proposalData: formContext?.proposalData,
                      //   crmLoggedUser: formContext?.crmLoggedUser,
                      //   carbikeformikValues: formContext?.carbikeformikValues,
                      //   PolicyDates: formContext?.PolicyDates,
                      //   kycApiRes: formContext?.kycApiRes,
                      //   loaderStatus: formContext?.loaderStatus,
                      //   spinner: formContext?.spinner,
                      //   spinnerDisapear: formContext?.spinnerDisapear,
                      // };
                      // let safeCombinedFormData =
                      //   removeCircularReferences(combinedFormData);
                      // safeCombinedFormData.kycData = {
                      //   kycNominePanNumber: formik.values.kycNominePanNumber,
                      //   kycNomineAadharNumber:
                      //     formik.values.kycNomineAadharNumber || "",
                      //   kycNomineDob: formik.values.kycNomineDob || "",
                      //   kycNomineGender: formik.values.kycNomineGender || "",
                      //   kycNomineFullName:
                      //     formik.values.kycNomineFullName || "",
                      // };
                      // await handleData(safeCombinedFormData);

                      const nomineeDetails = {
                        kycNominePanNumber: formik.values.kycNominePanNumber,
                        kycNomineAadharNumber:
                          formik.values.kycNomineAadharNumber || "",
                        kycNomineDob: formik.values.kycNomineDob || "",
                        kycNomineGender: formik.values.kycNomineGender || "",
                        kycNomineFullName:
                          formik.values.kycNomineFullName || "",
                      };

                      // Store in localStorage
                      localStorage.setItem(
                        "nomineeDetails",
                        JSON.stringify(nomineeDetails)
                      );

                      localStorage.setItem("prop", JSON.stringify(props));
                      window.location.href =
                        "/KYCRedirection/" + url + "/" + futureKycProposalId;
                    } else {
                      kycProceed();
                    }
                  }}
                >
                  Proceed for KYC{" "}
                </Button>
              </div>
            </div>
          </div>
        </VerticallyCenteredModal>
        {/* for popup kyc new india */}
        <VerticallyCenteredModal
          show={kycPopUpStatusNewInd}
          onHide={() => {
            newIndiaKycClose(1);
          }}
          heading="This step is mandatory to download the policy."
        >
          <div className="kyc-failed-popup">
            <div className="text-center mt-2">
              <p className="mt-2" style={{ color: "red" }}>
                {errorMessage}
              </p>
              <div className="text-center mt-3 mb-3 text-info-label d-flex justify-content-center align-items-center">
                <BiSolidError className="failed-icon-custom" />
                Click on below Button for KYC verification
              </div>
              <div className=" footer-btn-wrap">
                <Button
                  className="back-btn"
                  onClick={() => {
                    newIndiaKycClose(1);
                  }}
                >
                  Skip
                </Button>
                <Button
                  className="primary-btn"
                  onClick={() => {
                    newIndiaKycClose(2);
                  }}
                >
                  Proceed for KYC{" "}
                </Button>
              </div>
            </div>
          </div>
        </VerticallyCenteredModal>

        {/* bajaj doc upload */}
        <VerticallyCenteredModal
          show={showBajajDocUpload}
          onHide={() => setShowBajajDocUpload(false)}
          heading="Upload proof of address"
        >
          <BajajUploadDocForKYC onSubmit={bajajDocUploadDataSubmit} />
          {showBajajError && (
            <Modal show={true}>
              <Modal.Header closeButton>
                <Modal.Title>
                  Proof Of Identity Failed. We can not Proceed ahead.
                </Modal.Title>
              </Modal.Header>
              <Modal.Footer>
                <Button
                  type="button"
                  className="primary-btn"
                  onClick={handleClosePopup}
                >
                  Okay!
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </VerticallyCenteredModal>

        {/* bajaj poa details */}
        <VerticallyCenteredModal
          show={showBajajPOA}
          onHide={() => setShowBajajPOA(false)}
          heading="Fill Document Details"
        >
          <BajajPOA onSubmit={bajajPOASubmit} />
        </VerticallyCenteredModal>

        {/* pdf view and consent */}
        <VerticallyCenteredModal
          show={showPdfModel}
          onHide={() => setShowPdfModel(false)}
          heading="PDF View and Consent"
        >
          {/* PDF display */}
          <embed
            src={pdfDataUrl}
            type="application/pdf"
            width="100%"
            height="500px"
          />

          {/* Consent checkbox */}
          <div className="mt-3">
            <label>
              <input
                type="checkbox"
                checked={consentGiven}
                onChange={handleCheckboxChange}
              />
              I/We hereby declare that the Customer Information Sheet has been
              duly received and thoroughly reviewed. I/We confirm understanding
              and noting the details contained therein.
            </label>
          </div>

          {/* Modal footer buttons */}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <Button
              className="back-btn"
              onClick={() => setErrorModalShow(false)}
            >
              Cancel
            </Button>
            <Button
              className="primary-btn"
              onClick={(e) => {
                e.preventDefault();
                setConsentSave(true);
              }}
              disabled={!consentGiven}
            >
              OK
            </Button>
          </div>
        </VerticallyCenteredModal>
      </>
      {kycLoader && (
        <div
          className="spinner-border"
          role="status"
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            zIndex: 9999,
            width: "3rem",
            height: "3rem",
          }}
        >
          {/* {formContext.spinner} */}
        </div>
      )}
    </div>
  );
}

function SuccessModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="success-modal"
    >
      <div className="check-icon-wrap">
        <SVG src={Done} alt="" style={{ width: "80px" }} />
      </div>
      <h3 className="text-center mt-3">Done</h3>
      <Modal.Body className="p-0">
        <p className="text-center mt-0">register successfully!</p>
      </Modal.Body>
      <Modal.Footer className="modalfooter">
        <Button className="primary-btn" onClick={props.onHide}>
          Done
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
export default ContainerForForm;
