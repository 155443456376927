import React, { useEffect, useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import VerticallyCenteredModal from "../../commonModules/Popups/VerticallyCenteredModal";
import InputFieldText from "../../commonModules/InputFieldText";
import InputFieldDropdown from "../../commonModules/InputFieldDropdown";
import InputFieldCheckBox from "../../commonModules/InputFieldCheckBox";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import SVG from "react-inlinesvg";
import { Button } from "react-bootstrap";
import deleteIcon from "../../../../src/images/delete.svg";
import location from "../../../../src/images/location.svg";
import ErrorMessage from "../../commonModules/ErrorMessage";
import "../../style/CSS/HealthFormPage1.css"
import { getCarePincodeData } from "../../../API/authCurd"
import UseFormContext from "../../../context/UseFormContext";

export default function HealthFormPage1(props) {
  console.log("print propss", props)
  const formContext = UseFormContext();
  const [modalShow, setModalShow] = React.useState(false);
  const [InsurancemodalShow, SetInsurancemodalShow] = React.useState(false);
  const [memberIndex, setMemberIndex] = useState(1);
  const [formError, setFormError] = useState("");
  const [fieldVisibility, setFieldVisibility] = useState(true);
  const [pincodeError, setPincodeError] = useState("");
  const [pincodeStatus, setpincodeStatus] = useState(true);
  const [loading, setLoading] = useState()
  const [familyMembers, setFamilyMembers] = useState([
    {
      id: 0,
      dateOfBirth: null,
      age: null,
      relation: 'Self',
      dateOfBirthError: "",
    },
  ]);
  const hospitalLimitValues = [
    { label: "Select", value: "" },
    { label: "5 lakhs", value: "5" },
    { label: "7 lakhs", value: "7" },
    { label: "7.5 lakhs", value: "7.5" },
    { label: "10 lakhs", value: "10" },
    { label: "15 lakhs", value: "15" },
    { label: "20 lakhs", value: "20" },
    { label: "25 lakhs", value: "25" },
    { label: "30 lakhs", value: "30" },
    { label: "35 lakhs", value: "35" },
    { label: "40 lakhs", value: "40" },
    { label: "45 lakhs", value: "45" },
    { label: "50 lakhs", value: "50" },
    { label: "75 lakhs", value: "75" },
    { label: "1 crore", value: "100" },
    { label: "1.5 crores", value: "150" },
    { label: "2 crores", value: "200" },
    { label: "3 crores", value: "300" },
    { label: "6 crores", value: "600" },
  ];

  const addFamilyMember = () => {
    if (familyMembers.length < 6) {
      setMemberIndex((prevIndex) => prevIndex + 1);
      setFamilyMembers((family) => [...family, {
        id: memberIndex,
        age: null,
        relation: '',
        dateOfBirth: null,
        dateOfBirthError: "",
      }]);
    }
  };
  const policyTermOptions = [
    { value: "1", label: "1 Year" },
    { value: "2", label: "2 Year" },
    { value: "3", label: "3 Year" }
  ];


  const handleRelationChange = (event, id) => {
    let selectedRelation = event.target.value;
    let childCount = 0;
    let adultCount = 0;
    let motherCount = 0;
    let fatherCount = 0;
    let selfCount = 0;


    familyMembers.forEach((member) => {
      if (member.relation === 'Son' || member.relation === 'Daughter') childCount++;
      else if (member.relation === 'Self') selfCount++;
      else if (member.relation === 'Spouse' || member.relation === 'Mother' || member.relation === 'Father') adultCount++;
      if (member.relation === 'Mother') motherCount++;
      if (member.relation === 'Father') fatherCount++;
    });


    if (selectedRelation === 'Self' && selfCount > 0) {
      formContext.notifyError("Self can only be selected once");
      return;
    }


    if (selectedRelation === 'Mother' && motherCount > 0) {
      formContext.notifyError("Mother can only be selected once");
      return;
    }


    if (selectedRelation === 'Father' && fatherCount > 0) {
      formContext.notifyError("Father can only be selected once");
      return;
    }


    if (selectedRelation === 'Son' || selectedRelation === 'Daughter') {
      if (childCount >= 4) {
        formContext.notifyError("You can only add up to 4 children");
        return;
      }
    }

    if (adultCount >= 4) {
      formContext.notifyError("You can only add up to 4 adults");
      return;
    }


    setFamilyMembers((members) =>
      members.map((member) => {
        if (member.id === id) {
          member.relation = selectedRelation;
        }
        return member;
      })
    );
  };


  const handleDateOfBirthChange = (event, id) => {
    const selectedDate = new Date(event.target.value);
    const today = new Date();

    if (selectedDate > today) {
      setFormError("Date of Birth cannot be a future date.");
      setFamilyMembers((members) =>
        members.map(member => {
          if (member.id === id) {
            member.dateOfBirth = '';
            member.age = null;
            member.dateOfBirthError = "Date of Birth cannot be a future date.";
          }
          return member;
        })
      );
      return;
    }
    setFamilyMembers((members) =>
      members.map(member => {
        if (member.id === id) {
          member.dateOfBirth = event.target.value;
          let ageResult = calculateAge(event.target.value);
          member.age = ageResult.years || `${ageResult.days} days`;
          member.dateOfBirthError = "";
        }
        return member;
      })
    );
    setFormError("");
  };


  const calculateAge = (dateValue) => {
    console.log("Input Date Before Parsing:", dateValue);
    let day, month, year;
    if (dateValue.includes("-")) {
      const parts = dateValue.split("-");
      if (parts[0].length === 4) {
        [year, month, day] = parts.map(Number);
      } else {
        [day, month, year] = parts.map(Number);
      }
    } else {
      console.error("Invalid date format!");
      return { error: "Invalid date format" };
    }

    const birthDate = new Date(year, month - 1, day);
    const today = new Date();
    if (isNaN(birthDate.getTime()) || birthDate > today) {
      console.error("Invalid date provided or future date selected!");
      return { error: "Invalid date or future date selected" };
    }

    console.log("Parsed Date:", birthDate);
    const diffTime = today - birthDate;
    let totalDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
    let years = today.getFullYear() - birthDate.getFullYear();
    const birthdayThisYear = new Date(today.getFullYear(), birthDate.getMonth(), birthDate.getDate());

    if (today < birthdayThisYear) {
      years -= 1;
    }

    console.log(`Calculated Age: Years = ${years}, Days = ${totalDays}`);
    return years < 1 ? { days: totalDays } : { years };
  };


  const handleDeleteMember = (id) => {
    const memberToDelete = familyMembers.find(member => member.id === id);
    if (memberToDelete.relation === "Self") {
      formContext.notifyError("The 'Self' member cannot be deleted")

      return;
    }
    const remainingMembers = familyMembers.filter(member => member.id !== id);
    const selfExists = remainingMembers.some(member => member.relation === "Self");

    if (!selfExists && memberToDelete.relation === "Self") {
      formContext.notifyError("The 'Self' member cannot be deleted")
      return;
    }
    setFamilyMembers(remainingMembers);
  };

  const handleSubmitMembers = () => {
    let valid = true;
    let selfAge = null;
    let motherAge = null;
    let fatherAge = null;

    for (const member of familyMembers) {
      const memberDateOfBirth = new Date(member.dateOfBirth);
      const today = new Date();
      if (memberDateOfBirth > today) {
        valid = false;
        setFormError("Date of Birth cannot be a future date for any member.");
        break;
      }
      if (member.age !== null && member.age !== undefined && member.age !== NaN &&
        member.dateOfBirth !== null && member.dateOfBirth !== undefined && member.dateOfBirth !== '' &&
        member.relation !== '' && member.relation !== undefined && member.relation !== null) {

        if (member.relation === "Self") selfAge = member.age;
        if (member.relation === "Mother") motherAge = member.age;
        if (member.relation === "Father") fatherAge = member.age;
      } else {
        valid = false;
        break;
      }
    }

    if (valid && selfAge !== null && (motherAge !== null || fatherAge !== null)) {
      if ((motherAge && selfAge >= motherAge) || (fatherAge && selfAge >= fatherAge)) {
        valid = false;
        setFormError("The age of Self should be less than the age of Mother or Father.");
      }
    }

    if (valid) {
      props.formik.setFieldValue('healthInsurefor', JSON.stringify(familyMembers));
      SetInsurancemodalShow(false);
      setFormError("");
    } else {
      console.log('Please enter all the values or correct the age validation');
      formContext.notifyError("Please enter all the values correctly")
    }
  };

  //old
  // const handlePincodeChange = async (event) => {
  //   console.log("print propss pincode",props)
  //   const pincode = event.target.value;

  //   setPincodeError("");

  //   if (!/^\d*$/.test(pincode)) {
  //     setPincodeError("Pincode must contain only digits");
  //     return;
  //   }

  //   props.formik.setFieldValue("pincode", pincode);

  //   if (pincode.length === 6) {
  //     try {
  //       const response = await getCarePincodeData(pincode);
  //       // formContext.setsecondLoaderStatus(true);
  //       console.log("PR",formContext.setsecondLoaderStatus(true))
  //       console.log("Pincode Data:", JSON.parse(response.data));
  //       const pincodeData = JSON.parse(response.data);

  //       if (pincodeData?.data) {
  //         formContext.setPincodeData(pincodeData.data);
  //         setPincodeError("");
  //       } else {
  //         setPincodeError("Invalid pincode. Please enter a valid pincode.");
  //       }
  //     } catch (error) {
  //       console.error("API Error:", error);
  //       setPincodeError("No results found for this pincode");

  //     }
  //   } else if (pincode.length > 0) {

  //     setPincodeError("Pincode must be exactly 6 digits");
  //   } else {
  //     props.formik.setFieldValue("pincode", "");
  //   }

  // };

  //new-1
  // const handlePincodeChange = async (event) => {
  //   console.log("print propss pincode", props);
  //   const pincode = event.target.value;

  //   setPincodeError("");

  //   if (!/^\d*$/.test(pincode)) {
  //     setPincodeError("Pincode must contain only digits");
  //     return;
  //   }

  //   props.formik.setFieldValue("pincode", pincode);

  //   if (pincode.length === 6) {
  //     // Set loading state to true when fetching pincode data
  //     setLoading(true);

  //     try {
  //       const response = await getCarePincodeData(pincode);
  //       console.log("PR", formContext.setsecondLoaderStatus(true));
  //       console.log("Pincode Data:", JSON.parse(response.data));
  //       const pincodeData = JSON.parse(response.data);

  //       if (pincodeData?.data) {
  //         formContext.setPincodeData(pincodeData.data);
  //         setPincodeError("");
  //       } else {
  //         setPincodeError("Invalid pincode. Please enter a valid pincode.");
  //       }
  //     } catch (error) {
  //       console.error("API Error:", error);
  //       setPincodeError("No results found for this pincode");
  //     } finally {

  //       setLoading(false);
  //     }
  //   } else if (pincode.length > 0) {
  //     setPincodeError("Pincode must be exactly 6 digits");
  //   } else {
  //     props.formik.setFieldValue("pincode", "");
  //   }
  // };

  const handlePincodeChange = async (event) => {
    const pincode = event.target.value;

    setPincodeError("");

    // If the pincode is empty or less than 6 digits, stop loading and clear the error.
    if (pincode.length === 0 || pincode.length < 6) {
      setLoading(false); // Hide the spinner when pincode is removed or invalid
      props.formik.setFieldValue("pincode", pincode);
      return;
    }

    // Check if the pincode contains only digits
    if (!/^\d*$/.test(pincode)) {
      setPincodeError("Pincode must contain only digits");
      setLoading(false); // Ensure the spinner is hidden if input is invalid
      return;
    }

    // Set loading state to true when fetching pincode data
    setLoading(true);

    props.formik.setFieldValue("pincode", pincode);

    if (pincode.length === 6) {
      try {
        const response = await getCarePincodeData(pincode);
        console.log("PR", formContext.setsecondLoaderStatus(true));
        console.log("Pincode Data:", JSON.parse(response.data));
        const pincodeData = JSON.parse(response.data);

        if (pincodeData?.data) {
          formContext.setPincodeData(pincodeData.data);
          setPincodeError("");
        } else {
          setPincodeError("Invalid pincode. Please enter a valid pincode.");
        }
      } catch (error) {
        console.error("API Error:", error);
        setPincodeError("No results found for this pincode");
      } finally {
        // Hide the spinner after the data is fetched or if there was an error
        setLoading(false);
      }
    } else if (pincode.length > 0) {
      setPincodeError("Pincode must be exactly 6 digits");
    } else {
      props.formik.setFieldValue("pincode", "");
    }
  };


  useEffect(() => {
    if (props.formik.values.healthInsurefor !== '') {
      setFamilyMembers(JSON.parse(props.formik.values.healthInsurefor));
    }
  }, []);

  const popForInsurance = (
    <>
      <style>
        {`
          [type="radio"]:checked,
          [type="radio"]:not(:checked)
          {
            opacity: unset;
            display: unset;
          }
          .form-check
         {
           position: relative !important;
         }
        `}
      </style>
      <div className="">
        <div>

          {familyMembers.map((member, index) => {
            const isOnlySelf = familyMembers.length === 1 && member.relation === "Self";
            return (
              <div className="member-row" key={`member-row-${index}`}>
                <div className="member">
                  <div className="form-floating mb-2">
                    <select
                      id="idunique"
                      className="form-select"
                      value={member.relation}
                      disabled={index === 0}
                      onChange={(event) => handleRelationChange(event, member.id)}
                    >
                      <option>Select</option>
                      <option value="Self" selected={index === 0}>Self</option>
                      <option value="Spouse">Spouse</option>
                      <option value="Mother">Mother</option>
                      <option value="Father">Father</option>
                      <option value="Son">Son</option>
                      <option value="Daughter">Daughter</option>
                    </select>
                    <label htmlFor="idunique select-label">Relation</label>
                  </div>
                </div>
                <div className="member">
                  <div>
                    <FloatingLabel controlId="age" label="Age " className="mb-3" aria-autocomplete="off">
                      <Form.Control
                        name="age"
                        type="text"
                        placeholder=""
                        className="floating-input"
                        value={member.age}
                        disabled
                      />
                    </FloatingLabel>

                  </div>
                </div>
                <div className="member">
                  <div>
                    <FloatingLabel controlId="dateOfBirth" label="Date of birth " className="mb-3" aria-autocomplete="off">
                      <Form.Control
                        name="dateOfBirth"
                        type="date"
                        placeholder=""
                        className="floating-input"
                        value={member.dateOfBirth}
                        max={new Date()?.toISOString()?.slice(0, 10)}
                        onChange={(event) => handleDateOfBirthChange(event, member.id)}
                      />
                      {member.dateOfBirthError && (
                        <div style={{ color: 'red', fontSize: '14px', marginTop: '5px' }}>
                          {member.dateOfBirthError}
                        </div>
                      )}
                    </FloatingLabel>
                  </div>
                </div>
                {!isOnlySelf && (
                  <div className="delete-icon-wrap">
                    <SVG className="delete-icon" src={deleteIcon} onClick={() => handleDeleteMember(member.id)} />
                  </div>
                )}
              </div>
            );
          })}

          <div className="text-center mb-3">
            <Button className="add-member-btn" onClick={addFamilyMember}>+ OTHER FAMILY MEMBERS</Button>
          </div>
          <div className="text-center">
            <Button className="primary-btn" onClick={handleSubmitMembers}>Submit</Button>
          </div>
        </div>
      </div>
      {formError && <div className="error-message d-flex justify-content-center" style={{ color: 'red', fontSize: '13px', fontWeight: '400', marginTop: '10px' }}>{formError}</div>}
    </>
  );

  const popForPinCode = (
    <>
      <div className="">
        <div className="d-flex pincode-wrap">
          <SVG className="location-icon" src={location}></SVG>
          <div className="input-wrap">

            <FloatingLabel controlId="floatingPassword" label="Pincode" className="mb-1">
              <Form.Control
                type="text"
                placeholder="Pincode"
                className="floating-input"
                onClick={() => setModalShow(true)}
                minLength={6}
                maxLength={6}
                onChange={handlePincodeChange}
              />
            </FloatingLabel>
            {pincodeError && <p className="error-text" style={{ color: 'red', fontSize: '13px', fontWeight: '400', textAlign: "left" }}>{pincodeError}</p>}
            {!pincodeError && props.formik.touched.pincode && props.formik.errors.pincode && (
              <p className="error-text" style={{ color: 'red', fontSize: '13px', fontWeight: '400', textAlign: "left" }}>{props.formik.errors.pincode}</p>
            )}
          </div>
        </div>
        <div className="text-center">
          <Button className="primary-btn" onClick={() => setModalShow(false)}>
            Submit
          </Button>
        </div>
      </div>
    </>
  );

  return (
    <div className="d-flex justify-content-center">
      <Form as="div" className="form-ui p-4" style={{ backgroundColor: '#f8f9fa', borderRadius: '8px', width: '100%', maxWidth: '600px' }}>
        <Row>
          {/* gender */}
          <Col md={12} sm={12} className='mb-3'>
            <div className="form-group gender-checkbox-group">
              <span className="d-flex justify-content-left" style={{ fontSize: "13px", color: "grey" }}>Gender</span>
              <div className="checkbox-options">
                <div className="policy-type">
                  <Form.Check
                    inline
                    label="Male"
                    name="gender"
                    type="radio"
                    id="gender-male"
                    value="Male"
                    checked={props.formik.values.gender === "Male"}
                    onChange={(event) => props.formik.setFieldValue("gender", event.target.value)}
                  />
                  <Form.Check
                    inline
                    label="Female"
                    name="gender"
                    type="radio"
                    id="gender-female"
                    value="Female"
                    checked={props.formik.values.gender === "Female"}
                    onChange={(event) => props.formik.setFieldValue("gender", event.target.value)}
                  />

                  <Form.Check
                    inline
                    label="Other"
                    name="gender"
                    type="radio"
                    id="gender-other"
                    value="Other"
                    checked={props.formik.values.gender === "Other"}
                    onChange={(event) => props.formik.setFieldValue("gender", event.target.value)}
                  />
                </div>
              </div>
            </div>
            <ErrorMessage formik={props.formik} fieldValue="gender" />
          </Col>


          {/* Policy Term Dropdown */}
          <Col md={12} sm={12} className='mb-3'>
            <InputFieldDropdown
              formikFieldName="policyTerm"
              label="Policy Term"
              optionsArray={policyTermOptions}
              formik={props.formik}
            />
            <ErrorMessage formik={props.formik} fieldValue="policyTerm" />
          </Col>


          <Col md={12} sm={12} className='mb-3'>
            <InputFieldDropdown
              formikFieldName="sumInsured"
              label="Cover"
              optionsArray={hospitalLimitValues}
              formik={props.formik}
            />
            <ErrorMessage formik={props.formik} fieldValue="sumInsured" />
          </Col>


          <Col md={12} sm={12} className="mb-3">
            <div className="d-flex pincode-wrap">
              <div className="input-wrap position-relative">
                <FloatingLabel controlId="floatingPassword" label="Pincode" className="mb-1">
                  <Form.Control
                    type="text"
                    placeholder="Pincode"
                    className="floating-input"
                    minLength={6}
                    maxLength={6}
                    value={props.formik.values.pincode}
                    onChange={handlePincodeChange}
                    onBlur={props.formik.handleBlur}
                  />
                </FloatingLabel>
                {pincodeError && (
                  <p
                    className="error-text"
                    style={{
                      color: "red",
                      fontSize: "13px",
                      fontWeight: "400",
                      textAlign: "left",
                    }}
                  >
                    {pincodeError}
                  </p>
                )}
                {!pincodeError && props.formik.touched.pincode && props.formik.errors.pincode && (
                  <p
                    className="error-text"
                    style={{
                      color: "red",
                      fontSize: "13px",
                      fontWeight: "400",
                      textAlign: "left",
                    }}
                  >
                    {props.formik.errors.pincode}
                  </p>
                )}


                {loading && (
                  <div
                    className="spinner-border text-primary position-absolute"
                    style={{ right: "10px", top: "50%", }}
                    role="status"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                )}
              </div>
            </div>
          </Col>


          <Col md={12} sm={12} className='mb-3'>
            <div className="health-input">
              <p className="text-left-align">
                Insured Members{" "}
                <span className="change-member" onClick={() => SetInsurancemodalShow(true)}>
                  [Update]
                </span>
              </p>
              <div className="insuranceFor">
                {familyMembers.map((member, index) => (
                  <p className="text-left-align font-weight-bold" key={index}>
                    {`${member.relation} - 
                    ${index === 0 ? props.formik.values.gender
                        : ['Daughter', 'Mother'].includes(member.relation)
                          ? 'Female'
                          : ['Son', 'Father'].includes(member.relation)
                            ? 'Male'
                            : ['Spouse'].includes(member.relation) && props.formik.values.gender === 'Male'
                              ? 'Female'
                              : 'Male'} - 
                    ${member.age ? member.age + "" : ''} `}
                  </p>

                ))}
              </div>
            </div>
            <VerticallyCenteredModal
              show={InsurancemodalShow}
              onHide={() => SetInsurancemodalShow(false)}
              heading="Add your family members"
            >

              {popForInsurance}
            </VerticallyCenteredModal>
            <ErrorMessage formik={props.formik} fieldValue="healthInsurefor" />

          </Col>
          <Col md={12} sm={12}>
            <InputFieldDropdown
              formikFieldName="existanceIllness"
              label="Existing Illness"
              optionsArray={[
                { value: "No", label: "No" },
                { value: "Yes", label: "Yes" },
              ]}
              formik={props.formik}
            />
            <ErrorMessage formik={props.formik} fieldValue="existanceIllness" />
          </Col>


          <Col md={12} sm={12}>
            {props.formik.values.existanceIllness === "Yes" && (
              <div className="health-input">
                <p className="text-left-align">Medical History</p>
                <p className="text-left-align">
                  Do any member(s) have any illness for which they take any medications.
                </p>
                <Row className="custom-checkbox">
                  <Col lg={4} md={6} sm={6}>
                    <InputFieldCheckBox
                      formik={props.formik}
                      formikFieldName="illnesses.diabetes"
                      label="Diabetes"
                      checkedValue={props.formik.values.illnesses.diabetes}
                    />
                  </Col>

                  <Col lg={4} md={6} sm={6}>
                    <InputFieldCheckBox
                      formik={props.formik}
                      formikFieldName="illnesses.bloodPressure"
                      label="Blood Pressure"
                      checkedValue={props.formik.values.illnesses.bloodPressure}
                    />
                  </Col>
                  <Col lg={4} md={6} sm={6}>
                    <InputFieldCheckBox
                      formik={props.formik}
                      formikFieldName="illnesses.anySurgery"
                      label="Any Surgery"
                      checkedValue={props.formik.values.illnesses.anySurgery}
                    />
                  </Col>
                  <Col lg={4} md={6} sm={6}>
                    <InputFieldCheckBox
                      formik={props.formik}
                      formikFieldName="illnesses.thyroid"
                      label="Thyroid"
                      checkedValue={props.formik.values.illnesses.thyroid}
                    />
                  </Col>
                  <Col lg={4} md={6} sm={6}>
                    <InputFieldCheckBox
                      formik={props.formik}
                      formikFieldName="illnesses.asthma"
                      label="Asthma"
                      checkedValue={props.formik.values.illnesses.asthma}
                    />
                  </Col>
                  <Col lg={4} md={6} sm={6}>
                    <InputFieldCheckBox
                      formik={props.formik}
                      formikFieldName="illnesses.otherDiseases"
                      label="Other disease"
                      checkedValue={props.formik.values.illnesses.otherDiseases}
                    />
                  </Col>
                </Row>
                <ErrorMessage formik={props.formik} fieldValue="illnesses" />
              </div>
            )}
          </Col>
        </Row>
      </Form>
    </div>

  )
}















