import React, { useState, useEffect } from "react";
import { Row, Col, Form } from "react-bootstrap";
import ErrorMessage from "../../commonModules/ErrorMessage";
import InputFieldDropdown from "../../commonModules/InputFieldDropdown";
import InputFieldText from "../../commonModules/InputFieldText";
import { formikFieldVisibility } from "../../commonModules/CommonCode";

const MemberDetails = (props) => {
  const [openMemberId, setOpenMemberId] = useState(null);
  const [members, setMembers] = useState([]);

  useEffect(() => {
    props.formik.values.familyDetails?.forEach((member, index) => {
      props.formik.setFieldTouched(`familyDetails.${index}.name`, false);
      props.formik.setFieldTouched(`familyDetails.${index}.dateOfBirth`, false);
      props.formik.setFieldTouched(`familyDetails.${index}.gender`, false);
      props.formik.setFieldTouched(`familyDetails.${index}.heightFt`, false);
      props.formik.setFieldTouched(`familyDetails.${index}.heightIn`, false);
      props.formik.setFieldTouched(`familyDetails.${index}.weight`, false);
    })
  }, []);

  // Fetch member details from formik or initialDataForProposal
  useEffect(() => {
    const insuredMembers = props.formik?.values?.familyDetails || props.initialDataForProposal?.familyDetails;
    setMembers(insuredMembers);
    console.log("Fetched insured members: ", insuredMembers);
  }, [props.formik?.values?.familyDetails]);

  const handleToggleCollapse = (memberId) => {
    setOpenMemberId(openMemberId === memberId ? null : memberId);
  };

  const handleFieldChange = (field, value, memberId) => {
    props.formik.setFieldValue("familyDetails", props.formik.values.familyDetails.map((member) => {
      if (memberId === member.id) {
        member[field] = value;
      }
      return member;
    }));
  };

  // Dynamically render fields for each member
  return (
    <div className="bg-white p-3 radius-12 mt-3 mb-3" style={{ textAlign: "left" }}>
      {props.formik?.values?.familyDetails?.map((member, index) => (
        <div key={member.id}>
          <Row>
            <Col sm={12} md={12} lg={12} className="pt-4">
              <label className="mb-1">{member.relation}</label>
              <p className="text-grey mb-0">{`Provide the details for your ${member.relation.toLowerCase()}`}</p>
              <button
                type="button"
                className="mt-3"
                style={{ fontSize: "13px", fontWeight: "600" }}
                onClick={() => handleToggleCollapse(member.id)}
              >
                {openMemberId === member.id ? "Hide Details" : "Fill Details"}
              </button>
            </Col>
          </Row>

          {/* Collapsible member details */}
          {openMemberId === member.id && (
            <>
              <div className="row">
                {member.relation !== "Self" && (
                  <Col sm={12} md={6} lg={6}>
                    <InputFieldText
                      formikFieldName={`familyDetails.${index}.name`}
                      placeholder={`Enter ${member.relation}'s full name`}
                      formik={props.formik}
                      value={member.name}
                      onChange={(e) => handleFieldChange(`familyDetails.${index}.name`, e.target.value, member.id)}
                    />
                    {props.formik.touched?.familyDetails?.[index]?.name && props.formik.errors?.familyDetails?.[index]?.name &&
                      <Form.Control.Feedback type="invalid" className="d-block" style={{ color: '#dc3545' }}>
                        {props.formik.errors.familyDetails[index].name}
                      </Form.Control.Feedback>}
                  </Col>
                )}

                {/* Date of Birth Field - Predefined */}
                <Col sm={12} md={6} lg={6} className="mb-4">
                  <InputFieldText
                    formikFieldName={`familyDetails.${index}.dateOfBirth`}
                    placeholder="D.O.B (DD-MM-YYYY)"
                    type="date"
                    formik={props.formik}
                    value={member.dateOfBirth || ""} // If member.dateOfBirth is available, use it; else default to an empty string
                    onChange={(e) => handleFieldChange(`familyDetails.${index}.dateOfBirth`, e.target.value, member.id)}
                  // Assuming you want to keep it disabled (as per original code)
                  />
                  {props.formik.touched?.familyDetails?.[index]?.dateOfBirth && props.formik.errors?.familyDetails?.[index]?.dateOfBirth &&
                    <Form.Control.Feedback type="invalid" className="d-block" style={{ color: '#dc3545' }}>
                      {props.formik.errors.familyDetails[index].dateOfBirth}
                    </Form.Control.Feedback>}
                </Col>

                {/* Gender Dropdown */}
                <Col sm={12} md={6} lg={6} className="mb-4">
                  <InputFieldDropdown
                    formikFieldName={`familyDetails.${index}.gender`}
                    label="Gender"
                    optionsArray={[
                      { value: "", label: "Select Gender" },
                      { value: "Male", label: "Male" },
                      { value: "Female", label: "Female" },
                      { value: "Other", label: "Other" },
                    ]}
                    formik={props.formik}
                    value={member.gender}
                    onChange={(e) => handleFieldChange(`familyDetails.${index}.gender`, e.target.value, member.id)}
                  />
                  {props.formik.touched?.familyDetails?.[index]?.gender && props.formik.errors?.familyDetails?.[index]?.gender &&
                    <Form.Control.Feedback type="invalid" className="d-block" style={{ color: '#dc3545' }}>
                      {props.formik.errors.familyDetails[index].gender}
                    </Form.Control.Feedback>}
                </Col>

                {/* Height (Feet & Inches) */}
                <Col sm={12} md={6} lg={6} className="mb-4">
                  <Row>
                    <Col md={6} lg={6} sm={12}>
                      <InputFieldDropdown
                        formikFieldName={`familyDetails.${index}.heightFt`}
                        label="Height (Feet)"
                        optionsArray={[
                          { value: "", label: "Select" },
                          { value: "1", label: "1 Feet" },
                          { value: "2", label: "2 Feet" },
                          { value: "3", label: "3 Feet" },
                          { value: "4", label: "4 Feet" },
                          { value: "5", label: "5 Feet" },
                          { value: "6", label: "6 Feet" },
                          { value: "7", label: "7 Feet" },
                          { value: "8", label: "8 Feet" },
                        ]}
                        formik={props.formik}
                        value={member.heightFt}
                        onChange={(e) => handleFieldChange(`familyDetails.${index}.heightFt`, e.target.value, member.id)}
                      />
                      {props.formik.touched?.familyDetails?.[index]?.heightFt && props.formik.errors?.familyDetails?.[index]?.heightFt &&
                        <Form.Control.Feedback type="invalid" className="d-block" style={{ color: '#dc3545' }}>
                          {props.formik.errors.familyDetails[index].heightFt}
                        </Form.Control.Feedback>}
                    </Col>

                    <Col md={6} lg={6} sm={12}>
                      <InputFieldDropdown
                        formikFieldName={`familyDetails.${index}.heightIn`}
                        label="Height (Inches)"
                        optionsArray={[
                          { value: "", label: "Select" },
                          { value: "0", label: "0 Inch" },
                          { value: "1", label: "1 Inch" },
                          { value: "2", label: "2 Inch" },
                          { value: "3", label: "3 Inch" },
                          { value: "4", label: "4 Inch" },
                          { value: "5", label: "5 Inch" },
                          { value: "6", label: "6 Inch" },
                          { value: "7", label: "7 Inch" },
                          { value: "8", label: "8 Inch" },
                          { value: "9", label: "9 Inch" },
                          { value: "10", label: "10 Inch" },
                          { value: "11", label: "11 Inch" },
                        ]}
                        formik={props.formik}
                        value={member.heightIn}
                        onChange={(e) => handleFieldChange(`familyDetails.${index}.heightIn`, e.target.value, member.id)}
                      />
                      {props.formik.touched?.familyDetails?.[index]?.heightIn && props.formik.errors?.familyDetails?.[index]?.heightIn &&
                        <Form.Control.Feedback type="invalid" className="d-block" style={{ color: '#dc3545' }}>
                          {props.formik.errors.familyDetails[index].heightIn}
                        </Form.Control.Feedback>}
                    </Col>
                  </Row>
                </Col>

                {/* Weight Field */}
                <Col sm={12} md={6} lg={6}>
                  <InputFieldText
                    formikFieldName={`familyDetails.${index}.weight`}
                    placeholder="Weight (KG)"
                    type="number"
                    formik={props.formik}
                    value={member.weight || ""}
                    onChange={(e) => handleFieldChange(`familyDetails.${index}.weight`, e.target.value, member.id)}
                  />
                  {props.formik.touched?.familyDetails?.[index]?.weight && props.formik.errors?.familyDetails?.[index]?.weight &&
                    <Form.Control.Feedback type="invalid" className="d-block" style={{ color: '#dc3545' }}>
                      {props.formik.errors.familyDetails[index].weight}
                    </Form.Control.Feedback>}
                </Col>
              </div>
              {props.selectedHealthQuoteData?.insuranceCompany === "Care Health Insurance" &&
                member.relation === "Self" &&
                <div className="row">
                  <Col sm={12} md={6} lg={6}>
                    <InputFieldText
                      formikFieldName={`bankAccountNumber`}
                      placeholder={`Bank account number`}
                      formik={props.formik}
                    />
                    <ErrorMessage formik={props.formik} fieldValue="bankAccountNumber" />
                  </Col>
                  <Col sm={12} md={6} lg={6}>
                    <InputFieldText
                      formikFieldName={`bankIFSCCode`}
                      placeholder={`IFSC Code`}
                      formik={props.formik}
                      capitalize={true}
                    />
                    <ErrorMessage formik={props.formik} fieldValue="bankIFSCCode" />
                  </Col>
                </div>}
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default MemberDetails;