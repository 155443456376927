import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
} from "react-bootstrap";
import ErrorMessage from "../../commonModules/ErrorMessage";
import InputFieldDropdown from "../../commonModules/InputFieldDropdown";
import InputFieldText from "../../commonModules/InputFieldText";
import { formikFieldVisibility } from "../../commonModules/CommonCode";
import UseFormContext from "../../../context/UseFormContext";
export default function ContactInfo(props) {
  const formContext = UseFormContext();
  const [gender, setGender] = useState([
    { value: "", label: "Please Select Gender" },
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
    { value: "F", label: "Female" },
    { value: "M", label: "Male" },
    { value: "FEMALE", label: "Female" },
    { value: "MALE", label: "Male" },
  ]);

  const [maritalStatus, setMaritalStatus] = useState([
    { value: "", label: "Please Select" },
    { value: "married", label: "Married" },
    { value: "single", label: "Single" },
  ])

  const [kycDetails, setKYCDetails] = useState(props.kycDetails);
  console.log("KYCCCCCCCCCCCCCCC Details", kycDetails)
  useEffect(() => {
    formikFieldVisibility(["ownerPrefix", "ownerFirstName", "ownerLastName", "ownerEmail", "ownerPhoneNumber", "ownerDob", "ownerGender", "ownerMaritialStus", "ownerGstin"], props.formik)
    defaultValueForFields()
  }, [kycDetails]);

    useEffect(() => {
      if (props.formik.values.ownerPhoneNumber) {
        localStorage.setItem('customerphoneno', props.formik.values.ownerPhoneNumber);
        console.log("phone number",props.formik.values.ownerPhoneNumber)
      }
      if (!props?.formik?.values?.ownerMiddleName && formContext?.proposalData) {
        props.formik.setFieldValue("ownerMiddleName", formContext?.proposalData?.proposalRequest?.proposerDetails?.middleName ? formContext?.proposalData?.proposalRequest?.proposerDetails?.middleName?.trim() : "NA")
      }
  
    }, [props.formik.values.ownerPhoneNumber]);

  const defaultValueForFields = () => {
    if (formContext.kycApiRes) {
      const nestedData = formContext?.kycApiRes?.kyc_data?.CKYC?.result?.PERSONAL_DETAILS;
      console.log("nestedData", nestedData)
      const fullName = kycDetails?.firstName.split(" ");

      // Set values from KYC response
      console.log("inside defaultValueForFields", kycDetails)
      // props.formik.setFieldValue("prefix", kycDetails?.prefix);
      props.formik.setFieldValue("prefix", kycDetails?.prefix || "");
      props.formik.setFieldValue("firstName", kycDetails?.firstName);
      props.formik.setFieldValue("lastName", kycDetails?.lastName);
      props.formik.setFieldValue("ownerPhoneNumber", nestedData?.MOB_NUM ? nestedData?.MOB_NUM : "");
      props.formik.setFieldValue("ownerEmail", kycDetails?.email);
      props.formik.setFieldValue("dob", kycDetails?.dob);
      props.formik.setFieldValue("kycNomineGender", `${nestedData?.GENDER === "M" ? "male" : "female"}`);
    } else {
      //   // If KYC data is not available, use fallback data from healthQuoteFormikData
      props.formik.setFieldValue("ownerEmail", props.healthQuoteFormikData?.email || "");
      props.formik.setFieldValue("ownerFirstName", props.healthQuoteFormikData?.firstName || "");
      props.formik.setFieldValue("ownerLastName", props.healthQuoteFormikData?.lastName || "");
      props.formik.setFieldValue("ownerPhoneNumber", props.healthQuoteFormikData?.phoneNumber || "");
      props.formik.setFieldValue("ownerPrefix", "Mr");

    }
  }


  return (
    <React.Fragment>
      <div>
        <Row>
          {/* new */}
          <Col sm={4} className="mb-4">
            {kycDetails?.prefix ? (
              // If prefix exists in the KYC object, render a text input (readonly)
              <InputFieldText
                formikFieldName="prefix"
                placeholder="Prefix*"
                formik={props.formik}
                disabled={true}
              />
            ) : (
              // If prefix is not in the KYC object, render a dropdown
              <InputFieldDropdown
                formikFieldName="prefix"
                label="Prefix*"
                optionsArray={[
                  { value: "", label: "Please Select" },
                  { value: "MISS", label: "Ms." },
                  { value: "MR", label: "Mr." },
                  { value: "MRS", label: "Mrs." },
                ]}
                formik={props.formik}
              />
            )}
            <ErrorMessage formik={props.formik} fieldValue="prefix" />
          </Col>



          {/* old */}
          {/* <Col sm={4} className="mb-4">
            <InputFieldText formikFieldName="prefix" placeholder="prefix*" formik={props.formik} />
            <InputFieldDropdown
              formikFieldName="prefix"
              label="Prefix*"
              optionsArray={[{ value: "", label: "Please Select" }, { value: "MISS", label: "MISS" }, { value: "MR", label: "MR" }, { value: "MS", label: "MS" }]}
              formik={props.formik}
            />
            <ErrorMessage formik={props.formik} fieldValue="prefix" />
          </Col> */}

          <Col sm={4} className="mb-4">
            <InputFieldText formikFieldName="firstName" placeholder="First name*" formik={props.formik} />
            <ErrorMessage formik={props.formik} fieldValue="firstName" />
          </Col>

          <Col sm={4} className="mb-4">
            <InputFieldText formikFieldName="lastName" placeholder="Last Name*" formik={props.formik} />
            <ErrorMessage formik={props.formik} fieldValue="lastName" />
          </Col>

          <Col sm={4} className="mb-4">
            <InputFieldText formikFieldName="email" placeholder="Email id*" formik={props.formik} />
            <ErrorMessage formik={props.formik} fieldValue="email" />
          </Col>

          <Col sm={4} className="mb-4">
            <InputFieldText formikFieldName="ownerPhoneNumber" placeholder="Mobile number*" formik={props.formik} onlyNumber={true}
            />
            <ErrorMessage formik={props.formik} fieldValue="ownerPhoneNumber" />
          </Col>

          <Col sm={4} className="mb-4">
            <InputFieldText formikFieldName="kycNomineDob" placeholder="Date of birth*" formik={props.formik} type="date" disabled={true}
            />
            <ErrorMessage formik={props.formik} fieldValue="kycNomineDob" />
          </Col>

          <Col sm={4} className="mb-4">
            <InputFieldDropdown formikFieldName="kycNomineGender" label="Gender*" optionsArray={gender} formik={props.formik} disabled={false} />
            <ErrorMessage formik={props.formik} fieldValue="kycNomineGender" />
          </Col>
          <Col sm={4} className="mb-4">
            <InputFieldDropdown formikFieldName="ownerMaritialStus" label="Marital status*" optionsArray={maritalStatus} formik={props.formik} />
            <ErrorMessage formik={props.formik} fieldValue="ownerMaritialStus" />
          </Col>
          <Col sm={4} className="mb-4">
            <InputFieldText formikFieldName="ownerGstin" placeholder="GSTIN" formik={props.formik} />
            <ErrorMessage formik={props.formik} fieldValue="ownerGstin" />
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}