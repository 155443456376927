import axios from "axios";
import CryptoJS from "crypto-js";
import { convertDate, gateage } from "../../components/commonModules/CommonCode";
const { format, parseISO } = require('date-fns');
export const rtoBaseUrl = process.env.REACT_APP_RTO_API_URL
export const rtoLoginUrl = `login`
export const projectBaseurl = process.env.REACT_APP_BASE_URL
export const getCitiesUrl = `/cxf/configmanager/config/getconfigdata`
export const createQote = `/cxf/authrestservices/integrate/invoke`
export const getRtodetails = '/cxf/authrestservices/integrate/invoke'
export const getpolicyName = '/cxf/Insuranceservice/request/integrate/invoke'
export const kycBaseurl = `/cxf/kycservice/request/integrate/invoke`
export const addressUrl = `https://api.postalpincode.in/pincode/`
export const mobileNumberurl = `/cxf/Otpservice/request/integrate/invoke`


export function careKycVerify(values) {
  console.log("yoooo", values);

  const isPanSelected = values?.kycType === "pan";
  const isAadharSelected = values?.kycType === "aadhar";
  const consent_pur = "this is a consent purpose string pass anything"
  const formattedDob = isPanSelected
    ? values?.kycNomineDob
    : convertDate(values?.kycNomineDob, 3);
  console.log("formattedDob", formattedDob)

  const request = {
    CareKYCRequest: {
      panNum: isPanSelected ? values?.kycNominePanNumber : undefined,
      dob: formattedDob,
      id_number: isPanSelected ? values?.kycNominePanNumber : undefined,
      consent_purpose: isAadharSelected ? values?.consent_purpose : consent_pur,
      aadharno: isAadharSelected ? values?.kycNomineAadharNumber : undefined,
      name: isAadharSelected ? values?.kycNomineFullName : undefined,
      gender: values?.kycNomineGender,
    },
  };
  return axios.post(projectBaseurl + kycBaseurl, request);
}

export function careKycOVD(fileName, DocString, type, proposalNo) {

  let docStr = DocString.split('base64');
  docStr = docStr?.[1]?.substring(1) ?? docStr[0]

  const request = {
    "CareKYCRequest": {
      "proposalNumber": proposalNo,
      "documentName": fileName,
      "isBase64": "Y",
      "documentData": docStr,
      "docType": type

    }
  }
  return axios.post(projectBaseurl + kycBaseurl, request);
}

export function careBankDetailsEncrypt(inputText) {
  const initializationVector = process.env.REACT_APP_CAREHEALTH_BANKDETAILS_ENC_IV;
  const secretKey = process.env.REACT_APP_CAREHEALTH_BANKDETAILS_ENC_SECRET_KEY;

  if (!inputText || !initializationVector || !secretKey) {
    return;
  }

  if (initializationVector.length !== 16 || secretKey.length !== 32) {
    return;
  }

  const key = CryptoJS.enc.Utf8.parse(secretKey);
  const iv = CryptoJS.enc.Utf8.parse(initializationVector);

  const encrypted = CryptoJS.AES.encrypt(inputText, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  });

  const base64CipherText = encrypted.toString();

  return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(base64CipherText));
}

