import React, { useEffect, useState } from "react";
import { IoPersonCircleOutline } from "react-icons/io5";
import Progress from "../../images/progress.jpg";
import { MdDirectionsBike } from "react-icons/md";
import { useHistory, useLocation, useParams } from "react-router-dom";
import Table from "react-bootstrap/Table";
import { Button, Form, Row, Col, FloatingLabel, Spinner, Modal } from "react-bootstrap";
import Godigit from "../../images/Godigit.png";
import * as api from "../../API/authCurd";
import futureGenrali from "../../images/futureGenrali.png";
import ICICI from "../../images/ICICI-insurance.png";
import reliance from "../../images/reliance.png";
import NewIndia from "../../images/new-india.png";
import UseFormContext from "../../context/UseFormContext";
import UpdatePassword from "./UpdatePassword";
import { PageNotFound } from "../commonModules/PageNotFound";
import axios from "axios";
import Bajaj from "../../images/bajaj.png";
import HDFC from "../../images/hdfc-ergo-gi.png";
import AdminService from "../../services/admin.service";
import Validations from "../../validations/validations";
import { useFormik } from "formik";
import { decryptData } from "../../services/auth-service";
import { convertDate } from "../commonModules/CommonCode";
import bike from "../../../src/images/bike.png";
import car from "../../../src/images/car1.png";
import InputFieldText from "../commonModules/InputFieldText";
import InputFieldDropdown from "../commonModules/InputFieldDropdown";
import ErrorMessage from "../commonModules/ErrorMessage";
import * as Yup from "yup";
import { MdEdit } from "react-icons/md";
import { FaSearch, FaTimes } from "react-icons/fa";
import { DateRangePicker } from "rsuite";
import careLogo from "../../images/careLogo.jpg"
import "rsuite/dist/rsuite.css";
import ModalGeneratePolicy from "../pages/Health/PolicyGenerateModal.js"
import { createCarPolicyDownload } from "../../API/authCurd.js"
import { createBikePolicyDownload } from "../../API/authCurd.js"
//import { policyDownloadRepository} from "../../API/authCurd.js"
import healthImage from "../pages/Health/images.png"
import "./Health/CSS/MyprofileStyle.css"

var CryptoJS = require("crypto-js");

export const MyProfile = () => {
  const { id } = useParams();
  const [mobile, setMobile] = useState();
  const [profileData, setProfileData] = useState([]);
  // const [showPopup, setShowPopup] = useState(false);
  const [removePolicy, setRemovePolicy] = useState(false)
  const [initialData, setInitialData] = useState({
    firstName: "",
    lastName: "",
    EmailId: "",
    mobileNumber: "",
    gender: "",
    maritalStatus: "",
  });
  const [toggle, setToggle] = useState(1);
  const [isEditing, setIsEditing] = useState(false);
  const [user, setUser] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showModal, setShowModal] = useState(false)
  const [modalMessage, setModalMessage] = useState('');
  const formContext = UseFormContext();
  const validationSchema = Validations.userValidations;


  const [policyDetailsData, setPolicyDetailsData] = useState([]);

  const [proposalData, setproposalData] = useState([]);
  const [quoteDataOriginal, setQuoteDataOriginal] = useState([]);
  const [proposalDataOriginal, setproposalDataOriginal] = useState([]);
  const [policyDetailsDataOriginal, setPolicyDetailsDataOriginal] = useState([]);
  const [policyDowloadRepository, setPolicyDetailsRepository] = useState([]);
  const [policyDowloadRepositoryy, setPolicyDowloadRepository] = useState([]);
  const [showPopup, setShowPopup] = useState(false);
  const [generatedPolicyDetails, setGeneratedPolicyDetails] = useState(null);
  const [healthProposal, setHealthProposal] = useState([]);


  const [loaderStatus, setloaderStatus] = useState(false);
  const [buyNowLoader, setbuyNowLoader] = useState();
  const [buyNowIndex, setbuyNowIndex] = useState();
  //loader 
  const [isLoading, setIsLoading] = useState(false);
  const [gender, setGender] = useState([
    { value: "", label: "Please Select" },
    { value: "male", label: "Male" },
    { value: "female", label: "Female" },
  ]);
  const [maritalStatus, setMaritalStatus] = useState([
    { value: "", label: "Please Select" },
    { value: "married", label: "Married" },
    { value: "single", label: "Single" },
  ]);
  const location = useLocation();
  const history = useHistory();
  const imgArrayForCard = {
    "Reliance General Insurance Co. Ltd": reliance,
    "New India Assurance": NewIndia,
    "Future Generali India Insurance Co. Ltd": futureGenrali,
    "GO DIGIT General Insurance CO. LTD": Godigit,
    "ICICI Lombard General Insurance Co. Ltd": ICICI,
    "Bajaj Allianz General Insurance Co. Ltd": Bajaj,
    "HDFC ERGO General Insurance Company": HDFC,
    "Care Health Insurance": careLogo
  };

  const [searchParams, setSearchParams] = useState({
    searchInput: "",
    startDate: "",
    endDate: "",
  });

  const [searchParamsForPolicy, setSearchParamsForPolicy] = useState({
    searchInput: "",
    startDate: "",
    endDate: "",
  });

  const [searchParamsForProposal, setSearchParamsForProposal] = useState({
    searchInput: "",
    startDate: "",
    endDate: "",
  });

  const [value, setValue] = useState();
  const [valuePolicy, setValuePolicy] = useState();
  const [valueProposal, setValueProposal] = useState();


  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tabValue = queryParams.get("tab");
    if (tabValue) {
      setToggle(tabValue);
      if (tabValue == 5) updateToggle(5);
      else if (tabValue == 7) updateToggle(7);
      else if (tabValue == 9) updateToggle(9);
    }
    // CRM proposaltask
    const proposalValue = queryParams.get("proposalId");
    const mobNumber = queryParams.get("mob");
    if (proposalValue && mobNumber) {
      const encryptNo = CryptoJS.AES.encrypt(
        mobNumber,
        "dynamipass"
      ).toString();
      sessionStorage.setItem("userLoggin", encryptNo);
      localStorage.setItem("ownerPhoneNumber", mobNumber);
      getProposalDetsil(proposalValue);
    }
  }, []);

  useEffect(() => {
    getPoliciesList();
    getPolicyDownloadRepository()
  }, [history]);

  useEffect(() => {
    if (id) {
      document.getElementById("Prosposal").click();
    }
  }, [id]);

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const tabValue = queryParams.get("tab");
    if (!tabValue) {
      updateToggle(1);
    }
  }, []);

  useEffect(() => {
    console.log("remove Policy")
  }, [removePolicy])


  const handleModalClose = () => setShowModal(false);
  const handleModalShow = () => setShowModal(true);

  const decryptUser = async () => {
    setMobile(
      localStorage.getItem("ownerPhoneNumber1")
        ? localStorage.getItem("ownerPhoneNumber1")
        : localStorage.getItem("ownerPhoneNumber1")
    );
    const encryptedUser = localStorage.getItem("user");
    if (encryptedUser) {
      try {
        const user1 = await decryptData(encryptedUser);

        setUser(user1);
        fetchData(user1);
        return user1;
      } catch (error) {
        console.error("Error decrypting user:", error);
      }
    }
    // else{
    //   history.push("/signin");
    //   console.error("Error");
    // }
  };

  const handleGeneratePolicy = (details) => {
    setIsLoading(true);
    if (details?.businessLineId === 2) {
      // For bike policy
      createBikePolicyDownload(details?.paymentResponse?.apPreferId, details?.paymentResponse?.proposalId, details?.paymentResponse?.Amount, details?.paymentResponse?.transactionNo)
        .then(response => {
          setGeneratedPolicyDetails({
            policyNo: response.data?.data?.policyNo,
            proposalId: response.data?.data?.proposalId,
          });
          setIsModalOpen(true);
          setPolicyDowloadRepository(prevData => prevData.filter(item => item.paymentResponse.proposalId !== details?.paymentResponse?.proposalId));
        })
        .catch(err => {
          console.error('Error generating bike policy:', err);
        }).finally(() => {
          setIsLoading(false); // Hide loader after API call completes
        });
    } else if (details?.businessLineId === 3) {
      // For car policy
      createCarPolicyDownload(details?.paymentResponse?.apPreferId, details?.paymentResponse?.proposalId, details?.paymentResponse?.Amount, details?.paymentResponse?.transactionNo)
        .then(response => {
          setGeneratedPolicyDetails({
            policyNo: response.data?.data?.policyNo,
            proposalId: response.data?.data?.proposalId,
          });
          setIsModalOpen(true);
          setPolicyDowloadRepository(prevData => prevData.filter(item => item.paymentResponse.proposalId !== details?.paymentResponse?.proposalId));
        })
        .catch(err => {
          console.error('Error generating car policy:', err);
        }).finally(() => {
          setIsLoading(false); // Hide loader after API call completes
        });
    }
  };

  //this code working for UAT and Prod
  const getPoliciesList = () => {
    const userLogin = sessionStorage.getItem("userLoggin");

    if (userLogin) {
      var bytes = CryptoJS.AES.decrypt(userLogin, "dynamipass");
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      formContext.setloaderStatus(true);

      api.downloadPolicy(decryptedData)

      api.downloadPolicy(decryptedData)
        .then((data) => {
          if (data.data) {
            console.log("print all", data.data);
            const jsonData = JSON.parse(data?.data);
            setPolicyDetailsData(jsonData?.data?.policyDetails.reverse());
            formContext.setloaderStatus(false);
          }
        })
        .catch((err) => {
          setPolicyDetailsData([]);  // Reset the data if there's an error
          formContext.setloaderStatus(false);
        });
    } else {
      formContext.setPages("Myprofile");
      history.push("/signin");
    }
  };

  //old
  // const getPoliciesList = () => {
  //   const userLogin = sessionStorage.getItem("userLoggin");

  //   if (userLogin) {
  //     var bytes = CryptoJS.AES.decrypt(userLogin, "dynamipass");
  //     var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  //     formContext.setloaderStatus(true);
  //     api
  //       .downloadPolicy(decryptedData)
  //       .then((data) => {
  //         if (data.data) {
  //           console.log("print alll".data.data)
  //           const jsonData = JSON.parse(data?.data);
  //           setPolicyDetailsData(jsonData?.data?.policyDetails.reverse());
  //           formContext.setloaderStatus(false);
  //         }
  //       })
  //       .catch((err) => {
  //         setPolicyDetailsData([]);
  //         formContext.setloaderStatus(false);

  //       });
  //   } else {
  //     formContext.setPages("Myprofile");
  //     history.push("/signin");
  //   }
  // };

  const getPolicyDownloadRepository = () => {
    const userLogin = sessionStorage.getItem("userLoggin");
    console.log("first", userLogin);

    if (userLogin) {
      var bytes = CryptoJS.AES.decrypt(userLogin, "dynamipass");
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      console.log("decryptedData", decryptedData);
      formContext.setloaderStatus(true);

      api
        .policyDownloadRepository(decryptedData)
        .then((data) => {
          console.log("check data", data);
          if (data && data.data) {
            console.log("data.data", data.data);


            const jsonData = data.data;

            console.log("Print JsonData", jsonData);
            setPolicyDetailsRepository(jsonData.reverse());
            formContext.setloaderStatus(false);
          }
        })
        .catch((err) => {
          setPolicyDetailsRepository([]);
          formContext.setloaderStatus(false);
        });
    } else {
      formContext.setPages("Myprofile");
      history.push("/signin");
    }
  };

  useEffect(() => {
    getPolicyDownloadRepository();
  }, []);

  const getProposal = () => {
    setloaderStatus(true);
    const userLogin = sessionStorage.getItem("userLoggin");
    var bytes = CryptoJS.AES.decrypt(userLogin, "dynamipass");
    var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    api
      .getProposalList(decryptedData)
      .then((data) => {
        if (data.data) {
          const jsonData = JSON.parse(data?.data);

          setproposalData(
            jsonData?.data
              ? jsonData.data?.sort((a, b) => {
                let dateA = new Date(
                  a.proposalCreatedDate
                    .split(" ")[0]
                    .split("/")
                    .reverse()
                    .join("/")
                );
                let dateB = new Date(
                  b.proposalCreatedDate
                    .split(" ")[0]
                    .split("/")
                    .reverse()
                    .join("/")
                );
                return dateB - dateA;
              })
              : []
          );
          formContext.setloaderStatus(false);
        }
      })
      .catch((err) => {
        setproposalData([]);
        formContext.notifyError("Error while getting propsal");
      })
      .finally(() => {
        setloaderStatus(false);
      });
  };

  function carePolicyStatusService(proposalId) {
    return new Promise((resolve, reject) => {
      setloaderStatus(true);
      const userLogin = sessionStorage.getItem("userLoggin");
      var bytes = CryptoJS.AES.decrypt(userLogin, "dynamipass");
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

      api
        .getProposalList(decryptedData)
        .then((data) => {
          if (data.data) {
            const jsonData = JSON.parse(data?.data);
            // console.log("rrr", data?.data)
            // const filteredData = jsonData?.data?.filter(
            //   (proposal) => proposal.healthProposalStatus === "PENDINGFORMANUALUW" || "PENDINGREQUIREMENTS"
            // );
          
          const filteredData = jsonData?.data?.filter(
             (proposal) => proposal.healthProposalStatus === "PENDINGREQUIREMENTS"
          );

            setproposalData(
              filteredData
                ? filteredData.sort((a, b) => {
                  let dateA = new Date(
                    a.proposalCreatedDate
                      .split(" ")[0]
                      .split("/")
                      .reverse()
                      .join("/")
                  );
                  let dateB = new Date(
                    b.proposalCreatedDate
                      .split(" ")[0]
                      .split("/")
                      .reverse()
                      .join("/")
                  );
                  return dateB - dateA;
                })
                : []
            );
            formContext.setloaderStatus(false);
            resolve(jsonData); // Resolve with the data to handle the success in the component
          }
        })
        .catch((err) => {
          setproposalData([]);
          formContext.notifyError("Error while getting proposals");
          reject(err); // Reject in case of an error
        })
        .finally(() => {
          setloaderStatus(false);
        });
    });
  }

  const getProposalDetsil = (propsalId) => {
    api
      .getProposal(propsalId)
      .then((data) => {

        if (data.data) {
          const jsonData = JSON.parse(data?.data);

          formContext.setsingleQuotesData(
            jsonData?.data?.proposalRequest?.premiumDetails
          );
          formContext.setproposalData(jsonData?.data);
          history.push("/proposal/2");
        }
      })
      .catch((err) => {
        // setproposalData([]);

        formContext.notifyError("Facing some error ,please try again.");
      })
      .finally(() => setbuyNowLoader(false));
  };

  useEffect(() => {
    console.log("My proposal data", proposalData)
  }, [proposalData]);

  const fetchData = async (user) => {
    try {
      const data = {
        email: user?.email,
      };

      await AdminService.getUserByEmail(data).then((res) => {
        setInitialData(res?.data?.data);
        formik.setValues(res.data.data);
      });
    } catch (error) {
      history.push("/signin");
      console.error("Error:", error);
    }
  };

  const getMyProfile = () => {
    setloaderStatus(true);
    const userLogin = sessionStorage.getItem("userLoggin");
    if (userLogin) {
      var bytes = CryptoJS.AES.decrypt(userLogin, "dynamipass");
      var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));


      api
        .getMyProfile(decryptedData)
        .then((data) => {
          if (data.data) {
            const jsonData = JSON.parse(data?.data);

            setProfileData(jsonData?.data);
            formik.setValues({
              firstName: jsonData?.data.firstName,
              lastName: jsonData?.data.lastName,
              EmailId: jsonData?.data.emailId,
              mobileNumber: jsonData?.data.mobile,
              gender: jsonData?.data.gender,
              maritalStatus: jsonData?.data.maritalStatus,
            });
            formContext.setloaderStatus(false);
          }
        })
        .catch((err) => {
          setProfileData([]);

          formContext.notifyError("Error while getting Profile Data");
        })
        .finally(() => {
          setloaderStatus(false);
        });
    }
  };
  /**
   * Updates the toggle state for a specific element identified by its 'id'.
   * @param {string} id - The unique identifier of the element to toggle.
   */



  function updateToggle(id) {

    setToggle(id);

    if (id === 6) {
      sessionStorage.clear();
      localStorage.clear();
      history.push("/");

    }
    else if (id === 8) {
      getPolicyDownloadRepository()
    }
    else if (id === 5) {
      getPoliciesList();

    } else if (id === 4) {
      getProposal();
      //UW policies
    } else if (id === 9) {
      // getUWPolicies();
      carePolicyStatusService()
    }
    else if (id === 1) {
      getMyProfile();
    }
  }


  const downloadFile = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = "document";
    link.target = "_blank";
    document.body.appendChild(link);
    setTimeout(() => {
      link.click();
      document.body.removeChild(link);
    }, 500);
  };

  const downloadFileFromAPI = (carrierId, mobile, pKey) => {
    const apiUrl =
      process.env.REACT_APP_BASE_URL + process.env.REACT_APP_POLICY_URL;

    // Define request data
    const requestData = {
      header: {
        transactionName: "policyDocDownloadService",
        messageId: "",
        deviceId: "ABCD12345",
      },
      body: {
        mobile: mobile,
        pKey: pKey,
      },
    };


    // Make POST request to the API
    axios
      .post(apiUrl, requestData)
      .then((response) => {
        // Extract URL from response data

        const responseData = JSON.parse(response.data);
        const downloadLink = responseData?.data?.DownloadLink
          ? responseData?.data?.DownloadLink
          : responseData?.data?.policyDetails[0].downloadUrl;
        if (carrierId === 29) {
          window.open(downloadLink);
        } else {
          // Trigger file download using the obtained URL
          downloadFile(downloadLink);
        }


      })
      .catch((error) => {
        console.error("Error downloading file from API:", error);
      });
  };


  // const handleEdit = (e) => {
  //   e.preventDefault();
  //   setIsEditing(true);
  // };
  const validationSchema2 = Yup.object().shape({
    firstName: Yup.string()
      .required("Full Name Is Required")
      .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed")
      .test("not blank", "Select value", (value) => {
        return value !== "";
      }),
    lastName: Yup.string()
      .required("Last Name Is Required")
      .matches(/^[a-zA-Z\s]+$/, "Only alphabets are allowed")
      .test("not blank", "Select value", (value) => {
        return value !== "";
      }),
    EmailId: Yup.string()
      .required("Email Is Required")
      .email("Wrong email format")
      .max(50, "Maximum 50 characters allowed")
      .test("not blank", "Select value", (value) => {
        return value !== "";
      }),

    gender: Yup.string()
      .required("Gender Is Required")
      .test("not blank", "Select value", (value) => {
        return value !== "";
      }),
    maritalStatus: Yup.string()
      .required(" Maritial Status Is Required")
      .test("not blank", "Select value", (value) => {
        return value !== "";
      }),
  });
  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    formik.setValues({
      firstName: profileData?.firstName,
      lastName: profileData?.lastName,
      EmailId: profileData?.emailId,
      mobileNumber: profileData?.mobile,
      gender: profileData?.gender,
      maritalStatus: profileData?.maritalStatus,
    });
    setIsEditing(false);
  };



  const formik = useFormik({
    initialValues: initialData,
    validationSchema: toggle === 1 ? validationSchema2 : validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      let data = values;

      try {
        const response = await api.updateMyProfileApi(values);
        if (response.status === 200) {
          formContext.notifySuccess("Profile updated successfully");
          // setProfileData(values);
          await getMyProfile();
          setIsEditing(false);
        } else {
          console.error("Failed to update profile", response);
          throw new Error("Failed to update profile");
        }
      } catch (error) {
        console.error("Error details", error);
        formContext.notifyError(
          `There was an error updating the profile ${error.message}`
        );
      } finally {
        setSubmitting(false);
      }
    },
  });

  useEffect(() => {
    if (!formContext.loginType) {
      formContext.setloginType(sessionStorage.getItem("isAdmin"));
      // history.push("/myaccount")
      formContext.setPages("Myprofile");
    }
  }, []);


  const filterFunction = () => {

    let formattedDates;
    if (value) {
      formattedDates = value?.map(formatDate);
    }
    const quoteData = [];
    let filteredQuotes = quoteData.filter((details) => {
      const { searchInput } = searchParams;
      const customerFullName =
        `${details.firstName} ${details.lastName}`.toLowerCase();

      const isSearchMatch = searchInput
        ? details?.phoneNumber?.includes(searchInput) ||
        customerFullName?.includes(searchInput?.toLowerCase())
        : true;

      let isDateValid = true;
      if (formattedDates) {
        isDateValid = isDateInRange(details.quoteCreatedDate, formattedDates);
      }

      return isSearchMatch && isDateValid;
    });
  };

  const filterFunctionPropsal = () => {
    let formattedDates;
    if (valueProposal) {
      formattedDates = valueProposal?.map(formatDate);
    }

    let filteredProposal = proposalData.filter((details) => {
      const { searchInput } = searchParamsForProposal;
      const customerFullName =
        `${details.firstName} ${details.lastName}`.toLowerCase();

      const isSearchMatch = searchInput
        ? details?.phoneNumber?.includes(searchInput) ||
        customerFullName?.includes(searchInput?.toLowerCase())
        : true;

      let isDateValid = true;
      if (formattedDates) {
        isDateValid = isDateInRange(details, formattedDates);
      }

      return isSearchMatch && isDateValid;
    });

    setproposalData(filteredProposal);

  };

  const filterFunctionPolicy = () => {
    let formattedDates;
    if (valuePolicy) {
      formattedDates = valuePolicy?.map(formatDate); // Date range selected by user
    }

    let filteredQuotes = policyDetailsData.filter((details) => {
      const { searchInput } = searchParamsForPolicy;
      const customerFullName = `${details.Name}`.toLowerCase();

      const isSearchMatch = searchInput
        ? details?.mobile?.includes(searchInput) ||
        customerFullName?.includes(searchInput?.toLowerCase())
        : true;

      let isDateValid = true;

      const parseDate = (dateStr) => {
        const [day, month, year] = dateStr.split("/");
        return new Date(`${year}-${month}-${day}`);
      };
      if (formattedDates) {
        isDateValid = isDateInRange(
          parseDate(details.policyIssueDate),
          formattedDates
        ); // Apply date range filtering
      }

      return isSearchMatch && isDateValid; // Both conditions must match
    });

    setPolicyDetailsData(filteredQuotes);

  };



  useEffect(() => {
    if (value) {
      filterFunction();
    } else {
      //setQuoteData(quoteDataOriginal);
    }

  }, [value]);

  useEffect(() => {
    if (valueProposal) {
      filterFunctionPropsal();
    } else {
      setproposalData(proposalDataOriginal);
    }
  }, [valueProposal]);

  useEffect(() => {
    if (valuePolicy) {
      filterFunctionPolicy();
    } else {
      setPolicyDetailsData(policyDetailsDataOriginal);
    }
  }, [valuePolicy]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    // Adjust for timezone if necessary
    const adjustedDate = new Date(
      date.getTime() - date.getTimezoneOffset() * 60000
    );

    // Format the date parts
    const month = String(adjustedDate.getMonth() + 1).padStart(2, "0");
    const day = String(adjustedDate.getDate()).padStart(2, "0");
    const year = adjustedDate.getFullYear();

    // Format the time parts

    // Custom time you wanted
    const customHours = "12";
    const customMinutes = "13";
    const customSeconds = "59";

    // Return in the desired format
    return `${month}/${day}/${year} ${customHours}:${customMinutes}:${customSeconds}`;
  };

  function isDateInRange(details, formattedDates) {
    const quoteDate = new Date(details);
    const startDate = new Date(formattedDates[0]);
    const endDate = new Date(formattedDates[1]);


    return quoteDate >= startDate && quoteDate <= endDate;
  }

  console.log("policy download ", policyDowloadRepository)
  console.log("policy issues ", policyDetailsData)


  function displayMessage() {
    formContext.notifySuccess()
  }

  const uniquePolicies = policyDetailsData?.reduce((acc, current) => {
    const isDuplicate = acc.find(item =>
      (item.policyNo && current.policyNo && item.policyNo === current.policyNo) ||
      (item.policyNumber && current.policyNumber && item.policyNumber === current.policyNumber)
    );

    if (!isDuplicate) {
      return [...acc, current];
    }

    if (isDuplicate) {
      const existingIndex = acc.findIndex(item =>
        (item.policyNo && current.policyNo && item.policyNo === current.policyNo) ||
        (item.policyNumber && current.policyNumber && item.policyNumber === current.policyNumber)
      );

      const existingKeys = Object.keys(acc[existingIndex]).filter(key => acc[existingIndex][key]);
      const currentKeys = Object.keys(current).filter(key => current[key]);

      if (currentKeys.length > existingKeys.length) {
        acc[existingIndex] = current;
      }
    }

    return acc;
  }, []);


  return (
    <div className="container-fluid mt-3">
      <div className="tab-wrap">
        <div className="tabs">
          <button
            style={{ paddingLeft: "20px", paddingRight: "20px" }}
            className={toggle === 1 ? "active" : ""}
            onClick={() => updateToggle(1)}
          >
            My Profile{" "}
          </button>
          <button
            style={{ paddingLeft: "20px", paddingRight: "20px" }}
            className={toggle === 2 ? "active" : ""}
            onClick={() => updateToggle(2)}
          >
            Risk and Insurance Profile{" "}
          </button>
          <button
            style={{ paddingLeft: "20px", paddingRight: "20px" }}
            className={toggle === 3 ? "active" : ""}
            onClick={() => updateToggle(3)}
          >
            My Quotes{" "}
          </button>
          <button
            style={{ paddingLeft: "20px", paddingRight: "20px" }}
            className={toggle === 4 ? "active" : ""}
            onClick={() => updateToggle(4)}
          >
            My Proposal{" "}
          </button>

          <button
            style={{ paddingLeft: "20px", paddingRight: "20px" }}
            className={toggle === 9 ? "active" : ""}
            onClick={() => updateToggle(9)}
          >
            Underwriting Policies{" "}
          </button>

          <button
            style={{ paddingLeft: "20px", paddingRight: "20px" }}
            className={toggle === 5 ? "active" : ""}
            onClick={() => updateToggle(5)}
            id="Prosposal"
          >
            My Policies{" "}
          </button>

          {/* {added generate policy} */}
          {/* <button
            style={{ paddingLeft: "20px", paddingRight: "20px" }}
            className={toggle === 8 ? "active" : ""}
            onClick={() => updateToggle(8)}
            id="Prosposal"
          >
            Generate Policy{" "}
          </button> */}

          <button
            style={{ paddingLeft: "20px", paddingRight: "20px" }}
            className={toggle === 7 ? "active" : ""}
            onClick={() => {
              sessionStorage.clear();
              updateToggle(7);
              localStorage.removeItem("user");
              alert("Logged Out ");
              window.location.href = "/myaccount";
            }}
          >
            Log Out{" "}
          </button>
        </div>

        <div className="content-wrap">
          <form onSubmit={formik.handleSubmit}>
            <div
              className={
                toggle === 1 ? "showConten" : "content my-profile-content"
              }
            >
              <div className="edit-profile">
                <IoPersonCircleOutline className="profile-image" />
                <Button
                  className="primary-btn"
                  onClick={() => handleEditClick()}
                >
                  <MdEdit />
                </Button>
              </div>
              {!isEditing ? (
                <div className="personal-info-wrap">
                  <div className="personal-info">
                    <label className="label">First Name</label>
                    <p className="value">{profileData?.firstName}</p>
                  </div>
                  <div className="personal-info">
                    <label className="label">Last Name</label>
                    <p className="value">{profileData?.lastName}</p>
                  </div>
                  <div className="personal-info">
                    <label className="label">Email Address</label>
                    <p className="value">{profileData?.emailId}</p>
                  </div>

                  <div className="personal-info">
                    <label className="label">Mobile Number</label>
                    <p className="value">{profileData?.mobile}</p>
                  </div>
                  <div className="personal-info">
                    <label className="label">Gender</label>
                    <p className="value">{profileData?.gender}</p>
                  </div>
                  <div className="personal-info">
                    <label className="label">Marital status</label>
                    <p className="value">{profileData?.maritalStatus}</p>
                  </div>
                </div>
              ) : (
                <>
                  <div className="personal-info-wrap">
                    <div className="personal-info">
                      <InputFieldText
                        formikFieldName="firstName"
                        placeholder="Full Name*"
                        formik={formik}
                      />
                      <ErrorMessage formik={formik} fieldValue="firstName" />
                    </div>
                    <div className="personal-info">
                      <InputFieldText
                        formikFieldName="lastName"
                        placeholder="Full Name*"
                        formik={formik}
                      />
                      <ErrorMessage formik={formik} fieldValue="lastName" />
                    </div>
                    <div className="personal-info">
                      <InputFieldText
                        formikFieldName="EmailId"
                        placeholder="Email Address"
                        formik={formik}
                      />
                      <ErrorMessage formik={formik} fieldValue="EmailId" />
                    </div>

                    <div className="personal-info">
                      <InputFieldDropdown
                        formikFieldName="gender"
                        label="Gender"
                        optionsArray={gender}
                        formik={formik}
                      />
                      <ErrorMessage formik={formik} fieldValue="gender" />
                    </div>
                    <div className="personal-info">
                      <InputFieldDropdown
                        formikFieldName="maritalStatus"
                        label="Marital status"
                        optionsArray={maritalStatus}
                        formik={formik}
                      />
                      <ErrorMessage
                        formik={formik}
                        fieldValue="maritalStatus"
                      />
                    </div>
                  </div>
                  <div className="button-group mt-5">
                    <Button
                      type="button"
                      onClick={handleCancel}
                      className="back-btn"
                    >
                      Cancel
                    </Button>
                    <Button type="submit" className="primary-btn">
                      Save
                    </Button>
                  </div>
                </>
              )}
            </div>
          </form>
          {/* -------------------------------- */}
          <div
            className={
              toggle === 2 ? "showConten" : "content insurance-profile"
            }
          >
            <img src={Progress} width={"40%"} />
          </div>
          <div className={toggle === 3 ? "showConten" : "content my-quotes"}>
            <div>
              <img src={Progress} width={"40%"} />
            </div>
          </div>

          <div className={toggle === 4 ? "showConten" : "content"}>
            <div className="policies">
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <input
                  type="text"
                  placeholder="Mobile Number or Customer Name"
                  value={searchParamsForProposal.searchInput}
                  onChange={(e) =>
                    setSearchParamsForProposal({
                      ...searchParamsForProposal,
                      searchInput: e.target.value,
                    })
                  }
                  style={{
                    width: "250px",
                    marginRight: "10px",
                    padding: "5px 10px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                  }}
                />{" "}
                <button
                  onClick={() => {
                    setSearchParamsForProposal({
                      ...searchParamsForProposal,
                      searchInput: "",
                    });
                    setproposalData(proposalDataOriginal);
                  }}
                  style={{
                    background: "transparent",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  <FaTimes size={20} />
                </button>
                <button
                  onClick={() => {
                    filterFunctionPropsal();
                  }}
                  style={{
                    background: "transparent",
                    border: "none",
                    cursor: "pointer",
                    padding: "5px",
                  }}
                >
                  <FaSearch size={20} />
                </button>
                <DateRangePicker
                  value={valueProposal}
                  onChange={setValueProposal}
                />
              </div>
              <Table className="policy-details-wrap">
                <thead>
                  <tr>
                    <th></th>
                    <th>Proposal Date</th>
                    <th>Insured Name</th>
                    <th>Proposal Number</th>
                    <th>Policy Start Date</th>
                    <th>Policy End Date</th>
                    <th>Breakin Status</th>
                    <th>Premium(RS)</th>
                    <th>Type</th>
                    <th></th>
                  </tr>
                </thead>
                {proposalData?.length === 0
                  ? (loaderStatus && formContext.spinner) || (
                    <tr>
                      <td colSpan={7}>
                        <PageNotFound message={"No data found"} />
                      </td>
                    </tr>
                  )
                  : proposalData?.map((details, index) => {
                    return (
                      !details.policyNo &&
                      !details.policyIssueDate && (
                        <tbody key={index}>
                          <tr>
                            <td>
                              <div className="policy-image">
                                <img
                                  src={
                                    imgArrayForCard?.[
                                    details?.insuranceCompany
                                    ]
                                  }
                                  alt=""
                                  className="logo"
                                  style={
                                    details?.insurerName ===
                                      "HDFC ERGO General Insurance Company"
                                      ? { width: "120px", height: "44px" }
                                      : null
                                  }
                                />
                              </div>
                            </td>
                            <td>
                              {details?.proposalCreatedDate.split(" ")?.[0]}
                            </td>
                            <td>
                              {details?.firstName + " " + details?.lastName}
                            </td>
                            <td>{details?.proposalId}</td>
                            <td>{details?.policyStartDate}</td>
                            <td>{details?.policyExpiryDate}</td>
                            <td>{details?.breakinStatusRes?.status || "NA"} </td>
                            <td>{details?.totalPremium}</td>
                            <td>
                              {" "}
                              <div>
                                <img
                                  src={
                                    details?.proposalResponseCar
                                      ?.businessLineId === 3
                                      ? car
                                      : bike
                                  }
                                  alt=""
                                  className="logo"
                                  style={{ width: "100px", height: "54px" }}
                                />
                              </div>
                            </td>
                            <td>
                              <Button
                                className="primary-btn"
                                onClick={() => {
                                  getProposalDetsil(details?.proposalId);
                                  setbuyNowIndex(index);
                                  setbuyNowLoader(true);
                                }}
                              >
                                Buy{" "}
                                {buyNowLoader &&
                                  buyNowIndex === index &&
                                  formContext.spinner}
                              </Button>
                            </td>
                          </tr>
                        </tbody>
                      )
                    );
                  })}
              </Table>
            </div>
          </div>

          {/* underwriting policies */}
          <div className={toggle === 9 ? "showConten" : "content"} style={{border: '1px solid #d7d7d7',borderRadius: '6px',overflow: 'auto'}}>
            <div className="policies">
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <input
                  type="text"
                  placeholder="Mobile Number or Customer Name"
                  value={searchParamsForProposal.searchInput}
                  onChange={(e) =>
                    setSearchParamsForProposal({
                      ...searchParamsForProposal,
                      searchInput: e.target.value,
                    })
                  }
                  style={{
                    width: "250px",
                    marginRight: "10px",
                    padding: "5px 10px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                  }}
                />{" "}
                <button
                  onClick={() => {
                    setSearchParamsForProposal({
                      ...searchParamsForProposal,
                      searchInput: "",
                    });
                    setproposalData(proposalDataOriginal);
                  }}
                  style={{
                    background: "transparent",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  <FaTimes size={20} />
                </button>
                <button
                  onClick={() => {
                    filterFunctionPropsal();
                  }}
                  style={{
                    background: "transparent",
                    border: "none",
                    cursor: "pointer",
                    padding: "5px",
                  }}
                >
                  <FaSearch size={20} />
                </button>
                <DateRangePicker
                  value={valueProposal}
                  onChange={setValueProposal}
                />
              </div>
              <Table className="policy-details-wrap">
                <thead>
                  <tr>
                    <th></th>
                    <th>PlanName</th>
                    <th>Bussiness Type</th>
                    <th>Proposal Date</th>
                    <th>Insured Name</th>
                    <th>Proposal Number</th>
                    <th>Policy Start Date</th>
                    <th>Policy End Date</th>
                    <th>PolicyNo</th>
                    <th>Status</th>
                    <th>Premium</th>
                    <th></th>
                 
                  </tr>
                </thead>
                {proposalData?.length === 0

                  ? (loaderStatus && formContext.spinner) || (
                    <tr>
                      <td colSpan={7}>
                        <PageNotFound message={"No data found"} />
                      </td>
                    </tr>
                  )
                  : proposalData?.map((details, index) => {
                    console.log("print my details", details)
                    return (

                      !details.policyNo &&
                      !details.policyIssueDate && (
                        <tbody key={index}>
                          <tr>
                            <td>
                              <div className="policy-image">
                                <img
                                  src={
                                    imgArrayForCard?.[
                                    details?.insuranceCompany
                                    ]
                                  }
                                  alt=""
                                  className="logo"
                                  style={
                                    details?.insurerName ===
                                      "HDFC ERGO General Insurance Company"
                                      ? { width: "120px", height: "44px" }
                                      : null
                                  }
                                />
                              </div>
                            </td>
                            <td>{details?.planName}</td>
                            <td>{details?.BusinessType}</td>
                            <td>
                              {details?.proposalCreatedDate.split(" ")?.[0]}
                            </td>
                            <td>
                              {details?.firstName + " " + details?.lastName}
                            </td>
                         
                            <td>{details?.proposalId}</td>
                            <td>{details?.policyStartDate}</td>
                            <td>{details?.policyExpiryDate}</td>
                            <td>{details?.healthPolicyNo}</td>
                            <td>{details?.healthProposalStatus}</td>
                            <td>{details?.totalPremium}</td>
                            {/* <td>{details?.proposalResponseHealth?.premium}</td> */}
                            {/* <td>
                              {" "}
                            </td> */}
                            <td>
                              <Button
                                className="primary-btn"
                                style={{ width: "97px" }}
                                onClick={() => {
                                  setbuyNowLoader(true);
                                  api.carePolicyStatusService(details?.proposalId)
                                    .then((response) => {
                                      const policyStatus = response?.data?.data?.carrierResponse?.intGetPolicyStatusIO?.policyStatus;
                                      console.log("Policy Status:", policyStatus);

                                      if (policyStatus === "Inforce") {
                                        console.log("proposal ID", details?.proposalId);
                                        console.log("checking proposal data...", proposalData);
                                        const updatedProposalData = proposalData.filter(
                                          (policy) => policy.proposalId !== details?.proposalId
                                        );
                                        setproposalData(updatedProposalData);
                                        console.log("moving to the account tab")
                                        // formContext.notifySuccess("Policy is Inforce and has been removed from the list.");
                                        setModalMessage("Policy is Inforce.");
                                        handleModalShow();
                                        window.location.href = "/myaccount?tab=5";
                                      } else if (
                                        policyStatus === 'Pending Application Entry' || 'Pending CPU Requirement' || 'Pending Tele Q'
                                      ) {
                                        setModalMessage("Pending with Underwriting Team for Review");
                                        console.log("setModalMessage", modalMessage)
                                        handleModalShow();
                                      } else {
                                        // formContext.notifyError(`Policy Status: ${policyStatus}`);
                                        setModalMessage(`Policy Status: ${policyStatus}`);
                                        handleModalShow();
                                      }
                                      setbuyNowIndex(index);
                                      setbuyNowLoader(false);
                                    })
                                    .catch((error) => {
                                      formContext.notifyError("Error while fetching policy status");
                                      setbuyNowLoader(false);
                                    });
                                }}
                              >
                                Status
                                {buyNowLoader && buyNowIndex === index && formContext.spinner}
                              </Button>
                            </td>
                          </tr>
                        </tbody>
                      )
                    );
                  })}
              </Table>
              {/* Modal to display status messages */}
              <Modal show={showModal} onHide={handleModalClose} centered className="custom-modal">
                <Modal.Header closeButton className="modal-header">
                  <Modal.Title style={{ fontSize: '17px'}}>Policy Status</Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-body">{modalMessage}</Modal.Body>
                <Modal.Footer className="modal-footer">
                  <Button variant="secondary" onClick={handleModalClose} className="close-btn">
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>

          <div className={toggle === 5 ? "showConten my-policies" : "content "}>
            <div className="policies">
              <div
                style={{
                  justifyContent: "center",
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "10px",
                }}
              >
                <input
                  type="text"
                  placeholder="Mobile Number or Customer Name"
                  value={searchParamsForPolicy.searchInput}
                  onChange={(e) =>
                    setSearchParamsForPolicy({
                      ...searchParamsForPolicy,
                      searchInput: e.target.value,
                    })
                  }
                  style={{
                    width: "250px",
                    marginRight: "10px",
                    padding: "5px 10px",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                  }}
                />
                <button
                  onClick={() => {
                    setSearchParamsForPolicy({
                      ...searchParamsForPolicy,
                      searchInput: "",
                    });
                    setPolicyDetailsData(policyDetailsDataOriginal);
                  }}
                  style={{
                    background: "transparent",
                    border: "none",
                    cursor: "pointer",
                  }}
                >
                  <FaTimes size={20} />
                </button>
                <button
                  onClick={() => {
                    filterFunctionPolicy();
                  }}
                  style={{
                    background: "transparent",
                    border: "none",
                    cursor: "pointer",
                    padding: "5px",
                  }}
                >
                  <FaSearch size={20} />
                </button>
                <DateRangePicker value={valuePolicy} onChange={setValuePolicy} />
              </div>

              <Table className="policy-details-wrap">
                <thead>
                  <tr>
                    <th></th>
                    <th>Plan Name</th>
                    <th>Purchased on</th>
                    <th>Insured Name</th>
                    <th>Mobile Number</th>
                    <th>Policy Number</th>
                    <th>Policy Start Date</th>
                    <th>Policy End Date</th>
                    <th></th>
                  </tr>
                </thead>
                {!uniquePolicies || uniquePolicies.length === 0 ? (
                  (formContext.loaderStatus && formContext.spinner) || (
                    <tr>
                      <td colSpan={7}>
                        <PageNotFound message={"No data found"} />
                      </td>
                    </tr>
                  )
                ) : (
                  uniquePolicies.map((details, index) => (
                    <tbody key={index}>
                      <tr>
                        <td>
                          <div className="policy-image">
                            <img
                              src={imgArrayForCard?.[details?.insurerName]}
                              alt=""
                              className="logo"
                              style={
                                details?.insurerName ===
                                  "HDFC ERGO General Insurance Company" ||
                                  details?.insurerName ===
                                  "Care Health Insurance"
                                  ? { width: "120px", height: "44px" }
                                  : null
                              }
                            />
                          </div>
                        </td>
                        <td>{details?.planName}</td>
                        <td>{details?.policyIssueDate}</td>
                        <td>{details?.Name}</td>
                        <td>{details?.mobile}</td>
                        <td>{details?.policyNo || details?.policyNumber}</td>
                        <td>{details?.policyStartDate}</td>
                        <td>{details?.policyExpiryDate}</td>
                        <td>
                          <Button
                            className="primary-btn"
                            onClick={() => {
                              downloadFileFromAPI(
                                details?.carrierId,
                                details?.mobile,
                                details?.secretKey
                              );
                            }}
                          >
                            Download
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  ))
                )}
              </Table>
            </div>
          </div>

          <div className={toggle === 8 ? 'showConten my-policies' : 'content'}>
            <div className="generate-policies">
              <div style={{ justifyContent: 'center', display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
                <input
                  type="text"
                  placeholder="Mobile Number or Customer Name"
                  value={searchParamsForPolicy.searchInput}
                  onChange={(e) => setSearchParamsForPolicy({ ...searchParamsForPolicy, searchInput: e.target.value })}
                  style={{
                    width: '250px',
                    marginRight: '10px',
                    padding: '5px 10px',
                    borderRadius: '4px',
                    border: '1px solid #ccc',
                  }}
                />
                <button
                  onClick={() => {
                    setSearchParamsForPolicy({ ...searchParamsForPolicy, searchInput: '' });
                    setPolicyDowloadRepository(policyDetailsDataOriginal);
                  }}
                  style={{
                    background: 'transparent',
                    border: 'none',
                    cursor: 'pointer',
                  }}
                >
                  <FaTimes size={20} />
                </button>
                <button
                  onClick={() => {
                    filterFunctionPolicy();
                  }}
                  style={{
                    background: 'transparent',
                    border: 'none',
                    cursor: 'pointer',
                    padding: '5px',
                  }}
                >
                  <FaSearch size={20} />
                </button>
                <DateRangePicker value={valuePolicy} onChange={setValuePolicy} />
              </div>

              {/* Loading Spinner and Message */}
              {isLoading && (
                <div style={{ textAlign: 'center', marginTop: '20px' }}>
                  <Spinner animation="border" role="status" />
                  <p>Loading...</p>
                </div>
              )}

              <Table className="policy-details-wrap">
                <thead>
                  <tr>
                    <th></th>
                    <th>Firstname</th>
                    <th>Lastname</th>
                    <th>Proposal Id</th>
                    <th>Registration no</th>
                    <th>Mobile no</th>
                    <th></th>
                  </tr>
                </thead>
                {policyDowloadRepository.length === 0 ? (
                  (formContext.loaderStatus && formContext.spinner) || (
                    <tr>
                      <td colSpan={7}>
                        <PageNotFound message={'No data found'} />
                      </td>
                    </tr>
                  )
                ) : (
                  policyDowloadRepository.map((details, index) => (
                    <tbody key={index}>
                      <tr>
                        <td>
                          <div className="policy-image">
                            <img
                              src={imgArrayForCard?.[details?.insurerName]}
                              alt=""
                              className="logo"
                              style={
                                details?.insurerName === 'HDFC ERGO General Insurance Company'
                                  ? { width: '120px', height: '44px' }
                                  : null
                              }
                            />
                          </div>
                        </td>
                        <td>{details?.firstName}</td>
                        <td>{details?.lastName}</td>
                        <td>{details?.paymentResponse?.proposalId}</td>
                        <td>{details?.registrationNumber}</td>
                        <td>{details?.mobile}</td>
                        <td>
                          <Button
                            className="primary-btn"
                            style={{ width: '200px' }}
                            onClick={() => handleGeneratePolicy(details)}
                          >
                            Generate Policy
                          </Button>
                        </td>
                      </tr>
                    </tbody>
                  ))
                )}
              </Table>

              <ModalGeneratePolicy
                isOpen={isModalOpen}
                onClose={() => {
                  setIsModalOpen(false);
                  getPolicyDownloadRepository();
                }}
              >
                {generatedPolicyDetails ? (
                  <>
                    <h1 style={{ color: 'green' }}><b>Policy Generated Successfully!</b></h1>
                    <br />
                    <p>Your policy has been successfully generated. Below are the details:</p>
                    <br />
                    <p><strong>Proposal ID:</strong> {generatedPolicyDetails.proposalId}</p>
                    <p><strong>Policy Number:</strong> {generatedPolicyDetails.policyNo}</p>
                  </>
                ) : (
                  <h2>Generating policy .........</h2>
                )}
              </ModalGeneratePolicy>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};
