import axios from "axios";

const AGENCY_URL = process.env.DB_URL_FOR_CONTEXT;

class JsonDbCrud {
  // Create operation: Add a lead
  addLead(addLeadRequest) {
    console.log("addLeadRequest",addLeadRequest)
    return axios.post(
      `${AGENCY_URL}/createCustomerReachabilityInfo`,
      addLeadRequest
    );
  }

  // Read operation: Get a list of leads
  getLead() {
    return axios.get(`${AGENCY_URL}/listCustomerReachabilityInfo`);
  }

  // Update operation: Update lead by ID
  updateLead(leadId, updateLeadRequest) {
    return axios.put(
      `${AGENCY_URL}/updateCustomerReachabilityInfo/${leadId}`,
      updateLeadRequest
    );
  }

  // Delete operation: Delete lead by ID
  deleteLead(leadId) {
    return axios.delete(
      `${AGENCY_URL}/deleteCustomerReachabilityInfo/${leadId}`
    );
  }
}

export default new JsonDbCrud();
