import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import ErrorMessage from "../commonModules/ErrorMessage";
import InputFieldDropdown from "../commonModules/InputFieldDropdown";
import InputFieldText from "../commonModules/InputFieldText";
import { formikFieldVisibility } from "../commonModules/CommonCode";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import UseFormContext from "../../context/UseFormContext";

export default function Benefits(props) {
  const formContext = UseFormContext();
  const history = useHistory();

  useEffect(() => {
    window.location.hash = "benifitsform";
  }, []);

  const carrier = props.carrierType;
  const [relation, setRelation] = useState([
    { value: "", label: "Please Select" },
    { value: "mother", label: "Mother" },
    { value: "father", label: "Father" },
    { value: "son", label: "Son" },
    { value: "daughter", label: "Daughter" },
    { value: "wife", label: "Wife" },
    { value: "husband", label: "Husband" },
    { value: "brother", label: "Brother" },
    { value: "sister", label: "Sister" },
    { value: "uncle", label: "Uncle" },
    { value: "aunt", label: "Aunt" },
    { value: "niece", label: "Niece" },
    { value: "grand son", label: "Grand Son" },
    { value: "grand daughter", label: "Grand Daughter" },
    { value: "nephew", label: "Nephew" },
    { value: "self", label: "Self" },
    { value: "sister-In-Law", label: "Sister-In-Law" },
    { value: "other", label: "Other" },
  ]);

  const [relationForHDFC, setRelationForHDFC] = useState([
    { value: "", label: "Please Select" },
    { value: "spouse", label: "Spouse" },
    { value: "nephew", label: "Nephew" },
    { value: "niece", label: "Niece" },
    { value: "uncle", label: "Uncle" },
    { value: "daughterInLaw", label: "Daughter in law" },
    { value: "sonInLaw", label: "Son in law" },
    { value: "legalGuardian", label: "Legal Guardian" },
    { value: "brother", label: "Brother" },
    { value: "child", label: "Child" },
    { value: "daughter", label: "Daughter" },
    { value: "employee", label: "Employee" },
    { value: "father", label: "Father" },
    { value: "fatherInLaw", label: "Father in law" },
    { value: "grandDaughter", label: "Grand Daughter" },
    { value: "grandFather", label: "Grand Father" },
    { value: "grandMother", label: "Grand Mother" },
    { value: "grandSon", label: "Grand Son" },
    { value: "husband", label: "Husband" },
    { value: "mother", label: "Mother" },
    { value: "motherInLaw", label: "Mother in law" },
    { value: "partner", label: "Partner" },
    { value: "policyHolder", label: "Policy Holder" },
    { value: "sister", label: "Sister" },
    { value: "son", label: "Son" },
    { value: "specialConcessionAdult", label: "Special concession adult" },
    { value: "specialConcessionChild", label: "Special concession child" },
    { value: "wife", label: "Wife" },
  ]);

  // useEffect to set initial visibility of formik fields for nominee information
  useEffect(() => {
    formikFieldVisibility(
      ["nomineFirstName", "nomineLastName", "nomineDob", "nomineRelation"],
      props.formik
    );
    sessionStorage.setItem("nextPage", "yes");
  }, []);

  return (
    <React.Fragment>
      {formContext?.singleQuotesData?.insuranceCompany !==
        "ICICI Lombard General Insurance Co. Ltd" && (
        <div>
          <Row>
            <Col sm={4} className="mb-4">
              <InputFieldText
                formikFieldName="nomineFirstName"
                placeholder="First name*"
                formik={props.formik}
              />
              <ErrorMessage
                formik={props.formik}
                fieldValue="nomineFirstName"
              />
            </Col>
            <Col sm={4} className="mb-4">
              <InputFieldText
                formikFieldName="nomineLastName"
                placeholder="Last Name*"
                formik={props.formik}
              />
              <ErrorMessage formik={props.formik} fieldValue="nomineLastName" />
            </Col>
            <Col sm={4} className="mb-4">
              <InputFieldText
                formikFieldName="nomineDob"
                placeholder="Date of birth*"
                formik={props.formik}
                type="date"
              />
              <ErrorMessage formik={props.formik} fieldValue="nomineDob" />
            </Col>

            <Col sm={4} className="mb-4">
              <InputFieldDropdown
                formikFieldName="nomineRelation"
                label="Relation*"
                optionsArray={
                  carrier === "HDFC ERGO General Insurance Company"
                    ? relationForHDFC
                    : relation
                }
                formik={props.formik}
              />
            </Col>
            <ErrorMessage formik={props.formik} fieldValue="nomineRelation" />
          </Row>
        </div>
      )}

      {formContext?.singleQuotesData?.insuranceCompany ===
        "ICICI Lombard General Insurance Co. Ltd" && (
        <div>
          <Row>
            <Col sm={4} className="mb-4">
              <InputFieldText
                formikFieldName="nomineFirstName"
                placeholder="First name"
                formik={props.formik}
              />
              <ErrorMessage
                formik={props.formik}
                fieldValue="nomineFirstName"
              />
            </Col>
            <Col sm={4} className="mb-4">
              <InputFieldText
                formikFieldName="nomineLastName"
                placeholder="Last Name"
                formik={props.formik}
              />
              <ErrorMessage formik={props.formik} fieldValue="nomineLastName" />
            </Col>
            <Col sm={4} className="mb-4">
              <InputFieldText
                formikFieldName="nomineDob"
                placeholder="Date of birth"
                formik={props.formik}
                type="date"
              />
              <ErrorMessage formik={props.formik} fieldValue="nomineDob" />
            </Col>

            <Col sm={4} className="mb-4">
              <InputFieldDropdown
                formikFieldName="nomineRelation"
                label="Relation"
                optionsArray={
                  carrier === "HDFC ERGO General Insurance Company"
                    ? relationForHDFC
                    : relation
                }
                formik={props.formik}
              />
            </Col>
            <ErrorMessage formik={props.formik} fieldValue="nomineRelation" />
          </Row> 
        </div>
      )}
    </React.Fragment>
  );
}
