import React, { useEffect, useState } from "react";
import { downloadPolicy } from "../../../API/authCurd";
import UseFormContext from "../../../../src/context/UseFormContext";
import { useHistory } from "react-router-dom";
import MailService from "../../../services/mail.service";
import { decryptData } from "../../../services/auth-service";
import axios from "axios";
import * as api from "../../../API/authCurd";

export const PaymentSuccess = (data) => {
  const queryParams = new URLSearchParams(window.location.search);
  const formContext = UseFormContext();
  const history = useHistory();
  const [user, setUser] = useState(null);
  const [downloadLink, setDownloadLink] = useState(null);

  const valuesForPayload = {
    policyNo: queryParams.get("policyNo") ? queryParams.get("policyNo") : data.policyNo,
    lastName: queryParams.get("LastName") ? queryParams.get("LastName") : "",
    email: queryParams.get("Email") ? queryParams.get("Email") : "",
    uwDecision: queryParams.get("uwDecision") ? queryParams.get("uwDecision") : "",
  };
  console.log("print values", valuesForPayload?.uwDecision)
  useEffect(() => {
    if (!queryParams.get("policyNo") && localStorage.getItem("lob") === "health") {
      console.log("inside underWritting", queryParams.get("policyNo"))
      alternativeApiCall();
    } else {
      let user = localStorage.getItem("user");
      if (user) {
        console.log("inside payment success", queryParams.get("policyNo"))
        apiCall();
      }
    }
  }, []);

  const downloadFileFromAPI = (carrierId, mobile, pKey, url) => {
    const apiUrl = process.env.REACT_APP_BASE_URL + process.env.REACT_APP_POLICY_URL;
    const requestData = {
      header: {
        transactionName: "policyDocDownloadService",
        messageId: "",
        deviceId: "ABCD12345",
      },
      body: {
        mobile,
        pKey,
      },
    };

    axios
      .post(apiUrl, requestData)
      .then((response) => {
        const responseData = JSON.parse(response.data);
        const downloadLink = responseData.data.DownloadLink;
        sendMailWithPolicy(downloadLink);
      })
      .catch((error) => {
        console.error("Error downloading file from API:", error);
      });
  };

  const downloadFile = (url) => {
    const link = document.createElement("a");
    link.href = url;
    link.download = "document";
    link.target = "_blank";
    document.body.appendChild(link);
    setTimeout(() => {
      link.click();
      document.body.removeChild(link);
    }, 500);
  };


  const sendMailWithPolicy = (downloadLink) => {
    const to = localStorage.getItem("ownerEmail");
    const cc = ["tejashrik@comsenseconsulting.com"];
    const subject = "Your Policy Has Been Successfully Generated";
    const content = `
      <!DOCTYPE html>
      <html lang="en">
        <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <title>Policy Generation Confirmation</title>
        </head>
        <body>
          <p>Your policy is ready. <a href="${downloadLink}" download="policy_document.pdf">Click here</a> to download.</p>
        </body>
      </html>
    `;

    const mailData = { to, cc, subject, content };
    MailService.sendMail(mailData).then((res) => {
      console.log("Mail sent successfully:", res.data);
    });
  };
  const apiCall = async () => {
    if (
      localStorage.getItem("insuranceCompany") !==
      "ICICI Lombard General Insurance Co. Ltd"
    ) {
      downloadPolicy(localStorage.getItem("customerphoneno"))
        .then(async (data) => {
          const jsonData = JSON.parse(data?.data);
          const arraylength = jsonData?.data?.policyDetails.length;

          let url = jsonData?.data?.policyDetails[arraylength - 1].DownloadLink
            ? jsonData?.data?.policyDetails[arraylength - 1].DownloadLink
            : jsonData?.data?.policyDetails[arraylength - 1].downloadUrl;

          setDownloadLink(url);

          let carrierId =
            jsonData?.data?.policyDetails[arraylength - 1]?.carrierId;
          let mobile = jsonData?.data?.policyDetails[arraylength - 1]?.mobile;
          let pkey = jsonData?.data?.policyDetails[arraylength - 1]?.secretKey;

          crmStateUpdate(jsonData?.data?.policyDetails[arraylength - 1]);

          downloadFileFromAPI(carrierId, mobile, pkey, url);
        })
        .catch((err) => {
          formContext.notifyError("An error occurred while fetching data");
        });
    }
  };

  const alternativeApiCall = async () => {
    api.carePolicyStatusService(localStorage.getItem("proposalId"))
      .then((res) => { console.log(res); })
      .catch((err) => { })
  };

  const crmStateUpdate = async (obj) => {
    const objForUpdateStage = {};
    objForUpdateStage.customer_name = obj?.Name;
    objForUpdateStage.mobile_number = obj?.mobile;
    objForUpdateStage.RTO_no = obj?.registrationNumber;
    objForUpdateStage.policy_link = obj?.downloadUrl;

    objForUpdateStage.module = "motor";
    objForUpdateStage.KYC_Status = "completed";
    objForUpdateStage.proposal_stage = "completed";
    objForUpdateStage.policy_stage = "completed";

    const crmToken = await formContext.setCrmTokenMethod();
    api
      .crmStageUpdateApi(crmToken?.data?.token, objForUpdateStage)
      .then((res) => { })
      .catch((err) => { });
  };

  const handleDownload = (e) => {
    e.preventDefault();
    if (
      valuesForPayload?.uwDecision &&
      (valuesForPayload.uwDecision === "PENDINGFORMANUALUW" ||
        valuesForPayload.uwDecision === "PENDINGREQUIREMENTS")
    ) {
      window.location.href = "/myaccount?tab=9";
    } else {
      window.location.href = "/myaccount?tab=5";
    }

    // if (downloadLink) {
    //   downloadFile(downloadLink);
    // }
  };

  return (
    <div className="payment-success-wrap">
      <h4>Payment Success</h4>
      <p>
        {!valuesForPayload?.uwDecision
          ? "We thank you for choosing Policies365 for your insurance needs. You would receive a confirmation for the premium paid along with the policy copy attached in short while."
          : (valuesForPayload?.uwDecision === "PENDINGFORMANUALUW" || valuesForPayload?.uwDecision === "PENDINGREQUIREMENTS")
            ? "We thank you for choosing Policies365 for your insurance needs. Your policy is currently undergoing underwriting, and we will notify you once the process is complete."
            : null
        }
      </p>

      <div className="details-wrap">
        <p className="sub-head">The Policy details are as follow:</p>
        <div className="details">
          <div className="row-wrap">
            <p className="label-text">Policy Certificate Number</p>
            <span className="label-text">:</span>
            <p className="value-policy ">{valuesForPayload.policyNo} </p>
          </div>
        </div>
        <hr className="bordertop" />
        <div className="bottom-info">
          <p className="label-text">
            In case of any queries or assistance, please call us on our
            Helpline: <a>(022) 68284343</a>
          </p>
          <p className="label-text">
            (Office hours 10:00 am to 7:00 pm) Or write to us @:
            <a>contact@policies365.com</a>
          </p>
        </div>
      </div>
      <button className="goback-btn" onClick={handleDownload}>
        Go to my Policy
      </button>
    </div>
  );
};



